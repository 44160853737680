<template>
  <div class="contentStrategy">
    <div class="management-search">
      <div class="management-search-1">
        策略名称：
        <a-input
          placeholder="请输入策略名称"
          v-model="name"
          @keyup.enter="query(1)"
          allowClear
        />
      </div>
    </div>
    <div class="projectList-search-button">
      <a-button type="primary" @click="query(1)">查询</a-button>
      <i style="margin-left: 20px"></i>
      <a-button type="primary" @click="add">新增</a-button>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="enabled" slot-scope="text, record, index">
        <a-switch
          v-model="text"
          checkedChildren="已启用"
          unCheckedChildren="已禁用"
          @change="enableStrategy(record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="1000px"
    >
      <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
        <a-form-item label="策略名称" required>
          <a-input placeholder="请输入策略名称" v-model="nameAdd" allowClear />
        </a-form-item>
        <a-form-item label="状态" required v-if="title == '新增'">
          <a-switch
            v-model="enabledAdd"
            checkedChildren="启用"
            unCheckedChildren="禁用"
          />
        </a-form-item>
        <a-button
          type="primary"
          @click="strategyItemAdd"
          style="margin-bottom: 20px"
          >新增</a-button
        >
        <a-table
          bordered
          :rowClassName="$common.rowClassColor"
          :components="$common.getTitle(strategyItemColumns)"
          :columns="strategyItemColumns"
          :dataSource="strategyItemList"
          :pagination="false"
          size="small"
          :customRow="customRow"
        >
          <span slot="areaLimit" slot-scope="text, record">
            <el-cascader
              v-model="record.areaLimit"
              :options="options"
              :props="props"
              filterable
              :show-all-levels="false"
              clearable
              collapse-tags
              size="small"
            ></el-cascader>
          </span>
          <span slot="channelSmsAccountLimit" slot-scope="text, record">
            <a-select
              v-model="record.channelSmsAccountLimit"
              placeholder="通道"
              showSearch
              mode="multiple"
              :filterOption="false"
              @search="getGroupAccountList"
              style="width: 220px"
              :maxTagCount="1"
              :maxTagTextLength="12"
            >
              <a-select-option
                :disabled="!i.enabled"
                v-for="i in groupAccountList"
                :key="i.id"
                :value="i.id"
                >{{ i.name }}</a-select-option
              >
            </a-select>
          </span>
          <span slot="content" slot-scope="text, record, index">
            <span @click="editDefaultContent(index)" style="cursor: pointer">
              {{ record.content }}
              <a-icon type="edit" />
            </span>
          </span>
          <span slot="enabled" slot-scope="text, record">
            <a-switch
              v-model="record.enabled"
              checkedChildren="已启用"
              unCheckedChildren="已禁用"
            />
          </span>
          <span slot="action" slot-scope="text, record, index">
            <a-button
              type="link"
              style="color: #ff4d4f"
              @click="strategyItemDelete(index)"
              >删除</a-button
            >
          </span>
        </a-table>
        <a-form-item label="默认内容" required :wrapper-col="{ span: 22 }">
          <span @click="editDefaultContent()" style="cursor: pointer">
            {{ defaultContentAdd }}
            <a-icon type="edit" />
          </span>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      :title="editDefaultContentTitle"
      v-model="editDefaultContentShow"
      :maskClosable="false"
      class="action-class"
      width="1000px"
    >
      <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="内容" required>
          <sms-input :textareaSMS="textarea" @change="textarea_sms"></sms-input>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editDefaultContentShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="editDefaultContent_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import smsInput from "../tool/smsInput.vue";
export default {
  name: "contentStrategy",
  components: {
    smsInput,
  },
  data() {
    return {
      title: "",
      name: "",
      nameAdd: "",
      enabledAdd: false,
      defaultContentAdd: "",
      strategyId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 80,
        },
        {
          title: "策略名称",
          ellipsis: true,
          dataIndex: "name",
          width: 580,
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 120,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      strategyItemList: [],
      strategyItemColumns: [
        {
          title: "包含区域",
          ellipsis: true,
          dataIndex: "areaLimit",
          scopedSlots: { customRender: "areaLimit" },
          width: 200,
        },
        {
          title: "通道限制",
          ellipsis: true,
          dataIndex: "channelSmsAccountLimit",
          scopedSlots: { customRender: "channelSmsAccountLimit" },
          width: 240,
        },
        {
          title: "内容",
          ellipsis: true,
          dataIndex: "content",
          scopedSlots: { customRender: "content" },
          width: 0,
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 120,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      editDefaultContentShow: false,
      editDefaultContentTitle: "",
      editDefaultContentIndex: 0,
      props: { multiple: true, emitPath: false, checkStrictly: true },
      options: [],
      groupAccountList: [],
      textarea: "",
      sign: undefined,
      sourceObj: null,
      targetObj: null,
      sourceIndex: null,
      targetIndex: null,
    };
  },
  mounted() {
    this.getProvinceAndCity();
    this.getGroupAccountList();
    this.query(1);
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      this.$http
        .get("/contentStrategy/strategyPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    enableStrategy(val) {
      this.$http
        .json_post("/contentStrategy/enableStrategy", {
          id: val.id,
          enabled: !val.enabled,
        })
        .then((res) => {
          if (res.result) {
            if (val.enabled) {
              this.$message.success("禁用成功");
            } else {
              this.$message.success("启用成功");
            }
            this.query();
          }
        });
    },
    // 通过a-table自定义列来实现拖拽行修改优先级
    customRow(record, index) {
      return {
        style: {
          cursor: "pointer",
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            // 兼容IE
            var ev = event || window.event;
            ev.target.draggable = true;
          },
          // 开始拖拽
          dragstart: (event) => {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到源目标数据
            this.sourceObj = record;
            this.sourceIndex = index;
          },
          // 拖动元素经过的元素
          dragover: (event) => {
            // 兼容 IE
            var ev = event || window.event;
            // 阻止默认行为
            ev.preventDefault();
          },
          // 鼠标松开
          drop: (event) => {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到目标数据
            this.targetObj = record;
            this.targetIndex = index;
            if (this.sourceObj.id !== this.targetObj.id) {
              this.strategyItemList.splice(this.sourceIndex, 1);
              this.strategyItemList.splice(this.targetIndex, 0, this.sourceObj);
            }
            this.targetObj = null;
            this.sourceObj = null;
            this.sourceIndex = null;
            this.targetIndex = null;
          },
        },
      };
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.strategyId = val.id;
      this.strategyItemList.splice(0);
      this.$http
        .get("/contentStrategy/strategyDetail", { strategyId: val.id })
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.nameAdd = res.data.name;
            this.enabledAdd = res.data.enabled;
            this.defaultContentAdd = res.data.defaultContent;
            res.data.strategyItemList.forEach((item) => {
              if (item.areaLimit) {
                item.areaLimit = item.areaLimit.split(",");
              } else {
                item.areaLimit = [];
              }
              if (item.channelSmsAccountLimit) {
                item.channelSmsAccountLimit = item.channelSmsAccountLimit
                  .split(",")
                  .map(Number);
              } else {
                item.channelSmsAccountLimit = [];
              }
              this.strategyItemList.push(item);
            });
          }
        });
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.nameAdd = "";
      this.enabledAdd = true;
      this.defaultContentAdd = "";
      this.strategyItemList.splice(0);
    },
    textarea_sms(val) {
      this.textarea = val;
    },
    // 确定新增
    add_submit() {
      let data = {
        name: this.nameAdd,
        enabled: this.enabledAdd,
        defaultContent: this.defaultContentAdd,
      };
      let strategyItemList = JSON.parse(JSON.stringify(this.strategyItemList));
      strategyItemList.forEach((item) => {
        item.areaLimit = item.areaLimit.join(",");
        item.channelSmsAccountLimit = item.channelSmsAccountLimit.join(",");
      });
      data.strategyItemList = strategyItemList;
      if (this.title === "新增") {
        this.$http
          .json_post("/contentStrategy/addStrategy", data)
          .then((res) => {
            if (res.result === 200) {
              this.addKeyVisible = false;
              this.$message.success("添加成功");
              this.query();
            }
          });
      } else {
        data.id = this.strategyId;
        this.$http
          .json_post("/contentStrategy/editStrategy", data)
          .then((res) => {
            if (res.result === 200) {
              this.addKeyVisible = false;
              this.$message.success("修改成功");
              this.query();
            }
          });
      }
    },
    editDefaultContent(index) {
      this.editDefaultContentShow = true;
      this.$nextTick(() => {
        if (index !== undefined) {
          this.editDefaultContentTitle = "修改内容";
          this.textarea = this.strategyItemList[index].content;
          this.editDefaultContentIndex = index;
        } else {
          this.textarea = this.defaultContentAdd;
          this.editDefaultContentTitle = "修改默认内容";
        }
      });
    },
    editDefaultContent_submit() {
      if (this.editDefaultContentTitle == "修改默认内容") {
        this.defaultContentAdd = this.textarea;
      } else {
        this.$set(
          this.strategyItemList[this.editDefaultContentIndex],
          "content",
          this.textarea
        );
      }
      this.editDefaultContentShow = false;
    },
    strategyItemAdd() {
      this.strategyItemList.push({
        areaLimit: [],
        channelSmsAccountLimit: [],
        content: "",
        enabled: false,
        order: this.strategyItemList.length,
      });
    },
    strategyItemDelete(index) {
      this.strategyItemList.splice(index, 1);
    },
    getProvinceAndCity() {
      this.$http.get("/userPlatformRelation/getProvinceAndCity").then((res) => {
        if (res.result === 200) {
          this.options.splice(0);
          let data = res.data;
          for (let i in data) {
            let a = {};
            a.value = i;
            a.label = i;
            a.children = [];
            if (data[i] && data[i].length > 0) {
              for (let j of data[i]) {
                let b = {};
                b.value = i + ":" + j;
                b.label = i + " " + j;
                a.children.push(b);
              }
            }
            this.options.push(a);
          }
        }
      });
    },
    getGroupAccountList(accessKeyOrName) {
      let data = {
        pageNo: 1,
        pageSize: 5000,
        accessKeyOrName,
      };
      this.$http.get("/sms/platform/list", data).then((res) => {
        if (res.result === 200) {
          this.groupAccountList = res.data.records;
        }
      });
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/contentStrategy/deleteStrategy", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
    },
    // 获取enabled集合
    getenabledList() {
      let data = {
        param: "",
      };
      this.$http.enabledList(data).then((res) => {
        if (res.result === 200) {
          this.enabledList = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contentStrategy {
  .management-search {
    position: relative;
    overflow: hidden;
    .management-search-1 {
      float: left;
      margin-right: 20px;
      margin-bottom: 20px;
      height: 32px;
      line-height: 32px;
    }
    .backup {
      padding: 5px 0;
    }
    span {
      width: 240px;
    }
    input {
      width: 240px;
    }
    .ant-select {
      width: 240px;
    }
  }
  .projectList-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
    .request_nav_item {
      display: inline-block;
      margin-right: 20px;
      width: 350px;
      input {
        width: 250px;
      }
      .ant-select {
        width: 250px;
      }
    }
  }
}
.el-cascader {
  &::v-deep .el-cascader__search-input {
    min-width: 2px;
  }
}
.template {
  font-size: 14px;
  margin-bottom: 5px;
  color: #3a8ee6;
  text-align: right;
  cursor: pointer;
}
</style>
