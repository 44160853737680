<template>
  <div class="batchNumberReport">
    <div class="searchCondition">
      <div class="searchConditionItem">
        批次编号：
        <a-input
          v-model="searchBatchNumber"
          @keyup.enter="inquire(1)"
          placeholder="请输入批次编号"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        日期：
        <a-range-picker
          v-model="time"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 250px"
        />
      </div>
      <div class="searchConditionItem">
        类型：
        <a-select
          v-model="sendStatusSelect"
          placeholder="请选择类型"
          allowClear
        >
          <a-select-option
            v-for="i in sendStatusList"
            :key="i.value"
            :value="i.value"
            >{{ i.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        长链：
        <a-input
          v-model="searchLongUrl"
          @keyup.enter="inquire(1)"
          placeholder="请输入长链"
          allowClear
        />
      </div>
    </div>
    <div class="searchButton">
      <a-button type="primary" @click="inquire(1)">查 询</a-button>
    </div>
    <!-- 长链发送列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(urlReportColumns)"
      :columns="urlReportColumns"
      :dataSource="urlReportTableData"
      :pagination="urlReportPagination"
      :loading="loading"
      @change="handleTableChange"
      size="small"
    >
      <span slot="longUrl" slot-scope="text, record" class="slotCell">
        <a-tooltip placement="topLeft">
          <template slot="title">{{ record.longUrl }}</template>
          <span class="content">{{ record.longUrl }}</span>
        </a-tooltip>
        <a
          href="javascript:;"
          class="copy-class"
          v-clipboard:copy="record.longUrl"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-if="record.isCopyShow"
          >复制</a
        >
      </span>
      <span slot="clickRate" slot-scope="text, record">
        {{ record.clickRate / 100 }}%
      </span>
      <span slot="registerRate" slot-scope="text, record">
        {{ record.registerRate / 100 }}%
      </span>
      <span slot="msgCostLabel" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            <span>总成本：{{ record.totalCost }}</span>
            <a-divider type="vertical" />
            <span>短信成本：{{ record.msgCost }}</span>
            <a-divider type="vertical" />
            <span>语音成本：{{ record.voiceCost }}</span>
            <a-divider type="vertical" />
            <span>资源成本：{{ record.resourceCost }}</span>
          </template>
          <span class="send-color-total">{{ record.totalCost }}</span>
          <a-divider type="vertical" />
          <span class="send-color-success">{{ record.msgCost }}</span>
          <a-divider type="vertical" />
          <span class="send-color-wait">{{ record.voiceCost }}</span>
          <a-divider type="vertical" />
          <span class="send-color-fail">{{ record.resourceCost }}</span>
        </a-tooltip>
      </span>
      <span slot="msgUnitPriceLabel" slot-scope="text, record, index">
        <a-tooltip v-show="index !== urlReportTableData.length - 1">
          <template slot="title">
            <span>短信单价：{{ record.msgUnitPrice }}</span>
            <a-divider type="vertical" />
            <span>语音单价：{{ record.voiceUnitPrice }}</span>
            <a-divider type="vertical" />
            <span>资源单价：{{ record.resourceUnitPrice }}</span>
          </template>
          <span class="send-color-total">{{ record.msgUnitPrice }}</span>
          <a-divider type="vertical" />
          <span class="send-color-success">{{ record.voiceUnitPrice }}</span>
          <a-divider type="vertical" />
          <span class="send-color-fail">{{ record.resourceUnitPrice }}</span>
        </a-tooltip>
      </span>
    </a-table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "batchNumberReport",
  data() {
    return {
      time: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      gmtEndOpen: false,
      // yc多选下载
      selectedRowKeys: [],
      selectedRows: [], //多选选中的数据
      tableData: "",
      downloadId: "", //下载id
      downloadTimer: "", //刷新下载状态定时器
      // 排序
      clickRateOrder: "",
      registerRateOrder: "",
      desc: "",
      sort: "",
      //  ycEnd
      batchNumber: "",
      msgUnitPrice: "",
      voiceUnitPrice: "",
      resourceUnitPrice: "",
      urlReportId: "",
      sendStatusSelect: undefined,
      sendStatusList: [
        {
          value: "BATCH_SEND",
          label: "群发",
        },
        {
          value: "API_MESSAGE",
          label: "挂短",
        },
      ],
      loading: false,
      urlReportColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 80,
        },
        {
          title: "发送日期",
          ellipsis: true,
          dataIndex: "date",
          width: 100,
        },
        {
          title: "批次编号",
          ellipsis: true,
          dataIndex: "batchNumber",
          width: 80,
        },
        {
          title: "长链",
          ellipsis: true,
          dataIndex: "longUrl",
          customCell: this.mouseoverLongUrl,
          scopedSlots: { customRender: "longUrl" },
          width: 450,
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "typeLabel",
          width: 50,
        },
        {
          title: "发送条数",
          ellipsis: true,
          dataIndex: "totalCount",
          width: 80,
        },
        {
          title: "成功条数",
          ellipsis: true,
          dataIndex: "successCount",
          width: 80,
        },
        {
          title: "成功率",
          ellipsis: true,
          dataIndex: "successPert",
          width: 80,
        },
        {
          title: "失败条数",
          ellipsis: true,
          dataIndex: "failureCount",
          width: 80,
        },
        {
          title: "等待回执条数",
          ellipsis: true,
          dataIndex: "waitCallbackCount",
          width: 120,
        },
        {
          title: "点击pv",
          ellipsis: true,
          dataIndex: "clickPv",
          width: 80,
        },
        {
          title: "点击uv",
          ellipsis: true,
          dataIndex: "clickUv",
          width: 80,
        },
      ],
      urlReportTableData: [],
      urlReportPagination: {},
      urlReportTablePageNo: 1,
      urlReportTablePageSize: 10,
      searchBatchNumber: "",
      searchLongUrl: "",
      syncLoading: null,
      timerStatistics: null,
    };
  },
  mounted() {
    this.inquire(1);
    this.getStatisticsDateUrlReportStatus();
    this.setIntervalStatistics();
  },
  methods: {
    downloadStatus() {
      this.downloadTimer = setInterval(() => {
        let data = {
          id: this.downloadId,
        };
        this.$http.get("/report/file/state", data).then((res) => {
          if (res.result === 200) {
            this.syncLoading.close();
            if (res.data.status === "DOWNLOADABLE") {
              let data = {
                id: this.downloadId,
              };
              this.$http.get("/report/file/download", data).then((res) => {
                clearInterval(this.downloadTimer);
                this.downloadTimer = null;
                let href =
                  this.$path +
                  "/report/file/download?" +
                  "id=" +
                  this.downloadId;
                console.log(href);
                window.location.href = href;
              });
            } else if (res.data.status === "EXCEPTION") {
              clearInterval(this.downloadTimer);
              this.downloadTimer = null;
              this.$message.error("下载失败");
            }
          } else {
            clearInterval(this.downloadTimer);
            this.downloadTimer = null;
          }
        });
      }, 2000);
    },
    // yc排序
    handleTableChange(pagination, filters, { field, order }) {
      if (field == "clickRate" && order == "ascend") {
        this.desc = "ASC";
        this.sort = "";
      } else if (field == "clickRate" && order == "descend") {
        this.desc = "DESC";
        this.sort = "";
      } else if (field == "registerRate" && order == "ascend") {
        this.sort = "ASC";
        this.desc = "";
      } else if (field == "registerRate" && order == "descend") {
        this.sort = "DESC";
        this.desc = "";
      }
      this.inquire();
    },
    // 刷新统计数据状态
    setIntervalStatistics() {
      if (this.timerStatistics) {
        clearInterval(this.timerStatistics);
        this.timerStatistics = null;
      }
      this.timerStatistics = setInterval(() => {
        this.getStatisticsDateUrlReportStatus();
      }, 5000);
    },
    // 查询统计数据状态
    getStatisticsDateUrlReportStatus() {
      this.$http.get("/report/statisticsDateUrlReportStatus").then((res) => {
        // if (res.result === 200) {
        //   this.statisticsDataDisabled = res.data.statisticsIng;
        // }
      });
    },
    // 鼠标移入长链列--智能短链
    mouseoverLongUrl(record, rowIndex) {
      return {
        on: {
          // 事件
          click: (event) => {}, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {
            // 鼠标移入行
            this.urlReportTableData.forEach((item) => {
              if (record.id === item.id && record.id) {
                this.$set(
                  this.urlReportTableData[rowIndex],
                  "isCopyShow",
                  true
                );
              }
            });
          },
          mouseleave: (event) => {
            // 鼠标移出行
            this.urlReportTableData.forEach((item) => {
              if (record.id === item.id && record.id) {
                this.$set(
                  this.urlReportTableData[rowIndex],
                  "isCopyShow",
                  false
                );
              }
            });
          },
        },
      };
    },
    // 复制成功
    onCopy(e) {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError(e) {
      this.$message.error("复制失败");
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.urlReportTablePageNo = index;
      }
      let data = {
        pageNo: this.urlReportTablePageNo,
        pageSize: this.urlReportTablePageSize,
        batchNumber: this.searchBatchNumber,
        longUrl: this.searchLongUrl,
        startDate: this.time[0],
        endDate: this.time[1],
        type: this.sendStatusSelect,
        clickRateOrder: this.desc !== "" ? this.desc : undefined,
        registerRateOrder: this.sort !== "" ? this.sort : undefined,
      };
      this.urlReportTableData = [];
      this.getUrlReportList(data);
    },
    getUrlReportList(data) {
      this.loading = true;
      this.$http
        .get("/batchNumberReport/list", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.tableData = [];
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
              item.typeLabel = "";
              if (item.msgUnitPrice) {
                item.msgUnitPrice = item.msgUnitPrice / 1000;
              } else {
                item.msgUnitPrice = 0;
              }
              if (item.voiceUnitPrice) {
                item.voiceUnitPrice = item.voiceUnitPrice / 1000;
              } else {
                item.voiceUnitPrice = 0;
              }
              if (item.resourceUnitPrice) {
                item.resourceUnitPrice = item.resourceUnitPrice / 1000;
              } else {
                item.resourceUnitPrice = 0;
              }
              item.isCopyShow = false;
              switch (item.type) {
                case "BATCH_SEND":
                  item.typeLabel = "群发";
                  break;
                case "API_MESSAGE":
                  item.typeLabel = "挂短";
                  break;
              }
            });
            this.urlReportTableData = list;
            this.urlReportPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.urlReportTablePageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.urlReportChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 翻页
    urlReportChangePage(index) {
      this.inquire(index);
    },
    // 每页
    onShowSizeChange(pageSize) {
      this.urlReportTablePageSize = pageSize;
      this.inquire(1);
    },
  },
  beforeDestroy() {
    if (this.timerStatistics) {
      clearInterval(this.timerStatistics);
      this.timerStatistics = null;
    }
  },
};
</script>
<style lang="scss" scoped>
.copy-class {
  color: #52c41a;
}
::v-deep .ant-progress-outer {
  width: 90% !important;
}
</style>
