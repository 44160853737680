<template>
  <div class="smmSmarketingRecords">
    <a-tabs v-model="tabsKey" @change="callback">
      <a-tab-pane key="massTexting" tab="群发短信">
        <div class="searchCondition">
          <div class="searchConditionItem">
            名称：
            <a-input
              placeholder="请输入活动名称"
              v-model="massTextingCondition.activityCode"
              @keyup.enter="inquire(1)"
              allowClear
            />
          </div>
          <div class="searchConditionItem">
            内容：
            <a-input
              placeholder="请输入内容"
              v-model="massTextingCondition.content"
              @keyup.enter="inquire(1)"
              allowClear
            />
          </div>
          <div class="searchConditionItem">
            发送时间：
            <a-range-picker
              v-model="massTextingCondition.rangeTime"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :ranges="range"
              style="width: 250px"
            />
          </div>
          <div class="searchConditionItem">
            状态：
            <a-select
              v-model="massTextingCondition.status"
              placeholder="请选择标签"
              class="select-tag"
            >
              <a-select-option
                v-for="i in statusList"
                :key="i.status"
                :value="i.status"
                >{{ i.label }}</a-select-option
              >
            </a-select>
          </div>
          <div class="searchConditionItem">
            账号：
            <a-select
              v-model="massTextingCondition.accountAccessKey"
              placeholder="请选择账号"
              class="select-tag"
              allowClear
              showSearch
              :filterOption="false"
              @search="getReSendPlatformList"
              @change="inquire()"
            >
              <a-select-option
                v-for="item in reSendPlatformList"
                :key="item.id"
                :value="item.accessKey"
                >{{ item.accessKey }} | {{ item.alias }}</a-select-option
              >
            </a-select>
          </div>
          <div class="searchConditionItem" v-if="roleCode === 'SUPER_MANAGER'">
            用户：
            <a-select
              v-model="massTextingCondition.userLoginName"
              placeholder="请选择用户"
              class="select-tag"
              allowClear
              showSearch
              @search="getUserList"
              @change="inquire()"
            >
              <a-select-option
                v-for="i in userList"
                :key="i.id"
                :value="i.userLoginName"
                >{{ i.userLoginName }}</a-select-option
              >
            </a-select>
          </div>
          <div class="searchConditionItem">
            运营商通道：
            <a-select
              v-model="massTextingCondition.channelSmsAccountId"
              placeholder="请选择运营商通道"
              class="select-tag"
              allowClear
              :filterOption="false"
              @search="searchSmsAccountList"
              showSearch
            >
              <a-select-option
                v-for="item in smsAccountList"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="inquire(1)">查 询</a-button>
            <a-button type="primary" @click="inquire()">刷 新</a-button>
            <a-dropdown :disabled="selectedRows.length == 0">
              <a-button type="primary">
                批量操作 <a-icon type="down" />
              </a-button>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;" @click="batchReSendMsgPlan"
                    >批量重新发送</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a
                    href="javascript:;"
                    @click="batchMix"
                    v-if="roleCode === 'SUPER_MANAGER' && confusion"
                    >批量混淆</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="batchAudit">批量审核</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="batchRecovery">批量恢复</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="batchPlanSendTimeClick"
                    >批量修改发送时间</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="batchModifyAccountAccessKey"
                    >批量修改账号</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="batchTextReplace"
                    >批量替换文本</a
                  >
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
        <!-- 群发短信记录表 -->
        <a-table
          bordered
          :rowClassName="$common.rowClassColor"
          :components="$common.getTitle(columns)"
          :columns="columns"
          :dataSource="tableData"
          :pagination="pagination"
          size="small"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :rowKey="(record) => record.id"
        >
          <span slot="sendTime" slot-scope="text, record">
            {{
              (record.sendStatus === "SENDING" ||
                record.sendStatus === "WAITING") &&
              record.auditStatus == "AUDIT_SUCCESS"
                ? text
                : "-"
            }}
          </span>
          <span
            slot="platformName"
            slot-scope="text, record"
            style="width: 100%"
          >
            <a-tooltip placement="topLeft">
              <template slot="title"
                >{{ record.platformName }}(
                {{ record.accountAccessKey }} )</template
              >
              <span>{{ text }} ( {{ record.accountAccessKey }} )</span>
            </a-tooltip>
          </span>
          <span
            slot="number"
            slot-scope="text, record"
            style="display: inline-block; width: 100%"
          >
            <div>
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <div>
                    <div>总条数:{{ record.planSendNums }}</div>
                    <div>
                      发送条数:{{ record.sendCounts }} | {{ record.sendsRate }}
                    </div>
                    <div>短信消耗数:{{ record.msgNums }}</div>
                  </div>
                </template>
                <a
                  href="javascript:;"
                  @click="viewConnected(record)"
                  class="send-color-total"
                  >{{ record.planSendNums }}</a
                >
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip
                placement="topLeft"
                @visibleChange="(visible) => getNonIosClickUv(visible, record)"
              >
                <template slot="title">
                  <div>
                    发送成功条数:{{ record.successSendCount }} |
                    {{ record.successSendRate }}<br />
                    点击pv数:{{ record.clickCount }} | {{ record.clickRate
                    }}<br />
                    点击uv数:{{ record.clickUvCount }} | {{ record.clickUvRate
                    }}<br />
                    安卓uv数:{{ androidUvCount }} |
                    {{ androidUvRate }}
                  </div>
                </template>
                <a
                  href="javascript:;"
                  @click="viewConnected(record, 'SUCCESS')"
                  class="send-color-success"
                  >{{ record.successSendCount }}</a
                >
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <div>
                    等待回执条数:{{ record.waitCallbackCount }} |
                    {{ record.waitCallbackRate }}
                  </div>
                </template>
                <a
                  href="javascript:;"
                  @click="
                    viewConnected(record, 'SUBMIT_SUCCESS,PRE_SUBMIT_SUCCESS')
                  "
                  class="send-color-wait"
                  >{{ record.waitCallbackCount }}</a
                >
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <div>
                    发送失败条数:{{ record.failureSendCount }} |
                    {{ record.failureSendRate }}
                  </div>
                </template>
                <a
                  href="javascript:;"
                  @click="viewConnected(record, 'FAILURE,SUBMIT_FAILURE')"
                  class="send-color-fail"
                  >{{ record.failureSendCount }}</a
                >
              </a-tooltip>
              <span style="margin-left: 10px"></span>
              <a-tooltip
                placement="topLeft"
                v-if="record.msgNums / record.planSendNums >= 1.5"
              >
                <template slot="title">
                  <div>注意，短信消耗数大幅超过发送数</div>
                </template>
                <a-icon
                  type="exclamation-circle"
                  theme="twoTone"
                  two-tone-color="#FF9224"
                />
              </a-tooltip>
            </div>
          </span>
          <span
            slot="activityCodeLabel"
            slot-scope="text, record"
            style="width: 100%"
          >
            <a-tooltip placement="top" v-if="record.preSubmit">
              <template slot="title">已定时预提交</template>
              <span class="icon-yu">预</span>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">{{ record.activityCodeLabel }}</template>
              {{ text }}
            </a-tooltip>
          </span>
          <span slot="content" slot-scope="text, record" class="slotCell">
            <a-tooltip placement="top">
              <template slot="title">{{ record.content }}</template>
              <span class="content">{{ text }}</span>
            </a-tooltip>
          </span>
          <span slot="status" slot-scope="text, record" style="width: 100%">
            <div v-if="record.auditStatus === 'AUDIT_FAILURE'">
              <a-tag color="red">审核失败</a-tag>
            </div>
            <div v-else>
              <a-tag v-if="record.sendStatusTag === 'orange'" color="orange">
                {{ text }}
                <a-icon
                  type="loading"
                  class="icon-loading"
                  v-if="record.isIconShow"
                />
              </a-tag>
              <a-tag v-if="record.sendStatusTag === 'green'" color="green">{{
                text
              }}</a-tag>
              <a-tag v-if="record.sendStatusTag === 'red'" color="red">{{
                text
              }}</a-tag>
            </div>
          </span>
          <span slot="action" slot-scope="text, record" style="width: 100%">
            <a
              href="javascript:;"
              @click="viewConnected(record)"
              :disabled="record.closed || record.sendStatus === 'CREATING'"
              >查看详情</a
            >
            <template v-if="record.auditStatus === 'WAIT_AUDIT'">
              <a-divider type="vertical" />
              <a
                href="javascript:;"
                :disabled="
                  record.closed ||
                  record.sendStatus === 'CREATING' ||
                  (!(record.userLoginName == userLoginName && auditable) &&
                    roleCode !== 'SUPER_MANAGER')
                "
                @click="audit(record)"
                >待审核</a
              >
            </template>
            <template
              v-if="
                (record.sendStatus === 'SENDING' ||
                  record.sendStatus === 'WAITING') &&
                record.auditStatus == 'AUDIT_SUCCESS'
              "
            >
              <a-divider type="vertical" />
              <a
                href="javascript:;"
                @click="suspendClick(record)"
                :disabled="record.closed"
                >暂停</a
              >
            </template>
            <template v-if="record.sendStatus == 'PAUSED'">
              <a-divider type="vertical" />
              <a
                href="javascript:;"
                @click="recoveryClick(record)"
                :disabled="record.closed || record.sendStatus !== 'PAUSED'"
                >恢复</a
              >
            </template>
            <a-divider type="vertical" v-if="roleCode === 'SUPER_MANAGER'" />
            <a-dropdown
              :trigger="['click']"
              v-if="roleCode === 'SUPER_MANAGER'"
            >
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                其它 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a
                    href="javascript:;"
                    @click="recreateClick(record)"
                    :disabled="
                      record.closed || record.sendStatus === 'CREATING'
                    "
                    >重新创建</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a
                    href="javascript:;"
                    @click="modifyActiveCode(record)"
                    :disabled="record.closed"
                    >修改发送名称</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a
                    href="javascript:;"
                    @click="planSendTimeClick(record)"
                    :disabled="
                      record.closed ||
                      (record.auditStatus !== 'WAIT_AUDIT' &&
                        record.sendStatus !== 'WAITING')
                    "
                    >修改发送时间</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a
                    href="javascript:;"
                    @click="sendAnalysisClick(record)"
                    :disabled="
                      record.closed ||
                      (record.sendStatus !== 'FINISHED' &&
                        record.sendStatus !== 'PAUSED')
                    "
                    >发送分析</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a
                    href="javascript:;"
                    @click="aisleClick(record)"
                    :disabled="record.closed"
                    >通道分析</a
                  >
                </a-menu-item>
                <a-menu-item v-if="confusion">
                  <a
                    href="javascript:;"
                    @click="mixClick(record)"
                    :disabled="record.closed"
                    >数据混淆</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a
                    href="javascript:;"
                    @click="repair(record)"
                    :disabled="record.closed"
                    >重新统计</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a
                    href="javascript:;"
                    @click="reSendMsgPlan(record)"
                    :disabled="record.closed"
                    >重新发送</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a
                    href="javascript:;"
                    @click="speedControl(record)"
                    :disabled="record.closed"
                    >速度控制</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="updateClosedState(record)">
                    {{ record.closed ? "开启" : "关闭" }}批次
                  </a>
                </a-menu-item>
                <a-menu-item>
                  <a
                    href="javascript:;"
                    @click="smsDeleteClick(record)"
                    class="send-color-fail"
                    :disabled="
                      record.closed || record.sendStatus === 'FINISHED'
                    "
                    >删除</a
                  >
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="interfaceSMS" tab="接口短信">
        <div class="searchCondition">
          <div class="searchConditionItem">
            批次编号：
            <a-input
              placeholder="请输入批次编号"
              v-model="activityCode"
              @keyup.enter="inquireInterface(1)"
              allowClear
            />
          </div>
          <div class="searchConditionItem">
            手机号：
            <a-input
              placeholder="请输入手机号"
              v-model="mobile"
              @keyup.enter="inquireInterface(1)"
              allowClear
            />
          </div>
          <div class="searchConditionItem" v-if="roleCode === 'SUPER_MANAGER'">
            用户：
            <a-select
              v-model="userName"
              placeholder="请选择用户"
              class="select-tag"
              allowClear
              showSearch
              @search="getUserList"
              @change="inquireInterface()"
            >
              <a-select-option
                v-for="i in userList"
                :key="i.id"
                :value="i.userLoginName"
                >{{ i.userLoginName }}</a-select-option
              >
            </a-select>
          </div>
          <div class="searchConditionItem">
            创建时间：
            <a-date-picker
              :disabledDate="disabledGmtCreatedStartDateInterface"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              v-model="interfaceStartTime"
              placeholder="开始日期"
              @openChange="handleGmtCreatedStartOpenChangeInterface"
            />
            <i style="display: inline-block; margin-left: 20px"></i>
            <a-date-picker
              :disabledDate="disabledGmtCreatedEndDateInterface"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              placeholder="结束日期"
              v-model="interfaceEndTime"
              :open="gmtEndOpenInterface"
              @openChange="handleGmtCreatedEndOpenChangeInterface"
            />
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="inquireInterface(1)"
              >查 询</a-button
            >
            <a-button type="primary" @click="inquireInterface()"
              >刷 新</a-button
            >
          </div>
        </div>
        <!-- 接口短信记录表 -->
        <a-table
          bordered
          :rowClassName="$common.rowClassColor"
          :components="$common.getTitle(interfaceColumns)"
          :columns="interfaceColumns"
          :dataSource="interfaceTableData"
          :pagination="interfacePagination"
          size="small"
        >
          <span
            slot="platformName"
            slot-scope="text, record"
            style="width: 100%"
          >
            <span>{{ text }} ( {{ record.accessKey }} )</span>
          </span>
          <span slot="sendCountTitle">
            条数
            <a-tooltip>
              <template slot="title"> 实时 </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <span slot="statisticsInTheLast5MinutesTitle">
            最近5分钟统计
            <a-tooltip>
              <template slot="title"> 实时 </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <span slot="sendCount" slot-scope="text, record" style="width: 100%">
            <a-tooltip placement="topLeft">
              <template slot="title">
                <div>总条数:{{ record.planSendNums }}</div>
                <div>
                  发送条数:{{ record.sendCounts }} | {{ record.sendsRate }}
                </div>
              </template>
              <a
                href="javascript:;"
                @click="viewConnected(record)"
                class="send-color-total"
                >{{ record.planSendNums }}</a
              >
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip placement="topLeft">
              <template slot="title">
                <div>待审核条数:{{ record.waitAuditCount }}</div>
              </template>
              <a href="javascript:;" class="send-color-audit">{{
                record.waitAuditCount
              }}</a>
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip
              placement="topLeft"
              @visibleChange="(visible) => getNonIosClickUv(visible, record)"
            >
              <template slot="title">
                发送成功条数:{{ record.successSendCount }} |
                {{ record.successSendRate }}<br />
                点击pv数:{{ record.clickCount }} | {{ record.clickRate }}<br />
                点击uv数:{{ record.clickUvCount }} | {{ record.clickUvRate
                }}<br />
                安卓uv数:{{ androidUvCount }} |
                {{ androidUvRate }}
              </template>
              <a
                href="javascript:;"
                @click="viewConnected(record, 'SUCCESS')"
                class="send-color-success"
                >{{ record.successSendCount }}</a
              >
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip placement="topLeft">
              <template slot="title"
                >提交未返回条数:{{ record.waitCallbackCount }} |
                {{ record.waitCallbackRate }}</template
              >
              <a
                href="javascript:;"
                @click="viewConnected(record, 'SUBMIT_SUCCESS')"
                class="send-color-wait"
                >{{ record.waitCallbackCount }}</a
              >
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip placement="topLeft">
              <template slot="title"
                >发送失败条数:{{ record.failureSendCount }} |
                {{ record.failureSendRate }}</template
              >
              <a
                href="javascript:;"
                @click="viewConnected(record, 'FAILURE,SUBMIT_FAILURE')"
                class="send-color-fail"
                >{{ record.failureSendCount }}</a
              >
            </a-tooltip>
          </span>
          <span
            slot="statisticsInTheLast5Minutes"
            slot-scope="text, record"
            style="width: 100%"
          >
            <a-tooltip placement="topLeft">
              <template slot="title">最近5分钟总条数:{{ text }}</template>
              <span style="color: #409eff">{{ text }}</span>
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip placement="topLeft">
              <template slot="title"
                >最近5分钟发送成功条数:{{ record.recentSuccessCount }} |
                {{ record.recentSuccessSendRate }}</template
              >
              <span style="color: #67c23a">{{
                record.recentSuccessCount
              }}</span>
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip placement="topLeft">
              <template slot="title"
                >最近5分钟提交未返回条数:{{ record.recentWaitCallbackCount }} |
                {{ record.recentWaitCallbackRate }}</template
              >
              <span style="color: #e6a23c">{{
                record.recentWaitCallbackCount
              }}</span>
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip placement="topLeft">
              <template slot="title"
                >最近5分钟统计发送失败条数:{{ record.recentFailureCount }} |
                {{ record.recentFailureSendRate }}</template
              >
              <span style="color: #f56c6c">{{
                record.recentFailureCount
              }}</span>
            </a-tooltip>
          </span>
          <span
            slot="action"
            slot-scope="text, record, index"
            style="width: 100%"
          >
            <a href="javascript:;" @click="viewConnected(record)">查看详情</a>
            <a-divider type="vertical" />
            <a
              href="javascript:;"
              @click="interfaceConnectedRefresh(record, index)"
              disabled
              >刷新</a
            >
            <a-divider type="vertical" v-if="roleCode === 'SUPER_MANAGER'" />
            <a-dropdown
              :trigger="['click']"
              v-if="roleCode === 'SUPER_MANAGER'"
            >
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                其它 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a
                    href="javascript:;"
                    @click="sendAnalysisClick(record)"
                    :disabled="
                      !(
                        record.sendStatus === 'FINISHED' ||
                        record.sendStatus === 'PAUSED' ||
                        record.sendStatus === 'WAITING'
                      )
                    "
                    >发送分析</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="repair(record)">重新统计</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="aisleClick(record)"
                    >通道分析</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="reSendMsgPlan(record)"
                    >重新发送</a
                  >
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <!-- 群发短信详情表 -->
    <a-modal
      :title="sendTitle"
      v-model="sendShow"
      :footer="null"
      :maskClosable="false"
      :afterClose="sendClose"
      width="1100px"
      class="actionSub-class"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          客户编号：
          <a-input
            placeholder="请输入客户编号"
            v-model="sendMobile"
            @keyup.enter="sendInquire(1)"
            allowClear
          />
        </div>
        <div class="searchConditionItem">
          状态：
          <a-select
            v-model="statusSend"
            placeholder="请选择状态"
            mode="multiple"
            class="select-tag"
            @change="statusSendChange"
            allowClear
            :maxTagCount="1"
          >
            <a-select-option
              v-for="i in statusSendList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </div>
        <div class="searchConditionItem">
          真实状态：
          <a-input
            placeholder="请输入真实状态"
            v-model="receiveRealStatus"
            @keyup.enter="sendInquire(1)"
            allowClear
          />
        </div>
        <div class="searchConditionItem">
          内容：
          <a-input
            placeholder="请输入内容"
            v-model="contentSearch"
            @keyup.enter="sendInquire(1)"
            allowClear
          />
        </div>
        <div class="searchConditionItem">
          提交时间：
          <a-date-picker
            :disabledDate="disabledGmtCreatedStartDateSend"
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            v-model="sendStartTime"
            placeholder="开始日期"
            @openChange="handleGmtCreatedStartOpenChangeSend"
          />
          <i style="display: inline-block; margin-left: 20px"></i>
          <a-date-picker
            :disabledDate="disabledGmtCreatedEndDateSend"
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="结束日期"
            v-model="sendEndTime"
            :open="gmtEndOpenSend"
            @openChange="handleGmtCreatedEndOpenChangeSend"
          />
        </div>
        <div class="searchConditionItem">
          运营商：
          <a-select
            v-model="phoneOperator"
            placeholder="请选择运营商"
            mode="multiple"
            class="select-tag"
            allowClear
            :maxTagCount="1"
          >
            <a-select-option
              v-for="i in phoneOperatorList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </div>
        <div class="searchConditionItem">
          运营商通道：
          <a-select
            v-model="channelSmsAccountId"
            placeholder="请选择运营商通道"
            class="select-tag"
            allowClear
            :filterOption="false"
            @search="searchSmsAccountList"
            showSearch
          >
            <a-select-option
              v-for="item in smsAccountList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>
        <div class="searchButton">
          <a-button
            type="primary"
            @click="sendInquire(1)"
            style="margin-right: 10px"
            >查询</a-button
          >
          <a-button type="primary" @click="exportClick">导出</a-button>
        </div>
      </div>
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(columnsDetailsTable)"
        :columns="columnsDetailsTable"
        :dataSource="detailsTable"
        :pagination="paginationDetails"
        size="small"
        :loading="loadingDetailsTable"
      >
        <span slot="content" slot-scope="text, record" class="slotCell">
          <a-tooltip placement="topLeft">
            <template slot="title">
              <span v-if="record.short_url">
                {{ record.contentStart }}
                <a :href="record.short_url_blank" target="_blank">{{
                  record.short_url
                }}</a>
                {{ record.contentEnd }}
              </span>
              <span v-else>{{ record.content }}</span>
            </template>
            <span class="content">{{ text }}</span>
          </a-tooltip>
          <a
            href="javascript:;"
            class="copy-class"
            v-clipboard:copy="record.content"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            v-if="record.isCopyShow"
            >复制</a
          >
        </span>
        <span
          slot="receiveRealStatus"
          slot-scope="text, record"
          style="width: 100%"
        >
          <a-tooltip placement="topLeft" style="margin-left: 5px">
            <template slot="title">
              {{ record.receiveRealStatusCh }}
            </template>
            <span>{{ text }}</span>
          </a-tooltip>
          <a
            href="javascript:;"
            @click="getRouteExceptionInfo(record)"
            v-if="text == 'HY:0004'"
            style="color: #fa8c16; margin-left: 5px"
          >
            <a-icon type="exclamation-circle" />
          </a>
        </span>
        <span slot="status" slot-scope="text, record" style="width: 100%">
          <a-tag v-if="record.sendStatusTag === 'orange'" color="orange">{{
            text
          }}</a-tag>
          <a-tag v-if="record.sendStatusTag === 'green'" color="green">{{
            text
          }}</a-tag>
          <a-tag v-if="record.sendStatusTag === 'red'" color="red">{{
            text
          }}</a-tag>
          <a-tooltip
            v-if="
              (record.sendStatus == 'SUBMIT_FAILURE' ||
                record.sendStatus == 'FAILURE') &&
              record.errorMessage
            "
          >
            <template slot="title">{{ record.errorMessage }}</template>
            <a-icon type="exclamation-circle" />
          </a-tooltip>
        </span>
      </a-table>
    </a-modal>
    <!-- 重新创建 -->
    <a-modal
      :title="'【' + smsTaskName + '】重新创建'"
      v-model="smsInputShow"
      :maskClosable="false"
      :afterClose="smsInputClose"
      class="actionSub-class"
      width="800px"
    >
      <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="发送名称">
          <a-input
            v-model="msgSMSName"
            placeholder="请输入名称"
            allowClear
            style="width: 80%"
          />
        </a-form-item>
        <a-form-item label="选择账号">
          <a-select
            v-model="accessKey"
            @change="platformChange"
            placeholder="请选择账号"
            class="select-tag"
            style="width: 80%"
            showSearch
            @search="getUserSmsAccount"
            :filterOption="filterOption"
          >
            <a-select-option
              v-for="i in platformRelationList"
              :key="i.id"
              :value="i.id"
              >{{ i.accessKey }} | {{ i.alias }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="选择签名">
          <a-select
            :value="signId"
            @change="sighChange"
            placeholder="请选择签名"
            class="select-tag"
            style="width: 80%"
          >
            <a-select-option
              v-for="i in signList"
              :key="i.signId"
              :value="i.signId"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="选择状态" class="status">
          <a-switch
            checked-children="全部状态"
            un-checked-children="特定状态"
            v-model="statusShow"
          />
          <i style="display: inline-block; margin-right: 10px"></i>
          <a-select
            v-show="!statusShow"
            v-model="realReceiveStatus"
            placeholder="请选择真实状态"
            class="select-tag"
            style="width: 40%"
            mode="multiple"
          >
            <a-select-option
              v-for="(item, index) in reSendList"
              :key="index"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
          <i style="display: inline-block; margin-right: 10px"></i>
          <a-select
            v-show="!statusShow"
            v-model="sendStatus"
            placeholder="请选择发送状态"
            class="select-tag"
            style="width: 40%"
            mode="multiple"
          >
            <a-select-option
              v-for="i in sendList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="选择运营商" class="status">
          <a-switch
            checked-children="全部运营商"
            un-checked-children="特定运营商"
            v-model="phoneOperatorShow"
          />
          <i style="display: inline-block; margin-right: 10px"></i>
          <a-select
            v-model="phoneOperator"
            placeholder="请选择运营商"
            mode="multiple"
            class="select-tag"
            style="width: 40%"
            allowClear
            :maxTagCount="1"
            v-show="!phoneOperatorShow"
          >
            <a-select-option
              v-for="i in phoneOperatorList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="短信内容">
          <a-tabs v-model="contentActive" @change="contentCallback">
            <a-tab-pane key="1" tab="短信内容">
              <sms-input
                :textareaSMS="textareaSMS"
                :isDisabled="isDisabled"
                :sign="sign"
                :longUrlValue="longUrlValue"
                :longUrlGroupIdValue="longUrlGroupIdValue"
                :prefixValue="prefixValue"
                :urlFormat="urlFormat"
                :urlTitleShow="false"
                :domain="selectDomain"
                :selectDomainList="selectDomainList"
                @selectDomainChange="selectDomainChange"
                @change="textarea_sms"
                @longUrlChange="longUrlChange"
                @longUrlGroupIdChange="longUrlGroupIdChange"
                @prefixChange="prefixChange"
                @urlFormatChange="urlFormatChange"
              ></sms-input>
            </a-tab-pane>
            <a-tab-pane key="2" tab="选择模板">
              <a-select
                v-model="strategyId"
                placeholder="请选择模板"
                class="select-tag"
                showSearch
                @search="getStrategyList"
                style="width: 300px"
              >
                <a-select-option
                  v-for="i in strategyList"
                  :key="i.id"
                  :value="i.id"
                  >{{ i.name }}</a-select-option
                >
              </a-select>
              <div
                v-if="textareaSMS.indexOf('#短链#') !== -1"
                class="sms-info-style"
              >
                前缀：
                <a-radio-group v-model="prefixValue" style="margin-left: 28px">
                  <a-radio value="https://">https://</a-radio>
                  <a-radio value="none">none</a-radio>
                </a-radio-group>
              </div>
              <div
                class="sms-info-style"
                v-if="longUrlValue && !longUrlGroupIdValue"
              >
                <span
                  v-if="textareaSMS.indexOf('#短链#') !== -1"
                  class="info-required"
                >
                  长链：
                  <a-input
                    v-model="longUrlValue"
                    placeholder="请输入长链"
                    @blur="compareToLongUrl(longUrlValue)"
                    style="width: 300px; margin-left: 28px"
                  />
                  <div
                    v-if="urlFormat"
                    class="dropbox-ts"
                    style="
                      color: red;
                      font-size: 12px;
                      margin-left: 76px;
                      line-height: 20px;
                    "
                  >
                    长网址链接格式不正确
                  </div>
                </span>
              </div>
              <div class="sms-info-style" v-if="longUrlGroupIdValue">
                <span
                  v-if="textareaSMS.indexOf('#短链#') !== -1"
                  class="info-required"
                >
                  长链分组：
                  <a-select
                    v-model="longUrlGroupIdValue"
                    placeholder="请选择长链分组"
                    showSearch
                    :filterOption="false"
                    @search="getLongUrlGroupList"
                    style="width: 300px"
                  >
                    <a-select-option
                      v-for="i in longUrlGroupList"
                      :key="i.id"
                      :value="i.id"
                      >{{ i.name }}</a-select-option
                    >
                  </a-select>
                </span>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 15 }"
          label="点击回调:"
        >
          <a-select
            v-model="clickCallbackUrlIdList"
            placeholder="请选择"
            class="select-tag"
            mode="multiple"
            showSearch
            :filterOption="false"
            @search="getClickCallbackUrlList"
          >
            <a-select-option
              v-for="item in clickCallbackUrlList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 24 }">
          <i style="display: inline-block; margin-left: 116px"></i>
          <a-checkbox v-model="filterExceptionNumber">
            过滤停机空号黑名单数据
          </a-checkbox>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 24 }">
          <i style="display: inline-block; margin-left: 116px"></i>
          <a-checkbox v-model="checkedTiming">定时发送</a-checkbox>
          <a-date-picker
            v-if="checkedTiming"
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            v-model="sendTime"
            placeholder="选择日期时间"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="smsInputShow = false">取消</a-button>
          <a-button type="primary" @click="smsInput_submit">确定</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 修改发送名称 -->
    <a-modal
      :title="'【' + smsTaskName + '】修改发送名称'"
      v-model="modifyActiveCodeShow"
      :maskClosable="false"
      :afterClose="modifyActiveCodeClose"
      class="actionSub-class"
      width="400px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="发送名称"
        >
          <a-input
            v-model="msgSMSName"
            placeholder="请输入名称"
            allowClear
            style="width: 80%"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="modifyActiveCodeShow = false">取消</a-button>
          <a-button type="primary" @click="modifyActiveCode_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 修改发送时间 -->
    <a-modal
      :title="'【' + smsTaskName + '】修改发送时间'"
      v-model="planSendTimeShow"
      :maskClosable="false"
      :afterClose="planSendTimeClose"
      class="actionSub-class"
      width="400px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="定时发送"
        >
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            v-model="sendTime"
            placeholder="选择日期时间"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="planSendTimeShow = false">取消</a-button>
          <a-button type="primary" @click="planSendTime_submit">确定</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="修改发送时间中"
      v-model="batchPlanSendTimeShow"
      class="action-class"
      width="500px"
      :footer="null"
      :maskClosable="false"
      :closable="false"
    >
      <div
        v-for="(item, index) in batchPlanSendTimeClickList"
        :key="index"
        style="margin-bottom: 40px"
      >
        <span style="display: inline-block; width: 300px; text-align: right">
          {{ item.name }}
        </span>
        <span style="margin-left: 20px">
          <a-icon
            v-if="item.state == 'running'"
            type="loading"
            style="color: #e6a23c"
          />
          <a-icon
            v-else-if="item.state == 'waiting'"
            type="play-circle"
            style="color: #1890ff"
          />
          <a-icon v-else type="check-circle" style="color: #84cf65" />
        </span>
      </div>
    </a-modal>
    <!-- 发送分析 -->
    <a-modal
      :title="sendAnalysisTitle"
      v-model="sendAnalysisShow"
      :footer="null"
      :maskClosable="false"
      width="800px"
    >
      <a-tabs v-model="activeKey" @change="sendAnalysisCallback">
        <a-tab-pane key="1" tab="真实状态分析">
          <div style="height: 505px">
            <div id="planRealStatus" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="真实状态列表">
          <div class="searchCondition">
            <div class="searchConditionItem">
              通道名称：
              <a-input
                placeholder="请输入活动名称"
                v-model="filterChannelSmsAccountName"
                @keyup.enter="filterPlanTableData()"
                @change="allowClearTableDataChange"
                allowClear
              />
            </div>
            <div class="searchConditionItem">
              发送状态：
              <a-input
                placeholder="请输入内容"
                v-model="filterSendStatus"
                @keyup.enter="filterPlanTableData()"
                @change="allowClearTableDataChange"
                allowClear
              />
            </div>
            <div class="searchButton">
              <a-button type="primary" @click="filterPlanTableData()"
                >查 询</a-button
              >
            </div>
          </div>
          <a-table
            v-if="activeKey == '2'"
            :scroll="{
              y: 400,
              x: '100%',
            }"
            :columns="planColumns"
            :dataSource="planTableData"
            :pagination="false"
            size="small"
            class="planTable"
          >
            <span
              slot="receiveRealStatusCh"
              slot-scope="text, record"
              style="width: 100%"
            >
              <template v-if="record.receiveRealStatusCh">
                {{ record.receiveRealStatusCh }} |
              </template>
              {{ record.sendStatus }}
            </span>
            <span slot="rate" slot-scope="text, record" style="width: 100%">
              {{ text }}%
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="3" tab="失败分析">
          <div style="height: 505px">
            <div id="errorContainer" />
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
    <!-- 混淆 -->
    <a-modal
      title="混淆"
      v-model="mixShow"
      :maskClosable="false"
      :afterClose="mixClose"
      width="700px"
    >
      <a-table :columns="mixColumns" :data-source="mixData" :pagination="false">
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button v-if="btnShow" type="primary" @click="rateClick"
            >修改比例</a-button
          >
          <a-popconfirm
            title="混淆操作不可逆， 是否继续?"
            ok-text="是"
            cancel-text="否"
            @confirm="mix_submit"
            v-if="btnShow"
          >
            <a-button v-if="btnShow" type="primary">提交</a-button>
          </a-popconfirm>

          <a-button v-if="!btnShow" type="primary" @click="mixShow = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 修改比例 -->
    <a-modal
      title="修改比例"
      v-model="rateModalShow"
      :maskClosable="false"
      :afterClose="rateModalClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="比例"
        >
          <a-input
            v-model="modalRate"
            placeholder="请输入比例%"
            allowClear
            style="width: 80%"
          ></a-input
          >%
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="rateModalShow = false">关闭</a-button>
          <a-button type="primary" @click="rate_submit">确定</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 批量混淆 -->
    <a-modal
      title="批量混淆比例"
      v-model="batchShow"
      :maskClosable="false"
      :afterClose="batchClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="比例"
        >
          <a-input
            v-model="batchRate"
            placeholder="请输入比例%"
            allowClear
            style="width: 80%"
          ></a-input
          >%
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="batchShow = false">关闭</a-button>
          <a-button type="primary" @click="batchRate_submit">确定</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 重新发送 -->
    <a-modal
      :title="reSendTitle"
      v-model="reSendShow"
      :maskClosable="false"
      :afterClose="reSendClose"
      width="680px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="真实状态" v-if="tabsKey == 'massTexting'">
          <a-select
            v-model="realReceiveStatus"
            placeholder="请选择真实状态"
            class="select-tag"
            style="width: 80%"
            mode="multiple"
          >
            <a-select-option
              v-for="(item, index) in reSendList"
              :key="index"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="发送状态" v-if="tabsKey == 'massTexting'">
          <a-select
            v-model="sendStatus"
            placeholder="请选择发送状态"
            class="select-tag"
            style="width: 80%"
            mode="multiple"
          >
            <a-select-option
              v-for="i in sendList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item v-if="tabsKey == 'interfaceSMS'">
          <span slot="label">
            真实状态
            <a-tooltip v-if="tabsKey == 'interfaceSMS'">
              <template slot="title"> 可以不选 </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-select
            v-model="receiveRealStatusModel"
            placeholder="请选择真实状态"
            class="select-tag"
            style="width: 80%"
            option-filter-prop="children"
            :filterOption="filterOption"
            showSearch
            allowClear
          >
            <a-select-option
              v-for="(item, index) in reSendList"
              :key="index"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="发送状态" v-if="tabsKey == 'interfaceSMS'">
          <a-select
            v-model="sendStatusModel"
            placeholder="请选择发送状态"
            class="select-tag"
            style="width: 80%"
          >
            <a-select-option
              v-for="i in sendList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item v-if="tabsKey == 'interfaceSMS'">
          <span slot="label">
            账号
            <a-tooltip v-if="tabsKey == 'interfaceSMS'">
              <template slot="title">
                发送管理-用户列表里面的accessKey
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-select
            v-model="addAccountAccessKey"
            placeholder="请选择账号"
            class="select-tag"
            style="width: 80%"
            showSearch
            :filterOption="false"
            @search="getReSendPlatformList"
          >
            <a-select-option
              v-for="item in reSendPlatformList"
              :key="item.id"
              :value="item.accessKey"
              >{{ item.accessKey }} | {{ item.alias }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="提交通道" v-if="tabsKey == 'massTexting'">
          <span slot="label">
            {{ tabsKey == "massTexting" ? "提交" : "" }}通道
            <a-tooltip v-if="tabsKey == 'interfaceSMS'">
              <template slot="title"> 平台管理-平台账号 </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-select
            v-model="channelSmsAccountIds"
            placeholder="请选择通道"
            class="select-tag"
            style="width: 80%; margin-right: 10px"
            allowClear
            :filterOption="false"
            showSearch
            mode="multiple"
          >
            <a-select-option
              v-for="item in queryChannelSmsAccountList"
              :key="item.channelSmsAccountId"
              :value="item.channelSmsAccountId"
              >{{ item.channelSmsAccountName }}</a-select-option
            >
          </a-select>
          <a-button type="link" @click="reloadQueryChannelSmsAccountList"
            >刷新</a-button
          >
        </a-form-item>
        <a-form-item v-if="tabsKey == 'interfaceSMS'">
          <span slot="label">
            通道
            <a-tooltip>
              <template slot="title"> 平台管理-平台账号 </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-select
            v-model="channelSmsAccountId"
            placeholder="请选择通道"
            class="select-tag"
            style="width: 80%"
            allowClear
            :filterOption="false"
            @search="searchSmsAccountList"
            showSearch
          >
            <a-select-option
              v-for="item in smsAccountList"
              :key="item.id"
              :value="item.id"
              >{{ item.accessKey }} | {{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="选择运营商" class="status">
          <a-switch
            checked-children="全部运营商"
            un-checked-children="特定运营商"
            v-model="phoneOperatorShow"
          />
          <i style="display: inline-block; margin-right: 10px"></i>
          <a-select
            v-model="phoneOperator"
            placeholder="请选择运营商"
            mode="multiple"
            class="select-tag"
            style="width: 40%"
            allowClear
            :maxTagCount="1"
            v-show="!phoneOperatorShow"
          >
            <a-select-option
              v-for="i in phoneOperatorList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="提交时间" v-if="tabsKey == 'massTexting'">
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            v-model="submitTimeStart"
            placeholder="开始时间"
          />
          —
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            placeholder="结束时间"
            v-model="submitTimeEnd"
          />
        </a-form-item>
        <a-form-item
          label="内容"
          v-if="tabsKey == 'massTexting'"
          class="contentStatus"
        >
          <a-switch
            checked-children="自定义"
            un-checked-children="使用原文案"
            v-model="modifyContent"
            @change="changeModifyContent"
          />
          <a-tabs
            v-model="contentActive"
            @change="contentCallback"
            v-if="modifyContent"
          >
            <a-tab-pane key="1" tab="短信内容">
              <a-textarea
                v-model="contentAdd"
                placeholder="请输入内容"
                :rows="4"
              />
            </a-tab-pane>
            <a-tab-pane key="2" tab="选择模板">
              <a-select
                v-model="strategyId"
                placeholder="请选择模板"
                class="select-tag"
                showSearch
                @search="getStrategyList"
                style="width: 300px"
              >
                <a-select-option
                  v-for="i in strategyList"
                  :key="i.id"
                  :value="i.id"
                  >{{ i.name }}</a-select-option
                >
              </a-select>
            </a-tab-pane>
          </a-tabs>
        </a-form-item>
        <a-form-item label="签名" v-if="tabsKey == 'interfaceSMS'">
          <a-select
            v-model="reSendSign"
            placeholder="请选择签名"
            class="select-tag"
            style="width: 80%"
            showSearch
            :filterOption="false"
            @search="getReSendSignList"
          >
            <a-select-option
              v-for="item in reSendSignList"
              :key="item.id"
              :value="item.sign"
              >{{ item.sign }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="起止时间" v-if="tabsKey == 'interfaceSMS'">
          <a-date-picker
            :disabledDate="disabledGmtCreatedStartDateReSend"
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            v-model="reSendStartTime"
            placeholder="开始日期"
          />
          —
          <a-date-picker
            :disabledDate="disabledGmtCreatedEndDateReSend"
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            placeholder="结束日期"
            v-model="reSendEndTime"
          />
        </a-form-item>
        <a-form-item label="发送时间">
          <a-checkbox v-model="reSendTimeShow" v-if="tabsKey == 'massTexting'"
            >定时发送</a-checkbox
          >
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            v-model="reSendTime"
            placeholder="发送时间"
            v-if="
              (reSendTimeShow && tabsKey == 'massTexting') ||
              tabsKey == 'interfaceSMS'
            "
          />
        </a-form-item>
        <span style="color: #ffa500">* 两种状态条件都满足才会被重新发送</span>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="reSendShow = false">关闭</a-button>
          <a-button type="primary" @click="reSend_submit">确定</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 通道分析 -->
    <a-modal
      title="通道分析"
      v-model="aisleShow"
      :footer="null"
      :maskClosable="false"
      :afterClose="aisleClose"
      width="900px"
    >
      <a-tabs v-model="aisleActiveKey" @change="aisleCallback">
        <a-tab-pane key="1" tab="账号通道">
          <a-table
            bordered
            :rowClassName="$common.rowClassColor"
            :components="$common.getTitle(tableColumns)"
            :columns="tableColumns"
            :dataSource="tableUserAccount"
            size="small"
            :pagination="false"
            :loading="loadingTable"
          >
          </a-table>
          <a-button type="primary" @click="updateNow" style="margin-top: 20px"
            >立即更新</a-button
          >
        </a-tab-pane>
        <a-tab-pane key="2" tab="服务商通道">
          <a-table
            bordered
            :rowClassName="$common.rowClassColor"
            :components="$common.getTitle(tableColumns)"
            :columns="tableColumns"
            :dataSource="tableOperator"
            size="small"
            :pagination="false"
            :loading="loadingTable"
          >
          </a-table>
          <a-button type="primary" @click="updateNow" style="margin-top: 20px"
            >立即更新</a-button
          >
        </a-tab-pane>
      </a-tabs>
    </a-modal>

    <a-modal
      title="异常信息"
      v-model="routeExceptionInfoShow"
      :footer="null"
      :maskClosable="false"
      width="900px"
    >
      <div v-if="routeExceptionInfoList.length > 0">
        <template v-for="item in routeExceptionInfoList">
          <p>{{ item }}</p>
        </template>
      </div>
      <div v-else>无</div>
    </a-modal>

    <a-modal
      title="速度控制"
      v-model="speedControlShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
        <a-form-item>
          <span slot="label">
            速度控制
            <a-tooltip placement="topLeft">
              <template slot="title">
                控制提交审核的速度,例如:5秒提交8条数据
              </template>
              <a-icon type="question-circle" style="color: #3d91f7" />
            </a-tooltip>
          </span>
          <a-switch v-model="speedEnabled" />
          <template v-if="speedEnabled">
            <i style="display: inline-block; margin-right: 20px"></i>
            <a-input v-model="speedSecond" style="width: 60px" />
            <span> 秒</span>
            <i style="display: inline-block; margin-right: 20px"></i>
            <a-input v-model="speedCount" style="width: 60px" />
            <span> 条</span>
          </template>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="speedControlShow = false">关闭</a-button>
          <a-button type="primary" @click="speedControlSubmit(false)"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <!-- 审核 -->
    <a-modal
      :title="smsTaskName"
      v-model="auditShow"
      :maskClosable="false"
      class="actionSub-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="发送时间:">
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            v-model="sendTime"
            placeholder="选择日期时间"
          />
        </a-form-item>
        <a-form-item label="发送数量:" class="status">
          <a-switch
            checked-children="全部"
            un-checked-children="限量"
            v-model="limitCountSwitch"
            @change="limitCountSwitchChange"
          />
          <i style="display: inline-block; margin-right: 10px"></i>
          <a-input
            v-if="!limitCountSwitch"
            placeholder="请输入发送数量"
            v-model="limitCount"
            allowClear
            style="width: 150px"
          />
        </a-form-item>
        <a-form-item>
          <span slot="label">
            发送检查
            <a-tooltip>
              <template slot="title">
                开启后，自动过滤当天已经提交过的号码，受签名配置影响，具体到发送限制
                -> 发送检查配置 页面查看
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-checkbox v-model="recentSubmitCheck" />
        </a-form-item>
        <a-form-item>
          <span slot="label">
            预提交
            <a-tooltip>
              <template slot="title"> 勾选后将预提交到预提交列表 </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-checkbox v-model="preSubmit" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="auditStatusConfirmCancel">驳回</a-button>
          <a-button type="primary" @click="auditStatusConfirm"
            >审核通过</a-button
          >
        </div>
      </template>
    </a-modal>

    <!-- 恢复 -->
    <a-modal
      :title="'恢复【' + smsTaskName + '】'"
      v-model="resumeTaskShow"
      :maskClosable="false"
      class="actionSub-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="发送时间:">
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            v-model="sendTime"
            placeholder="选择日期时间"
          />
        </a-form-item>
        <a-form-item label="发送数量:" class="status">
          <a-switch
            checked-children="全部"
            un-checked-children="限量"
            v-model="limitCountSwitch"
            @change="limitCountSwitchChange"
          />
          <i style="display: inline-block; margin-right: 10px"></i>
          <a-input
            v-if="!limitCountSwitch"
            placeholder="请输入发送数量"
            v-model="limitCount"
            allowClear
            style="width: 150px"
          />
        </a-form-item>
        <a-form-item>
          <span slot="label">
            发送检查
            <a-tooltip>
              <template slot="title">
                开启后，自动过滤当天已经提交过的号码，受签名配置影响，具体到发送限制
                -> 发送检查配置 页面查看
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-checkbox v-model="recentSubmitCheck" />
        </a-form-item>
        <a-form-item>
          <span slot="label">
            预提交
            <a-tooltip>
              <template slot="title"> 勾选后将预提交到预提交列表 </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-checkbox v-model="preSubmit" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="resumeTaskShow = false">取消</a-button>
          <a-button type="primary" @click="recoverySubmit">确认</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="批量修改账号"
      v-model="batchModifyAccountAccessKeyShow"
      :maskClosable="false"
      class="actionSub-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item>
          <span slot="label">
            账号
            <a-tooltip>
              <template slot="title">
                发送管理-用户列表里面的accessKey
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-select
            v-model="addAccountAccessKey"
            placeholder="请选择账号"
            class="select-tag"
            style="width: 80%"
            showSearch
            :filterOption="false"
            @search="getReSendPlatformList"
          >
            <a-select-option
              v-for="item in reSendPlatformList"
              :key="item.id"
              :value="item.accessKey"
              >{{ item.accessKey }} | {{ item.alias }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="batchModifyAccountAccessKeyShow = false"
            >取消</a-button
          >
          <a-button type="primary" @click="batchModifyAccountAccessKeySubmit"
            >确认</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="批量替换文本"
      v-model="batchTextReplaceShow"
      :maskClosable="false"
      class="actionSub-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="旧文本">
          <a-select
            mode="tags"
            placeholder="请输入旧文本"
            v-model="oldChar"
            style="width: 100%"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in oldCharSelectList"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="新文本">
          <a-input placeholder="请输入新文本" v-model="newChar" allowClear />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="batchTextReplaceShow = false">取消</a-button>
          <a-button type="primary" @click="batchTextReplaceSubmit"
            >确认</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="批量替换文本详情"
      v-model="batchTextReplaceDetailShow"
      :maskClosable="false"
      :footer="false"
      class="actionSub-class"
      width="500px"
    >
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(textReplaceColumns)"
        :columns="textReplaceColumns"
        :dataSource="textReplaceList"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="status" slot-scope="text, record" style="width: 100%">
          <a-tag :color="text == '已完成' ? 'green' : 'orange'">
            {{ text }}
          </a-tag>
        </span>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import G2 from "@antv/g2";
import smsInput from "../tool/smsInput.vue";
import moment from "moment";

export default {
  name: "smmSmarketingRecords",
  props: ["roleCode", "userLoginName", "confusion", "auditable"],
  components: {
    smsInput,
  },
  data() {
    return {
      massTextingCondition: {
        rangeTime: [
          moment().format("YYYY-MM-DD"),
          moment().add(2, "days").format("YYYY-MM-DD"),
        ],
        activityCode: "",
        status: "",
        userLoginName: undefined,
        content: "",
        accountAccessKey: undefined,
        channelSmsAccountId: undefined,
      },
      range: {
        后天: [moment(), moment().add(2, "days")],
        近7天: [moment().subtract(7, "days"), moment()],
        近30天: [moment().subtract(1, "months"), moment()],
      },
      statusShow: true,
      phoneOperatorShow: true,
      loadingTable: false,
      tableColumns: [
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 100,
        },
        {
          title: "accessKey",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 100,
        },
        {
          title: "待回执条数",
          ellipsis: true,
          dataIndex: "unknownCount",
          width: 80,
        },
        {
          title: "成功条数",
          ellipsis: true,
          dataIndex: "successCount",
          width: 80,
        },
        {
          title: "成功率",
          ellipsis: true,
          dataIndex: "successCountPer",
          width: 80,
        },
        {
          title: "失败条数",
          ellipsis: true,
          dataIndex: "failureCount",
          width: 80,
        },
        {
          title: "点击数",
          ellipsis: true,
          dataIndex: "clickCount",
          width: 80,
        },
        {
          title: "点击率",
          ellipsis: true,
          dataIndex: "clickCountPer",
          width: 80,
        },
        {
          title: "总条数",
          ellipsis: true,
          dataIndex: "count",
          width: 80,
        },
      ],
      checkId: "",
      receiveRealStatus: "",
      receiveRealStatusModel: undefined,
      sendStatusModel: undefined,
      contentSearch: "",
      batchShow: false,
      selectedRows: [],
      selectedRowKeys: [],
      batchPlanSendTimeClickList: [],
      batchRate: undefined,
      sendStatus: [],
      sendList: [
        {
          value: "SUBMITTING",
          label: "提交中",
        },
        {
          value: "WAIT_SUBMIT",
          label: "等待提交",
        },
        {
          value: "FAILURE",
          label: "发送失败",
        },
        {
          value: "SUBMIT_FAILURE",
          label: "提交失败",
        },
      ],
      realReceiveStatus: [],
      reSendList: [],
      reSendShow: false,
      accountAccessKey: undefined,
      platSearchList: [],
      btnShow: "",
      modalRate: "",
      rateModalShow: false,
      mixShow: false,
      editingKey: "",
      activeKey: "1",
      sendAnalysisTitle: "",
      sendAnalysisShow: false,
      planSendTimeShow: false,
      batchPlanSendTimeShow: false,
      filterExceptionNumber: true,
      smsTaskName: "",
      modifyActiveCodeShow: false,
      smsInputShow: false,
      msgSMSName: "",
      accessKey: undefined,
      sign: undefined,
      signId: undefined,
      platformRelationList: [],
      signList: [],
      textareaSMS: "",
      isDisabled: false,
      longUrlValue: undefined,
      longUrlGroupIdValue: undefined,
      longUrlGroupList: [],
      prefixValue: undefined,
      urlFormat: false,
      checkedTiming: false,
      isTime: false,
      msgPlanId: "",
      limitCount: "",
      auditShow: false,
      limitCountSwitch: false,
      recentSubmitCheck: false,
      sendTime: null,
      statusSend: [],
      statusSendList: [
        {
          value: "WAIT_SUBMIT",
          label: "等待提交",
        },
        {
          value: "SUBMIT_SUCCESS",
          label: "提交成功",
        },
        {
          value: "PRE_SUBMIT_SUCCESS",
          label: "预提成功",
        },
        {
          value: "SUBMIT_FAILURE",
          label: "提交失败",
        },
        {
          value: "SUCCESS",
          label: "发送成功",
        },
        {
          value: "FAILURE",
          label: "发送失败",
        },
      ],
      sendStartTime: null,
      sendEndTime: null,
      gmtEndOpenSend: false,
      sendMobile: "",
      sendTitle: "",
      sendSuccess: "",
      planId: "",
      modalPlanId: "",
      statusList: [
        {
          status: "",
          label: "全部",
        },
        {
          status: "CREATING",
          label: "创建中",
        },
        {
          status: "WAITING",
          label: "等待中",
        },
        {
          status: "SUBMIT_SUCCESS",
          label: "已提交",
        },
        {
          status: "SENDING",
          label: "发送中",
        },
        {
          status: "PAUSED",
          label: "已暂停",
        },
        {
          status: "PAUSING",
          label: "暂停中",
        },
        {
          status: "FINISHED",
          label: "已完成",
        },
        {
          status: "COMMIT_FAILURE",
          label: "提交失败",
        },
        {
          status: "SEND_FAILURE",
          label: "发送失败",
        },
        {
          status: "WAIT_AUDIT",
          label: "待审核",
        },
      ],
      gmtEndOpen: false,
      pagination: {},
      mixData: [
        {
          label: "成功",
          num: 0,
          rate: 0,
          rateShow: "",
          mixNum: 0,
          mixAfterNum: 0,
        },
        {
          label: "失败",
          num: 0,
          rate: 0,
          rateShow: "",
          mixNum: 0,
          mixAfterNum: 0,
        },
        {
          label: "等待回执",
          num: 0,
          rate: 0,
          rateShow: "",
          mixNum: 0,
          mixAfterNum: 0,
        },
        {
          label: "合计",
          num: 0,
          rate: 0,
          rateShow: "",
          mixNum: 0,
          mixAfterNum: 0,
        },
      ],
      mixColumns: [
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "label",
        },
        {
          title: "数量",
          ellipsis: true,
          dataIndex: "num",
          scopedSlots: { customRender: "num" },
        },
        {
          title: "比例",
          align: "center",
          ellipsis: true,
          dataIndex: "rateShow",
          scopedSlots: { customRender: "rateShow" },
          width: 150,
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            if (index === 0) {
              obj.attrs.rowSpan = 4;
            }
            if (index === 1) {
              obj.attrs.rowSpan = 0;
            }
            if (index === 2) {
              obj.attrs.rowSpan = 0;
            }
            if (index === 3) {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "混淆数",
          ellipsis: true,
          dataIndex: "mixNum",
        },
        {
          title: "混淆后",
          ellipsis: true,
          dataIndex: "mixAfterNum",
        },
      ],
      columns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "key",
          width: 100,
        },
        {
          title: "用户名称",
          ellipsis: true,
          dataIndex: "userLoginName",
          width: 100,
        },
        {
          title: "发送名称",
          ellipsis: true,
          dataIndex: "activityCodeLabel",
          scopedSlots: { customRender: "activityCodeLabel" },
          width: 300,
        },
        {
          title: "平台名称",
          ellipsis: true,
          dataIndex: "platformName",
          scopedSlots: { customRender: "platformName" },
          width: 200,
        },
        {
          title: "条数",
          align: "center",
          ellipsis: true,
          dataIndex: "planSendNums",
          scopedSlots: { customRender: "number" },
          width: 260,
        },
        {
          title: "内容",
          ellipsis: true,
          dataIndex: "content",
          scopedSlots: { customRender: "content" },
          customCell: this.mouseoverContent,
          width: 400,
        },
        {
          title: "发送状态",
          align: "center",
          ellipsis: true,
          dataIndex: "statusLabel",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
        {
          title: "发送时间",
          align: "center",
          ellipsis: true,
          dataIndex: "sendTime",
          scopedSlots: { customRender: "sendTime" },
          width: 170,
        },
        {
          title: "创建时间",
          align: "center",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "action" },
        },
      ],
      tableData: [],
      sendShow: false,
      paginationDetails: {},
      detailsTable: [],
      columnsDetailsTable: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "key",
          width: 80,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          width: 150,
        },
        {
          title: "内容",
          ellipsis: true,
          dataIndex: "content",
          scopedSlots: { customRender: "content" },
          customCell: this.mouseoverDetailsContent,
          width: 400,
        },
        {
          title: "发送状态",
          align: "center",
          ellipsis: true,
          dataIndex: "statusLabel",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
        {
          title: "账号通道",
          align: "center",
          ellipsis: true,
          dataIndex: "accountAccessKey",
          width: 100,
        },
        {
          title: "运营商通道",
          align: "center",
          ellipsis: true,
          dataIndex: "channelSmsAccountName",
          width: 150,
        },
        {
          title: "运营商",
          align: "center",
          ellipsis: true,
          dataIndex: "phoneOperator",
          width: 100,
        },
        {
          title: "真实状态",
          align: "center",
          ellipsis: true,
          dataIndex: "receiveRealStatus",
          scopedSlots: { customRender: "receiveRealStatus" },
          width: 100,
        },
        {
          title: "提交时间",
          align: "center",
          ellipsis: true,
          dataIndex: "submitTime",
          width: 170,
        },
        {
          title: "创建时间",
          align: "center",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "更新时间",
          align: "center",
          ellipsis: true,
          dataIndex: "gmtModified",
          width: 170,
        },
      ],
      loadingDetailsTable: false,
      currentPage: 1,
      pageSize: 10,
      asynTime: null,
      mobile: "",
      client: "",
      platform: "",
      activityCode: "",
      interfaceStartTime: null,
      interfaceEndTime: null,
      interfacePageNo: 1,
      interfacePageSize: 10,
      gmtEndOpenInterface: false,
      interfaceColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "key",
          width: 100,
        },
        {
          title: "用户名称",
          ellipsis: true,
          dataIndex: "userLoginName",
          width: 150,
        },
        {
          title: "批次编号",
          ellipsis: true,
          dataIndex: "activityCode",
          width: 150,
        },
        {
          align: "center",
          ellipsis: true,
          dataIndex: "sendCount",
          slots: { title: "sendCountTitle" },
          scopedSlots: { customRender: "sendCount" },
          width: 400,
        },
        {
          align: "center",
          ellipsis: true,
          dataIndex: "statisticsInTheLast5Minutes",
          slots: { title: "statisticsInTheLast5MinutesTitle" },
          scopedSlots: { customRender: "statisticsInTheLast5Minutes" },
          width: 400,
        },
        {
          title: "创建时间",
          align: "center",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 240,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      interfaceTableData: [],
      interfacePagination: {},
      selectDomainList: [],
      selectDomain: undefined,
      aisleId: "",
      aisleType: "userAccount",
      aisleActiveKey: "1",
      aisleShow: false,
      tableUserAccount: [],
      tableOperator: [],
      tabsKey: "massTexting",
      addAccountAccessKey: undefined,
      signId1: "",
      phoneOperator: [],
      phoneOperatorList: [
        {
          value: "中国移动",
          label: "中国移动",
        },
        {
          value: "中国联通",
          label: "中国联通",
        },
        {
          value: "中国电信",
          label: "中国电信",
        },
      ],
      submitTimeStart: null,
      submitTimeEnd: null,
      reSendTitle: "",
      chart: null,
      chart1: null,
      planColumns: [
        {
          title: "通道名称",
          align: "center",
          ellipsis: true,
          dataIndex: "channelSmsAccountName",
        },
        {
          title: "发送状态",
          align: "center",
          ellipsis: true,
          dataIndex: "receiveRealStatusCh",
          scopedSlots: { customRender: "receiveRealStatusCh" },
        },
        {
          title: "数量",
          align: "center",
          ellipsis: true,
          dataIndex: "count",
        },
        {
          title: "占比",
          align: "center",
          ellipsis: true,
          dataIndex: "rate",
          scopedSlots: { customRender: "rate" },
        },
      ],
      planTableData: [],
      filterChannelSmsAccountName: "",
      filterSendStatus: "",
      planData: [],
      reSendSign: undefined,
      reSendStartTime: null,
      reSendEndTime: null,
      reSendTime: null,
      reSendTimeShow: false,
      reSendSignList: [],
      reSendPlatformList: [],
      smsAccountList: [],
      searchSmsAccountTimer: null,
      queryChannelSmsAccountList: [],
      channelSmsAccountId: undefined,
      channelSmsAccountIds: [],
      userList: [],
      userName: undefined,
      routeExceptionInfoList: [],
      routeExceptionInfoShow: false,
      content: "",
      clickCallbackUrlIdList: [],
      modifyContent: false,
      contentAdd: "",
      contentActive: "1",
      strategyList: [],
      strategyId: undefined,
      clickCallbackUrlList: [],
      speedEnabled: false,
      speedSecond: "",
      speedCount: "",
      speedControlShow: false,
      resumeTaskShow: false,
      batchModifyAccountAccessKeyShow: false,
      preSubmit: false,
      oldCharSelectList: [],
      oldChar: [],
      newChar: "",
      batchTextReplaceShow: false,
      textReplaceList: [],
      textReplaceColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "状态",
          align: "center",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
        {
          title: "数量",
          align: "center",
          ellipsis: true,
          dataIndex: "count",
        },
      ],
      batchTextReplaceDetailShow: false,
      androidUvCount: "",
      androidUvRate: "",
    };
  },
  created() {
    this.getUserList();
    this.inquire(1);
    this.getPlatList();
    this.getReSendPlatformList();
    this.getReSendSignList();
    this.getSmsAccountList();
    this.getStrategyList();
    this.getLongUrlGroupList();
    this.getClickCallbackUrlList();
  },
  methods: {
    getNonIosClickUv(visible, record) {
      if (visible) {
        if (record.successSendCount) {
          let data = {
            planId: record.id,
          };
          this.$http.get("/msgPlan/getNonIosClickUv", data).then((res) => {
            if (res.result == 200) {
              this.androidUvCount = res.data;
              this.androidUvRate =
                ((this.androidUvCount / record.successSendCount) * 100).toFixed(
                  2
                ) + "%";
            }
          });
        } else {
          this.androidUvCount = 0;
          this.androidUvRate = "0.00%";
        }
      }
    },
    contentCallback() {
      this.strategyId = undefined;
    },
    getStrategyList(name) {
      this.$http.get("/contentStrategy/strategyList", { name }).then((res) => {
        if (res.result == 200) {
          this.strategyList = res.data;
        }
      });
    },
    changeModifyContent() {
      let sign = this.contentAdd.split("】")[0];
      let arr = this.selectedRows.filter((item) => {
        return sign !== item.content.split("】")[0];
      });
      if (arr.length > 0) {
        this.$message.warning("不同签名的短信重新发送不可以使用相同文案");
        this.modifyContent = !this.modifyContent;
        return;
      }
    },
    getUserList(userNameOrLoginName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        userNameOrLoginName,
      };
      this.$http.json_post("/user/list", data).then((res) => {
        if (res.result === 200) {
          this.userList = res.data.records;
        }
      });
    },
    getQueryChannelSmsAccountList() {
      let data = {
        planId: this.planId,
      };
      this.$http
        .get("/msgPlan/queryChannelSmsAccountList", data)
        .then((res) => {
          if (res.result === 200) {
            this.queryChannelSmsAccountList = res.data;
          }
        });
    },
    reloadQueryChannelSmsAccountList() {
      let data = {
        planId: this.planId,
      };
      this.$http
        .get("/msgPlan/reloadChannelSmsAccountList", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("刷新成功");
            this.getQueryChannelSmsAccountList();
          }
        });
    },
    searchSmsAccountList(accessKeyOrName) {
      if (this.searchSmsAccountTimer) {
        clearTimeout(this.searchSmsAccountTimer);
        this.searchSmsAccountTimer = null;
      }
      this.searchSmsAccountTimer = setTimeout(() => {
        this.getSmsAccountList(accessKeyOrName);
      }, 300);
    },
    getSmsAccountList(accessKeyOrName) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        accessKeyOrName,
      };
      this.$http.get("/sms/platform/list", data).then((res) => {
        if (res.result === 200) {
          this.smsAccountList = res.data.records;
        }
      });
    },
    getReSendPlatformList(accessKeyOrName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        accessKeyOrName,
      };
      this.$http.get("/userPlatformRelation/list", data).then((res) => {
        if (res.result === 200) {
          this.reSendPlatformList.splice(0);
          this.reSendPlatformList.push(...res.data.records);
        }
      });
    },
    getClickCallbackUrlList(name) {
      this.$http
        .get("/shortUrl/getClickCallbackUrlList", { name })
        .then((res) => {
          if (res.result === 200) {
            this.clickCallbackUrlList = res.data;
          }
        });
    },
    getReSendSignList(fuzzyName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        fuzzyName,
      };
      this.$http.get("/sms/signInfo/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.reSendSignList = list;
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 多选框数据
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRows = selectedRows;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 批量混淆
    batchMix() {
      this.batchShow = true;
    },
    // 批量混淆关闭
    batchClose() {
      this.batchRate = undefined;
    },
    // 批量混淆确定
    batchRate_submit() {
      let ids = [];
      this.selectedRows.forEach((item) => {
        ids.push(item.id);
      });
      let data = {
        ids,
        rate: this.batchRate,
      };
      this.$http.json_post("/sms/batchConfusionData", data).then((res) => {
        if (res.result === 200) {
          this.batchShow = false;
          this.$message.success("批量混淆成功");
          this.selectedRows.splice(0);
          this.selectedRowKeys.splice(0);
        }
      });
    },
    // 查询短链域名
    getDomainList() {
      this.selectDomainList = [];
      this.$http.get("/shortUrl/domainList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.selectDomainList.push({
              value: item,
              label: item,
            });
          });
        }
      });
    },
    // 点击发送分析
    sendAnalysisClick(val) {
      this.activeKey = "1";
      this.sendAnalysisTitle = "【" + val.activityCode + "】发送分析";
      this.planId = val.id;
      this.getPlanRealStatus();
    },
    // 发送分析tabx选择
    sendAnalysisCallback(val) {
      if (val === "1") {
        this.$nextTick(() => {
          this.chartStart1();
        });
      } else if (val === "3") {
        this.$nextTick(() => {
          this.chartStart();
        });
      }
    },
    // 查询发送分析
    getSmsPlanStatus() {
      let data = {
        planId: this.planId,
      };
      return new Promise((resolve, reject) => {
        this.$http.get("/smsStatus/planStatus", data).then((res) => {
          if (res.result === 200) {
            resolve(res.data);
          }
        });
      });
    },
    allowClearTableDataChange(e) {
      if (e.target.value) {
        return;
      }
      this.filterPlanTableData();
    },
    filterPlanTableData() {
      if (!this.filterChannelSmsAccountName && !this.filterSendStatus) {
        this.planTableData.splice(0);
        this.planTableData.push(...this.planData);
      } else {
        if (!this.filterChannelSmsAccountName) {
          this.planTableData = this.planData.filter((item) => {
            return item.sendStatus == this.filterSendStatus;
          });
        } else if (!this.filterSendStatus) {
          this.planTableData = this.planData.filter((item) => {
            return (
              item.channelSmsAccountName.indexOf(
                this.filterChannelSmsAccountName
              ) !== -1
            );
          });
        } else {
          this.planTableData = this.planData.filter((item) => {
            return (
              item.channelSmsAccountName.indexOf(
                this.filterChannelSmsAccountName
              ) !== -1 && item.sendStatus == this.filterSendStatus
            );
          });
        }
      }

      const sum = this.planData.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.count;
      }, 0);

      const filterSum = this.planTableData.reduce(
        (accumulator, currentObject) => {
          return accumulator + currentObject.count;
        },
        0
      );

      this.planTableData.sort((a, b) => {
        return b.count - a.count;
      });
      const rate = ((filterSum / sum) * 100).toFixed(2) * 1;
      this.planTableData.push({
        channelSmsAccountName: "合计",
        sendStatus: "",
        count: filterSum,
        rate,
      });
    },
    getPlanRealStatus() {
      let data = {
        planId: this.planId,
      };
      this.planData.splice(0);
      this.planTableData.splice(0);
      this.$http.get("/smsStatus/planRealStatus", data).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          const sum = list.reduce((accumulator, currentObject) => {
            return accumulator + currentObject.count;
          }, 0);
          list.forEach((item) => {
            item.rate = ((item.count / sum) * 100).toFixed(2) * 1;
          });
          this.planData.push(...list);
          this.planTableData.push(...list);
          this.planTableData.sort((a, b) => {
            return b.count - a.count;
          });
          this.planTableData.push({
            channelSmsAccountName: "合计",
            sendStatus: "",
            count: sum,
            rate: 100,
          });
          this.sendAnalysisShow = true;
          this.$nextTick(() => {
            this.chartStart1();
          });
        }
      });
    },
    // 绘制发送分析图
    async chartStart() {
      let data = await this.getSmsPlanStatus();
      if (this.chart) {
        this.chart.changeData(data);
      } else {
        this.chart = new G2.Chart({
          container: "errorContainer",
          forceFit: true,
          height: 500,
        });
        this.chart.source(data, {
          rate: {
            formatter: (val) => {
              val = val + "%";
              return val;
            },
          },
        });
        this.chart.coord("theta", {
          radius: 0.75,
        });
        this.chart.tooltip({
          showTitle: false,
          itemTpl:
            '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
        });
        this.chart
          .intervalStack()
          .position("rate")
          .color("sendStatus")
          .label("rate", {
            formatter: (val, item) => {
              return (
                item.point.sendStatus + ": " + item.point.count + " | " + val
              );
            },
          })
          .tooltip("sendStatus*count*rate", (sendStatus, count, rate) => {
            rate = rate + "%";
            return {
              name: sendStatus,
              value: count + " | " + rate,
            };
          })
          .style({
            lineWidth: 1,
            stroke: "#fff",
          });
        this.chart.legend({
          position: "left",
        });
      }
      this.chart.render();
    },
    // 绘制发送分析图
    async chartStart1() {
      let data = [...this.planData];

      let result = {};

      const sum = data.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.count;
      }, 0);
      data.forEach((item) => {
        if (!result[item.sendStatus]) {
          result[item.sendStatus] = {
            sendStatus: item.sendStatus,
            count: 0,
          };
        }
        result[item.sendStatus].count += item.count;
      });
      let mergedData = Object.values(result);
      mergedData.forEach((item) => {
        item.rate = ((item.count / sum) * 100).toFixed(2) * 1;
      });
      if (this.chart1) {
        this.chart1.changeData(mergedData);
      } else {
        this.chart1 = new G2.Chart({
          container: "planRealStatus",
          forceFit: true,
          height: 500,
        });
        this.chart1.source(mergedData, {
          rate: {
            formatter: (val) => {
              val = val + "%";
              return val;
            },
          },
        });
        this.chart1.coord("theta", {
          radius: 0.75,
        });
        this.chart1.tooltip({
          showTitle: false,
          itemTpl:
            '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
        });
        this.chart1
          .intervalStack()
          .position("rate")
          .color("sendStatus")
          .label("rate", {
            formatter: (val, item) => {
              return (
                item.point.sendStatus + ": " + item.point.count + " | " + val
              );
            },
          })
          .tooltip("sendStatus*count*rate", (sendStatus, count, rate) => {
            rate = rate + "%";
            return {
              name: sendStatus,
              value: count + " | " + rate,
            };
          })
          .style({
            lineWidth: 1,
            stroke: "#fff",
          });
        this.chart1.legend(false);
      }

      this.chart1.render();
    },
    // 点击修改发送时间
    planSendTimeClick(val) {
      this.msgPlanId = val.id;
      this.smsTaskName = val.activityCode;
      this.sendTime = val.sendTime;
      this.planSendTimeShow = true;
    },
    batchPlanSendTimeClick() {
      this.batchPlanSendTimeClickList.splice(0);
      this.selectedRows.forEach((item) => {
        if (item.auditStatus == "WAIT_AUDIT" || item.sendStatus == "WAITING") {
          this.batchPlanSendTimeClickList.push({
            id: item.id,
            name: item.activityCode,
            status: "waiting",
            sendTime: item.sendTime,
          });
        }
      });
      if (this.batchPlanSendTimeClickList.length > 0) {
        this.smsTaskName = "批量";
        this.sendTime = this.batchPlanSendTimeClickList[0].sendTime;
        this.planSendTimeShow = true;
      } else {
        this.$message.warning(
          "只有待审核状态和等待状态才可以修改发送时间,请重新选择"
        );
        this.selectedRows.splice(0);
        this.selectedRowKeys.splice(0);
        return;
      }
    },
    async planSendTime_submit() {
      if (!this.sendTime) {
        this.$message.warning("发送时间不能为空！");
        return;
      }

      if (
        this.smsTaskName == "批量" &&
        this.batchPlanSendTimeClickList.length > 0
      ) {
        this.planSendTimeShow = false;
        this.batchPlanSendTimeShow = true;
        for (let i = 0; i < this.batchPlanSendTimeClickList.length; i++) {
          const item = this.batchPlanSendTimeClickList[i];
          this.$set(item, "status", "running");
          let data = {
            id: item.id,
            sendTime: this.sendTime,
          };
          let status = await this.batchPlanSendTimeAwait(data);
          this.$set(item, "status", status);
          if (i == this.batchPlanSendTimeClickList.length - 1) {
            setTimeout(() => {
              this.$message.success("修改成功");
              this.batchPlanSendTimeShow = false;
              this.selectedRows.splice(0);
              this.selectedRowKeys.splice(0);
              this.inquire();
            }, 2000);
          }
        }
      } else {
        let data = {
          id: this.msgPlanId,
        };
        data.sendTime = this.sendTime;
        this.$http.json_post("/sms/editMsgPlanSendTime", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.planSendTimeShow = false;
            this.inquire();
          }
        });
      }
    },
    async batchPlanSendTimeAwait(data) {
      let status = "";
      await this.$http
        .json_post("/sms/editMsgPlanSendTime", data)
        .then((res) => {
          if (res.result === 200) {
            status = "success";
          }
        });
      return status;
    },
    // 关闭修改发送时间
    planSendTimeClose() {
      this.sendTime = null;
    },
    batchModifyAccountAccessKey() {
      this.addAccountAccessKey = undefined;
      this.batchModifyAccountAccessKeyShow = true;
    },
    batchModifyAccountAccessKeySubmit() {
      this.$axios
        .all(
          this.selectedRows.map((item) => {
            let data = {
              planId: item.id,
              accountAccessKey: this.addAccountAccessKey,
            };
            return this.$http.json_post("/sms/modifyAccountAccessKey", data);
          })
        )
        .then((resArr) => {
          this.$message.success("修改成功");
          this.selectedRows.splice(0);
          this.selectedRowKeys.splice(0);
          this.batchModifyAccountAccessKeyShow = false;
          this.inquire();
        });
    },
    batchTextReplace() {
      let arr = [];
      this.selectedRows.forEach((item) => {
        if (item.sendStatus !== "WAITING" && item.sendStatus !== "PAUSED") {
          arr.push(item.id);
        }
      });
      if (arr.length > 0) {
        this.$message.warning("只有等待中或已暂停才可以替换文本");
        this.selectedRows.splice(0);
        this.selectedRowKeys.splice(0);
        return;
      }
      this.queryTextReplaceDate();
    },
    queryTextReplaceDate() {
      let data = {
        idsStr: this.selectedRowKeys.join(","),
      };
      this.$http.get("/sms/queryTextReplaceDate", data).then((res) => {
        if (res.result === 200) {
          this.oldChar.splice(0);
          this.oldCharSelectList.splice(0);
          this.oldChar.push(...res.data);
          this.oldCharSelectList.push(...res.data);
          this.newChar = this.getTomorrowCurrentDate();
          this.batchTextReplaceShow = true;
        }
      });
    },
    getTomorrowCurrentDate() {
      const now = new Date();
      const month = now.getMonth() + 1; // 月份是从0开始的，所以需要+1
      let date = now.getDate() + 1; // 获取明天
      return `${month}月${date}日`;
    },
    batchTextReplaceSubmit() {
      let data = {
        ids: this.selectedRowKeys.join(","),
        oldChar: this.oldChar,
        newChar: this.newChar,
      };
      this.$http.json_post("/sms/textReplace", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("提交成功");
          this.batchTextReplaceShow = false;
          this.textReplaceList = this.selectedRowKeys.map((id) => {
            return { id, count: -1, status: "未完成" };
          });
          this.batchTextReplaceDetailShow = true;
          this.queryTextReplace();
        }
      });
    },
    queryTextReplace() {
      let data = {
        idsStr: this.selectedRowKeys.join(","),
      };
      this.$http.get("/sms/queryTextReplace", data).then((res) => {
        if (res.result === 200) {
          if (res.data.length > 0) {
            res.data.forEach((item) => {
              this.textReplaceList.forEach((oldItem) => {
                if (item.id == oldItem.id) {
                  this.$set(oldItem, "count", item.count);
                  if (item.count !== -1) {
                    this.$set(oldItem, "status", "已完成");
                  }
                }
              });
            });
            setTimeout(() => {
              this.queryTextReplace();
            }, 1000);
          } else {
            setTimeout(() => {
              this.selectedRowKeys.splice(0);
              this.selectedRows.splice(0);
            }, 200);
          }
        }
      });
    },
    // 查询账号
    getUserSmsAccount(accessKeyOrName) {
      this.platformRelationList = [];
      let data = {
        pageNo: 1,
        pageSize: 100,
        querySingleUser: true,
        accessKeyOrName,
      };
      this.$http.get("/userPlatformRelation/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.platformRelationList = list;
        }
      });
    },
    modifyActiveCode(val) {
      this.msgPlanId = val.id;
      this.smsTaskName = val.activityCode;
      this.msgSMSName = val.activityCode;
      this.modifyActiveCodeShow = true;
    },
    modifyActiveCodeClose() {
      this.msgPlanId = undefined;
      this.smsTaskName = "";
      this.msgSMSName = "";
    },
    modifyActiveCode_submit() {
      let data = {
        id: this.msgPlanId,
        activityCode: this.msgSMSName,
      };
      this.$http.json_post("/sms/modifyActiveCode", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.modifyActiveCodeShow = false;
          this.inquire();
        }
      });
    },
    // 点击重新创建
    recreateClick(val) {
      this.prefixValue = undefined;
      this.getUserSmsAccount();
      this.getReSend(val.id);
      this.planId = val.id;
      this.msgPlanId = val.id;
      this.smsTaskName = val.activityCode;
      this.clickCallbackUrlIdList.splice(0);
      this.$http
        .get(`/sms/getActivityCode?activityCode=${val.activityCode}`)
        .then((res) => {
          this.msgSMSName = res.data;
        });
      this.smsInputShow = true;
      this.$nextTick(() => {
        let signFirstIndex = val.content.indexOf("【");
        let signLastIndex = val.content.indexOf("】");
        if (signFirstIndex !== -1 && signLastIndex !== -1) {
          let startText = val.content.substring(0, signFirstIndex);
          let endText = val.content.substring(signLastIndex + 1);
          this.textareaSMS = startText + endText;
        }
        this.getLongUrlGroupIdForShortUrlGroup(val.shortUrlGroupId);
        this.longUrlValue = val.longUrl;
        this.selectDomain = val.domain;
        this.prefixValue = "none";
        this.getDomainList();
      });
    },
    getLongUrlGroupIdForShortUrlGroup(shortUrlGroupId) {
      this.$http
        .get("/sms/getLongUrlGroupIdForShortUrlGroup", { shortUrlGroupId })
        .then((res) => {
          if (res.result == 200) {
            this.longUrlGroupIdValue = res.data;
          }
        });
    },
    // 确定重新创建
    smsInput_submit() {
      let clickCallbackUrl = [];
      this.clickCallbackUrlIdList.forEach((item) => {
        this.clickCallbackUrlList.forEach((urlItem) => {
          if (urlItem.id == item) {
            clickCallbackUrl.push({ id: urlItem.id, url: urlItem.url });
          }
        });
      });
      let data = {
        userSmsAccountId: this.accessKey,
        content: this.sign + this.textareaSMS,
        msgPlanId: this.msgPlanId,
        signId: this.signId,
        activityCode: this.msgSMSName,
        platform: this.platform,
        filterExceptionNumber: this.filterExceptionNumber,
        sendStatusList: this.sendStatus,
        receiveRealStatusList: this.realReceiveStatus,
        clickCallbackUrl,
      };
      if (this.contentActive == "2") {
        data.strategyId = this.strategyId;
      }
      if (this.phoneOperatorShow) {
        data.operatorList = [];
      } else {
        data.operatorList = this.phoneOperator;
      }
      let containShortUrl = data.content.indexOf("#短链#") !== -1;
      if (containShortUrl) {
        data.prefix = this.prefixValue;
        if (this.longUrlValue) {
          data.longUrl = this.longUrlValue;
        }
        if (this.longUrlGroupIdValue) {
          data.longUrlGroupId = this.longUrlGroupIdValue;
        }
        if (this.urlFormat) {
          return;
        }
      }
      if (this.checkedTiming) {
        // 选择定时发送
        if (!this.sendTime) {
          this.$message.warning("发送时间不能为空！");
          return;
        }
        data.sendTime = this.sendTime;
      }
      this.$http.json_post("/sms/addMsgPlanFromMsgPlan", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("创建成功");
          this.smsInputShow = false;
          this.inquire();
        }
      });
    },
    // 关闭重新创建
    smsInputClose() {
      this.isTime = false;
      this.checkedTiming = false;
      this.smsTaskName = "";
      this.smsInputShow = false;
      this.msgSMSName = "";
      this.accessKey = undefined;
      this.sign = undefined;
      this.signId = undefined;
      this.platformRelationList = [];
      this.signList = [];
      this.textareaSMS = "";
      this.strategyId = undefined;
      this.contentActive = "1";
      this.isDisabled = false;
      this.longUrlValue = undefined;
      this.longUrlGroupIdValue = undefined;
      this.prefixValue = undefined;
      this.urlFormat = false;
      this.msgPlanId = "";
      this.filterExceptionNumber = true;
      this.selectDomain = undefined;
      this.phoneOperator = undefined;
      this.channelSmsAccountId = undefined;
      this.submitTimeStart = null;
      this.submitTimeEnd = null;
      this.phoneOperatorShow = true;
    },
    // 查询平台列表
    getPlatList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      this.platSearchList = [];
      this.$http.get("/plat/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.platSearchList = list;
        }
      });
    },
    // 选择平台
    platformChange(val) {
      this.getSignList(val);
    },
    // 选择签名
    sighChange(signId) {
      this.signId = signId;
      this.signList.forEach((item) => {
        if (item.signId === signId) {
          this.sign = item.sign;
          this.signId1 = item.id;
        }
      });
      let signFirstIndex = this.textareaSMS.indexOf("【");
      let signLastIndex = this.textareaSMS.indexOf("】");
      if (signFirstIndex !== -1 && signLastIndex !== -1) {
        let startText = this.textareaSMS.substring(0, signFirstIndex);
        let endText = this.textareaSMS.substring(signLastIndex + 1);
        this.textareaSMS = startText + endText;
      }
    },
    //获取签名列表
    getSignList(fuzzyName, val) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        userSmsAccountId: val,
        fuzzyName,
      };
      this.$http.get("/sms/userSignInfo/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          let signList = [];
          list.forEach((item) => {
            signList.push({
              sign: "【" + item.sign + "】",
              label: item.sign,
              signId: item.id,
            });
          });
          this.signList = signList;
          this.sighChange(this.signId);
        }
      });
    },
    // 获取短信内容
    textarea_sms(val) {
      this.textareaSMS = val;
    },
    // 获取长链内容
    longUrlChange(val) {
      this.longUrlValue = val;
    },
    longUrlGroupIdChange(val) {
      this.longUrlGroupIdValue = val;
    },
    // 获取长链内容
    selectDomainChange(val) {
      this.selectDomain = val;
    },
    // 获取前缀
    prefixChange(val) {
      this.prefixValue = val;
    },
    // 判断长链格式
    urlFormatChange(val) {
      this.urlFormat = val;
    },
    getLongUrlGroupList(name) {
      this.$http.get("/longUrlGroup/longUrlGroupList", { name }).then((res) => {
        if (res.result === 200) {
          this.longUrlGroupList = res.data;
        }
      });
    },
    compareToLongUrl(value) {
      if (value) {
        let httpIndex = value.indexOf("http://");
        let httpsIndex = value.indexOf("https://");
        if (httpIndex === 0 || httpsIndex === 0) {
          this.urlFormatChange(false);
          return;
        }
        this.urlFormatChange(true);
      } else {
        this.urlFormatChange(true);
      }
    },
    // 点击暂停--群发短信
    suspendClick(val) {
      this.$confirm({
        title: "确认暂停",
        content: (h) => (
          <div style="color:red;">确定要暂停 【{val.activityCodeLabel}】?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.$http
            .json_post("/sms/pauseTask", { msgPlanId: val.id })
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("暂停成功");
                this.inquire();
              }
            });
        },
        onCancel: () => {
          // console.log('Cancel');
        },
        class: "test",
      });
    },
    batchRecovery() {
      let arr = [];
      this.selectedRows.forEach((item) => {
        if (item.closed || item.sendStatus !== "PAUSED") {
          arr.push(item);
        }
      });
      if (arr.length > 0) {
        this.$message.warning("不可选择非已暂停状态进行恢复,请重新选择");
        this.selectedRows.splice(0);
        this.selectedRowKeys.splice(0);
        return;
      }
      this.planId = null;
      this.limitCount = 0;
      this.limitCountSwitch = true;
      if (this.selectedRows[0].sendTime) {
        this.sendTime = this.selectedRows[0].sendTime;
      } else {
        this.sendTime = moment().format("YYYY-MM-DD HH:mm:ss");
      }
      this.preSubmit = false;
      this.recentSubmitCheck = true;
      this.smsTaskName = "批量恢复";
      this.resumeTaskShow = true;
    },
    // 点击恢复--群发短信
    recoveryClick(record) {
      this.limitCount = 0;
      this.limitCountSwitch = true;
      this.sendTime = record.sendTime;
      this.recentSubmitCheck = true;
      this.planId = record.id;
      this.smsTaskName = record.activityCode;
      this.preSubmit = false;
      this.resumeTaskShow = true;
    },
    recoverySubmit() {
      let data = {
        limitCount: this.limitCount,
        sendTime: this.sendTime,
        recentSubmitCheck: this.recentSubmitCheck,
        preSubmit: this.preSubmit,
      };
      if (this.selectedRowKeys.length > 0) {
        this.$axios
          .all(
            this.selectedRows.map((item) => {
              let params = { ...data, msgPlanId: item.id };
              return this.$http.json_post("/sms/resumeTask", params);
            })
          )
          .then((resArr) => {
            this.$message.success("恢复成功");
            this.selectedRows.splice(0);
            this.selectedRowKeys.splice(0);
            this.inquire();
            this.resumeTaskShow = false;
          });
      } else if (this.planId) {
        data.msgPlanId = this.planId;
        this.$http.json_post("/sms/resumeTask", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("恢复成功");
            this.inquire();
            this.resumeTaskShow = false;
          }
        });
      }
    },
    // 点击刷新--接口短信
    interfaceConnectedRefresh(val, index) {
      this.$http
        .get("/hangupMsg/statisticsBatchNumber", {
          activityCode: val.activityCode,
        })
        .then((res) => {
          if (res.result === 200) {
            let obj = res.data;
            obj.key = index + 1;
            obj.gmtCreated = this.$common.transformTime(obj.gmtCreated);
            obj.lastUpdateTime = this.$common.transformTime(obj.lastUpdateTime);
            obj.statisticsInTheLast5Minutes = obj.recentSendCount;
            obj.successSendRate = "0%";
            obj.waitCallbackRate = "0%";
            obj.failureSendRate = "0%";
            obj.sendsRate = "0%";
            obj.clickRate = "0%";
            obj.clickUvRate = "0%";
            item.sendCounts =
              item.successSendCount +
              item.waitCallbackCount +
              item.failureSendCount;
            if (obj.planSendNums !== 0) {
              obj.successSendRate =
                ((obj.successSendCount / obj.planSendNums) * 100).toFixed(2) +
                "%";
              obj.waitCallbackRate =
                ((obj.waitCallbackCount / obj.planSendNums) * 100).toFixed(2) +
                "%";
              obj.failureSendRate =
                ((obj.failureSendCount / obj.planSendNums) * 100).toFixed(2) +
                "%";
              obj.sendsRate =
                ((obj.sendCounts / obj.planSendNums) * 100).toFixed(2) + "%";
              if (obj.successSendCount > 0) {
                obj.clickRate =
                  ((obj.clickCount / obj.successSendCount) * 100).toFixed(2) +
                  "%";
                obj.clickUvRate =
                  ((obj.clickUvCount / obj.successSendCount) * 100).toFixed(2) +
                  "%";
              }
            }
            obj.recentSuccessSendRate = "0%";
            obj.recentWaitCallbackRate = "0%";
            obj.recentFailureSendRate = "0%";
            if (obj.statisticsInTheLast5Minutes !== 0) {
              obj.recentSuccessSendRate =
                (
                  (obj.recentSuccessCount / obj.statisticsInTheLast5Minutes) *
                  100
                ).toFixed(2) + "%";
              obj.recentWaitCallbackRate =
                (
                  (obj.recentWaitCallbackCount /
                    obj.statisticsInTheLast5Minutes) *
                  100
                ).toFixed(2) + "%";
              obj.recentFailureSendRate =
                (
                  (obj.recentFailureCount / obj.statisticsInTheLast5Minutes) *
                  100
                ).toFixed(2) + "%";
            }
            this.$set(this.interfaceTableData, index, obj);
            this.$message.success("刷新成功");
          }
        });
    },
    // 点击tab切换
    callback(key) {
      if (this.asynTime) {
        clearInterval(this.asynTime);
        this.asynTime = null;
      }
      if (key === "massTexting") {
        this.pageSize = 10;
        this.inquire(1);
      }
      if (key === "interfaceSMS") {
        this.interfacePageSize = 10;
        this.inquireInterface(1);
      }
    },
    // 点击查询接口短信记录
    inquireInterface(index) {
      if (index) {
        this.interfacePageNo = index;
      }
      let data = {
        pageNo: this.interfacePageNo,
        pageSize: this.interfacePageSize,
        mobile: this.mobile,
        client: this.client,
        platform: this.platform,
        activityCode: this.activityCode,
        startTime: this.interfaceStartTime
          ? this.interfaceStartTime + " 00:00:00"
          : "",
        endTime: this.interfaceEndTime
          ? this.interfaceEndTime + " 23:59:59"
          : "",
        smsPlanType: "API_MESSAGE",
        userLoginName: this.userName,
      };
      let obj = JSON.parse(JSON.stringify(data));
      delete obj.pageNo;
      delete obj.pageSize;
      let interfaceSummaryData = JSON.stringify(obj);
      if (sessionStorage.getItem("interfaceSummaryData")) {
        if (
          interfaceSummaryData !==
          sessionStorage.getItem("interfaceSummaryData")
        ) {
          this.interfacePageNo = 1;
          data.pageNo = 1;
          sessionStorage.setItem("interfaceSummaryData", interfaceSummaryData);
        }
      } else {
        sessionStorage.setItem("interfaceSummaryData", interfaceSummaryData);
      }
      this.interfaceSummary(data);
    },
    // 查询接口短信记录
    interfaceSummary(data) {
      this.$http.get("/sms/msgPlanList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.key = item.id;
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            item.statisticsInTheLast5Minutes = item.recentSendCount;
            item.successSendRate = "0%";
            item.waitCallbackRate = "0%";
            item.failureSendRate = "0%";
            item.sendsRate = "0%";
            item.clickRate = "0%";
            item.clickUvRate = "0%";
            item.sendCounts =
              item.successSendCount +
              item.waitCallbackCount +
              item.failureSendCount;
            if (item.planSendNums !== 0) {
              item.successSendRate =
                ((item.successSendCount / item.planSendNums) * 100).toFixed(2) +
                "%";
              item.waitCallbackRate =
                ((item.waitCallbackCount / item.planSendNums) * 100).toFixed(
                  2
                ) + "%";
              item.failureSendRate =
                ((item.failureSendCount / item.planSendNums) * 100).toFixed(2) +
                "%";
              item.sendsRate =
                ((item.sendCounts / item.planSendNums) * 100).toFixed(2) + "%";
              if (item.successSendCount > 0) {
                item.clickRate =
                  ((item.clickCount / item.successSendCount) * 100).toFixed(2) +
                  "%";
                item.clickUvRate =
                  ((item.clickUvCount / item.successSendCount) * 100).toFixed(
                    2
                  ) + "%";
              }
            }
            item.recentSuccessSendRate = "0%";
            item.recentWaitCallbackRate = "0%";
            item.recentFailureSendRate = "0%";
            if (item.statisticsInTheLast5Minutes !== 0) {
              item.recentSuccessSendRate =
                (
                  (item.recentSuccessCount / item.statisticsInTheLast5Minutes) *
                  100
                ).toFixed(2) + "%";
              item.recentWaitCallbackRate =
                (
                  (item.recentWaitCallbackCount /
                    item.statisticsInTheLast5Minutes) *
                  100
                ).toFixed(2) + "%";
              item.recentFailureSendRate =
                (
                  (item.recentFailureCount / item.statisticsInTheLast5Minutes) *
                  100
                ).toFixed(2) + "%";
            }
            item.activityCodeLabel = item.activityCode;
          });
          this.interfaceTableData = list;
          this.interfacePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            pageSizeOptions: ["10", "20", "50", "100"],
            showSizeChanger: true,
            onShowSizeChange: (current, pageSize) => {
              this.interfacePageSize = pageSize;
              if (this.asynTime) {
                clearInterval(this.asynTime);
                this.asynTime = null;
              }
              this.inquireInterface(1);
            },
            onChange: (current) => this.currentPage_interface_data(current),
          };
          if (!this.asynTime) {
            this.AsyncIntelligence(); // 刷新短信记录列表
          }
        }
      });
    },
    // 接口短信页面分页
    currentPage_interface_data(index) {
      this.interfacePageNo = index;
      this.inquireInterface(index);
    },
    // 短信记录表-鼠标移入长链列
    mouseoverContent(record, rowIndex) {
      return {
        on: {
          // 事件
          click: (event) => {}, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {
            // 鼠标移入行
            this.tableData.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.tableData[rowIndex], "isCopyShow", true);
              }
            });
          },
          mouseleave: (event) => {
            // 鼠标移出行
            this.tableData.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.tableData[rowIndex], "isCopyShow", false);
              }
            });
          },
        },
      };
    },
    // 详情表-鼠标移入长链列
    mouseoverDetailsContent(record, rowIndex) {
      return {
        on: {
          // 事件
          click: (event) => {}, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {
            // 鼠标移入行
            this.detailsTable.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.detailsTable[rowIndex], "isCopyShow", true);
              }
            });
          },
          mouseleave: (event) => {
            // 鼠标移出行
            this.detailsTable.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.detailsTable[rowIndex], "isCopyShow", false);
              }
            });
          },
        },
      };
    },
    batchAudit() {
      let arr = [];
      this.selectedRows.forEach((item) => {
        if (item.closed || item.auditStatus !== "WAIT_AUDIT") {
          arr.push(item);
        }
      });
      if (arr.length > 0) {
        this.$message.warning("不可选择非待审核状态进行审核,请重新选择");
        this.selectedRows.splice(0);
        this.selectedRowKeys.splice(0);
        return;
      }
      this.planId = null;
      this.limitCount = 0;
      this.recentSubmitCheck = true;
      this.preSubmit = false;
      this.limitCountSwitch = true;
      if (this.selectedRows[0].sendTime) {
        this.sendTime = this.selectedRows[0].sendTime;
      } else {
        this.sendTime = moment().format("YYYY-MM-DD HH:mm:ss");
      }
      this.smsTaskName = "批量审核";
      this.auditShow = true;
    },
    audit(record) {
      this.limitCount = 0;
      this.recentSubmitCheck = true;
      this.preSubmit = false;
      this.limitCountSwitch = true;
      this.sendTime = record.sendTime;
      this.planId = record.id;
      this.smsTaskName = "审核【" + record.activityCode + "】";
      if (record.msgNums / record.planSendNums >= 1.5) {
        this.$confirm({
          title: "短信消耗数大幅超过发送数，确定要审核吗？",
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            this.auditShow = true;
          },
          onCancel() {},
        });
      } else {
        this.auditShow = true;
      }
    },
    limitCountSwitchChange() {
      if (this.limitCountSwitch) {
        this.limitCount = 0;
      } else {
        this.limitCount = 1000;
      }
    },
    // 通过审核
    auditStatusConfirm() {
      let data = {
        success: true,
        limitCount: this.limitCount,
        sendTime: this.sendTime,
        recentSubmitCheck: this.recentSubmitCheck,
        preSubmit: this.preSubmit,
      };
      if (this.selectedRowKeys.length > 0) {
        this.$axios
          .all(
            this.selectedRows.map((item) => {
              let params = { ...data, planId: item.id };
              return this.auditStatus_submit(params);
            })
          )
          .then((resArr) => {
            this.$message.success("审核成功");
            this.inquire();
            this.selectedRows.splice(0);
            this.selectedRowKeys.splice(0);
            this.auditShow = false;
          });
      } else if (this.planId) {
        data.planId = this.planId;
        this.auditStatus_submit(data).then((res) => {
          if (res.result === 200) {
            console.log(res);
            this.$message.success("审核成功");
            this.inquire();
            this.auditShow = false;
          }
        });
      }
    },
    // 驳回审核
    auditStatusConfirmCancel() {
      let data = {
        success: false,
      };
      if (this.selectedRowKeys.length > 0) {
        this.$axios
          .all(
            this.selectedRows.map((item) => {
              let params = { ...data, planId: item.id };
              return this.auditStatus_submit(params);
            })
          )
          .then((resArr) => {
            this.$message.success("驳回成功");
            this.inquire();
            this.selectedRows.splice(0);
            this.selectedRowKeys.splice(0);
            this.auditShow = false;
          });
      } else if (this.planId) {
        data.planId = this.planId;
        this.auditStatus_submit(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("驳回成功");
            this.inquire();
            this.auditShow = false;
          }
        });
      }
    },
    // 确认审核状态
    auditStatus_submit(data) {
      return this.$http.json_post("/sms/audit", data);
    },
    // 点击删除群发短信记录
    smsDeleteClick(val) {
      this.$confirm({
        title: "确认删除【" + val.activityCodeLabel + "】记录",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            planId: val.id,
          };
          this.$http.json_post("/sms/deleteMsgPlan", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire();
            }
          });
        },
        onCancel() {},
      });
    },
    // 查询真实发送分析
    getReSend(planIds) {
      this.$http.get("/smsStatus/plansRealStatus", { planIds }).then((res) => {
        if (res.result === 200) {
          this.reSendList = res.data;
        }
      });
    },
    // 重新发送关闭
    reSendClose() {
      this.phoneOperatorShow = true;
      this.phoneOperator.splice(0);
      this.addAccountAccessKey = undefined;
      this.reSendSign = undefined;
      this.modifyContent = false;
      this.contentActive = "1";
      this.strategyId = undefined;
      this.contentAdd = "";
      this.reSendStartTime = "";
      this.reSendEndTime = "";
      this.reSendTime = "";
      this.reSendTimeShow = false;
    },
    batchReSendMsgPlan() {
      this.reSendMsgPlan();
    },
    // 点击重新发送
    reSendMsgPlan(val) {
      this.channelSmsAccountId = undefined;
      this.channelSmsAccountIds.splice(0);
      this.submitTimeStart = null;
      this.submitTimeEnd = null;
      this.sendStatusModel = undefined;
      this.sendStatus = undefined;
      this.realReceiveStatus = undefined;
      this.receiveRealStatusModel = undefined;
      if (val) {
        this.reSendTitle = "重新发送" + " - " + val.id;
        this.getReSend(val.id);
        this.planId = val.id;
        this.contentAdd = val.content;
        this.strategyId = val.strategyId;
        this.selectedRowKeys.splice(0);
        this.selectedRows.splice(0);
      } else {
        this.reSendTitle = "批量重新发送";
        let planIdsArr = [];
        this.selectedRows.forEach((item) => {
          planIdsArr.push(item.id);
        });
        this.contentAdd = this.selectedRows[0].content;
        this.strategyId = this.selectedRows[0].strategyId;
        this.getReSend(planIdsArr.join(","));
        this.planId = this.selectedRows[0].id;
      }
      this.getQueryChannelSmsAccountList();
      if (this.strategyId && this.strategyId !== -1) {
        this.contentActive = "2";
      } else {
        this.contentActive = "1";
      }
      this.reSendShow = true;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 重新发送确定
    reSend_submit() {
      if (this.tabsKey == "massTexting") {
        let data = {
          realReceiveStatus: this.realReceiveStatus,
          sendStatus: this.sendStatus,
          modifyContent: this.modifyContent,
          channelSmsAccountIds: this.channelSmsAccountIds,
          submitTimeStart: this.submitTimeStart,
          submitTimeEnd: this.submitTimeEnd,
        };
        if (this.reSendTimeShow) {
          data.sendTime = this.reSendTime;
        }
        if (this.phoneOperatorShow) {
          data.operatorList = [];
        } else {
          data.operatorList = this.phoneOperator;
        }
        if (this.modifyContent) {
          if (this.contentActive == "1") {
            data.content = this.contentAdd;
          } else {
            data.strategyId = this.strategyId;
          }
        }
        if (this.selectedRows.length == 0) {
          data.planId = this.planId;
          this.$http.json_post("/sms/reSendMsgPlan", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("重新发送成功");
              this.reSendShow = false;
              this.inquire();
            }
          });
        } else {
          let selectedRows = [];
          this.selectedRows.forEach((item) => {
            selectedRows.push(item);
          });
          selectedRows.forEach((item) => {
            let batchData = {
              realReceiveStatus: this.realReceiveStatus,
              sendStatus: this.sendStatus,
              planId: item.id,
              sendTime: this.reSendTime,
              modifyContent: this.modifyContent,
              channelSmsAccountIds: this.channelSmsAccountIds,
              submitTimeStart: this.submitTimeStart,
              submitTimeEnd: this.submitTimeEnd,
            };
            if (this.reSendTimeShow) {
              batchData.sendTime = this.reSendTime;
            }
            if (this.phoneOperatorShow) {
              batchData.operatorList = [];
            } else {
              batchData.operatorList = this.phoneOperator;
            }
            if (this.modifyContent) {
              if (this.contentActive == "1") {
                batchData.content = this.contentAdd;
              } else {
                batchData.strategyId = this.strategyId;
              }
            }
            this.$http.json_post("/sms/reSendMsgPlan", batchData);
          });
          this.$message.success("重新发送成功");
          this.reSendShow = false;
          this.selectedRowKeys.splice(0);
          this.selectedRows.splice(0);
          this.inquire();
        }
      } else if (this.tabsKey == "interfaceSMS") {
        let data = {
          planId: this.planId,
          receiveRealStatus: this.receiveRealStatusModel,
          sendStatus: this.sendStatusModel,
          accountAccessKey: this.addAccountAccessKey,
          sign: this.reSendSign,
          startTime: this.reSendStartTime,
          endTime: this.reSendEndTime,
          channelSmsAccountId: this.channelSmsAccountId,
        };
        if (this.phoneOperatorShow) {
          data.operatorList = null;
        } else {
          data.operatorList = this.phoneOperator.join(",");
        }
        this.$http.get("/msgPlan/resend", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("重新发送成功");
            this.reSendShow = false;
            this.inquireInterface();
          }
        });
      }
    },
    speedControl(val) {
      this.planId = val.id;
      this.$http
        .get("/sms/speedControlInfo", { planId: this.planId })
        .then((res) => {
          if (res.result === 200) {
            if (res.data) {
              this.speedEnabled = true;
              this.speedSecond = res.data.second;
              this.speedCount = res.data.count;
            } else {
              this.speedEnabled = false;
              this.speedSecond = "1";
              this.speedCount = "1";
            }
            this.speedControlShow = true;
          }
        });
    },
    updateClosedState(val) {
      let data = {
        id: val.id,
        closed: !val.closed,
      };
      this.$confirm({
        title: "确认" + (data.closed ? "关闭" : "开启") + "批次",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.$http
            .json_post("/msgPlan/updateClosedState", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success((data.closed ? "关闭" : "开启") + "成功");
                this.inquire();
              }
            });
        },
        onCancel() {},
      });
    },
    speedControlSubmit() {
      let data = {
        planId: this.planId,
        enabled: this.speedEnabled,
      };
      if (this.speedEnabled) {
        data.second = this.speedSecond;
        data.count = this.speedCount;
      }
      this.$http.json_post("/sms/speedControl", data).then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.speedControlShow = false;
          this.inquire();
        }
      });
    },
    // 点击重新统计
    repair(val) {
      this.$confirm({
        title: "确认重新统计【" + val.activityCodeLabel + "】",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.post("/sms/repairMsgPlan", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("重新统计成功");
              this.inquire();
            }
          });
        },
        onCancel() {},
      });
    },
    // 刷新短信记录列表
    AsyncIntelligence() {
      if (this.asynTime) {
        clearInterval(this.asynTime);
        this.asynTime = null;
      }
      this.asynTime = setInterval(() => {
        if (this.tabsKey === "massTexting") {
          this.inquire();
        } else if (this.tabsKey === "interfaceSMS") {
          this.inquireInterface();
        }
      }, 2000);
    },
    // 点击导出
    exportClick() {
      let data = {
        planId: this.planId,
        mobile: this.sendMobile,
        sendStatus: this.sendSuccess,
        receiveRealStatus: this.receiveRealStatus,
        content: this.contentSearch,
      };
      if (this.phoneOperator) {
        data.operators = this.phoneOperator.join(",");
      }
      if (this.sendStartTime) {
        data.submitTimeStart = this.$common.transformTime(this.sendStartTime);
      }
      if (this.sendEndTime) {
        data.submitTimeEnd = this.$common.transformTime(this.sendEndTime);
      }
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href = this.$path + "/sms/export/excel?" + url;
      window.location.href = href;
    },
    // 复制成功
    onCopy(e) {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError(e) {
      this.$message.error("复制失败");
    },
    // 状态处理-1
    stateHandling1(val) {
      switch (val) {
        case "CREATING":
          return "创建中";

        case "WAITING":
          return "等待中";

        case "SUBMIT_SUCCESS":
          return "已提交";

        case "SENDING":
          return "发送中";

        case "PAUSED":
          return "已暂停";

        case "PAUSING":
          return "暂停中";

        case "FINISHED":
          return "已完成";

        case "COMMIT_FAILURE":
          return "提交失败";

        case "SEND_FAILURE":
          return "发送失败";

        case "WAIT_AUDIT":
          return "待审核";
      }
    },
    // 状态处理-2
    stateHandling2(val) {
      switch (val) {
        case "WAIT_SUBMIT":
          return "等待提交";

        case "SUBMITTING":
          return "提交中";

        case "SUBMIT_SUCCESS":
          return "提交成功";

        case "PRE_SUBMIT_SUCCESS":
          return "预提成功";

        case "SUBMIT_FAILURE":
          return "提交失败";

        case "SUCCESS":
          return "发送成功";

        case "FAILURE":
          return "发送失败";

        case "BLANK_NUMBER":
          return "空号";

        case "DOWNTIME":
          return "停机";

        case "WRONG_NUMBER":
          return "手机号格式错误";

        case "BLACKLIST":
          return "黑名单";

        case "UNKNOWN_OPERATOR":
          return "未知运营商";

        case "OVER_SEND":
          return "超频";

        case "ILLEGAL_SIGN":
          return "非法签名";

        case "OTHER":
          return "其它";

        case "UNKNOWN":
          return "未知状态";

        case "WAIT_AUDIT":
          return "待审核";
      }
    },
    // 状态处理-3
    stateHandling3(val) {
      switch (val) {
        case "WAIT_AUDIT":
          return "待审核";
        case "AUDIT_SUCCESS":
          return "审核成功";
        case "AUDIT_FAILURE":
          return "审核失败";
      }
    },
    //点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        smsPlanType: "BATCH_SEND",
        ...this.massTextingCondition,
      };
      if (data.rangeTime.length > 0) {
        data.startTime = data.rangeTime[0] + " 00:00:00";
        data.endTime = data.rangeTime[1] + " 23:59:59";
        delete data.rangeTime;
      }
      let obj = JSON.parse(JSON.stringify(data));
      delete obj.pageNo;
      delete obj.pageSize;
      let inquireData = JSON.stringify(obj);
      if (sessionStorage.getItem("inquireData")) {
        if (inquireData !== sessionStorage.getItem("inquireData")) {
          this.currentPage = 1;
          data.pageNo = 1;
          sessionStorage.setItem("inquireData", inquireData);
        }
      } else {
        sessionStorage.setItem("inquireData", inquireData);
      }
      this.getMsgPlanList(data);
    },
    // 查询短信记录列表
    getMsgPlanList(data) {
      this.$http.get("/sms/msgPlanList", data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          this.total = res.data.total;
          //数据处理
          list.map((item, index) => {
            item.key = item.id;
            item.failSendCount = item.planSendNums - item.successSendCount;
            item.gmtCreated = item.gmtCreated;
            item.sendTime = item.sendTime;
            item.statusLabel = this.stateHandling1(item.sendStatus); // 状态处理
            let sendStatusList = [
              "CREATING",
              "WAITING",
              "SENDING",
              "SUBMIT_SUCCESS",
              "PAUSING",
              "PAUSED",
            ];
            if (sendStatusList.indexOf(item.sendStatus) > -1) {
              item.sendStatusTag = "orange";
            }
            if (item.sendStatus === "FINISHED") {
              item.sendStatusTag = "green";
            }
            if (
              item.sendStatus === "COMMIT_FAILURE" ||
              item.sendStatus === "SEND_FAILURE"
            ) {
              item.sendStatusTag = "red";
            }
            item.activityCodeLabel = item.activityCode;
            item.isCopyShow = false;
            item.isIconShow = false;
            if (item.sendStatus === "CREATING") {
              item.isIconShow = true;
            }
            item.sendCounts =
              item.successSendCount +
              item.waitCallbackCount +
              item.failureSendCount;
            item.successSendRate = "0%";
            item.waitCallbackRate = "0%";
            item.failureSendRate = "0%";
            item.sendsRate = "0%";
            item.clickRate = "0%";
            item.clickUvRate = "0%";
            if (item.planSendNums !== 0) {
              item.successSendRate =
                ((item.successSendCount / item.planSendNums) * 100).toFixed(2) +
                "%";
              item.waitCallbackRate =
                ((item.waitCallbackCount / item.planSendNums) * 100).toFixed(
                  2
                ) + "%";
              item.failureSendRate =
                ((item.failureSendCount / item.planSendNums) * 100).toFixed(2) +
                "%";
              item.sendsRate =
                ((item.sendCounts / item.planSendNums) * 100).toFixed(2) + "%";
              if (item.successSendCount > 0) {
                item.clickRate =
                  ((item.clickCount / item.successSendCount) * 100).toFixed(2) +
                  "%";
                item.clickUvRate =
                  ((item.clickUvCount / item.successSendCount) * 100).toFixed(
                    2
                  ) + "%";
              }
            }
          });

          this.pagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            pageSizeOptions: ["10", "20", "50", "100"],
            showSizeChanger: true,
            onShowSizeChange: (current, pageSize) => {
              this.pageSize = pageSize;
              if (this.asynTime) {
                clearInterval(this.asynTime);
                this.asynTime = null;
              }
              this.inquire(1);
            },
            onChange: (current) => this.currentPage_data(current),
          };
          this.tableData = list;
          if (!this.asynTime) {
            this.AsyncIntelligence(); // 刷新短信记录列表
          }
        }
      });
    },
    //请求当前页数据
    currentPage_data(index) {
      this.currentPage = index;
      this.inquire(index);
    },
    // 查询详情表
    getDetailsList(data) {
      this.loadingDetailsTable = true;
      this.$http.get("/sms/msgPlanDetail/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.detailsTotal = res.data.total;
          list.forEach((item, index) => {
            item.key = index + 1;
            item.isCopyShow = false;
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            if (item.submitTime && item.submitTime != 946656000000) {
              item.submitTime = this.$common.transformTime(item.submitTime);
            } else {
              item.submitTime = "-";
            }
            item.statusLabel = this.stateHandling2(item.sendStatus); // 状态处理
            let sendStatusList = [
              "SUBMIT_FAILURE",
              "FAILURE",
              "BLANK_NUMBER",
              "DOWNTIME",
              "WRONG_NUMBER",
              "BLACKLIST",
              "UNKNOWN_OPERATOR",
              "OVER_SEND",
              "ILLEGAL_SIGN",
              "OTHER",
              "UNKNOWN",
              // "WAIT_AUDIT",
            ];
            if (sendStatusList.indexOf(item.sendStatus) > -1) {
              item.sendStatusTag = "red";
            }
            if (item.sendStatus === "SUCCESS") {
              item.sendStatusTag = "green";
            }
            if (
              item.sendStatus === "WAIT_SUBMIT" ||
              item.sendStatus === "SUBMITTING" ||
              item.sendStatus === "WAIT_AUDIT" ||
              item.sendStatus === "SUBMIT_SUCCESS" ||
              item.sendStatus === "PRE_SUBMIT_SUCCESS"
            ) {
              item.sendStatusTag = "orange";
            }
            item.contentStart = item.content;
            item.contentEnd = "";
            item.short_url = "";
            let shortUrl = "";
            if (item.params) {
              shortUrl = JSON.parse(item.params)["#短链#"];
            }
            if (shortUrl) {
              if (item.content.indexOf(shortUrl) > -1) {
                let short_url_blank = "";
                shortUrl.indexOf("http://") > -1
                  ? (short_url_blank = shortUrl.trim())
                  : (short_url_blank = "http://" + shortUrl.trim());
                item.short_url_blank = short_url_blank;
                item.short_url = shortUrl;
                let length = shortUrl.length - 1;
                item.contentStart = item.content.substr(
                  0,
                  item.content.indexOf(shortUrl)
                );
                item.contentEnd = item.content.substr(
                  item.content.indexOf(shortUrl) + length
                );
              }
            }
          });
          this.paginationDetails = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_details(current),
          };
          this.detailsTable = list;
          this.loadingDetailsTable = false;
        }
      });
    },
    //请求详情表当前页数据
    currentPage_details(index) {
      this.sendInquire(index);
    },
    // 点击查询群发短信详情
    sendInquire(index) {
      let data = {
        pageNo: index || 1,
        pageSize: 10,
        mobile: this.sendMobile,
        planId: this.planId,
        sendStatus: this.sendSuccess,
        receiveRealStatus: this.receiveRealStatus,
        content: this.contentSearch,
        channelSmsAccountId: this.channelSmsAccountId,
      };
      if (this.phoneOperator) {
        data.operators = this.phoneOperator.join(",");
      }
      if (this.sendStartTime) {
        data.submitTimeStart = this.$common.transformTime(this.sendStartTime);
      }
      if (this.sendEndTime) {
        data.submitTimeEnd = this.$common.transformTime(this.sendEndTime);
      }
      this.getDetailsList(data);
    },
    // 群发短信详情选择状态
    statusSendChange(val) {
      this.sendSuccess = this.statusSend.join(",");
    },
    getRouteExceptionInfo(val) {
      let data = {
        planId: this.planId,
        customerCode: val.customerCode,
      };
      this.$http.get("/sms/routeExceptionInfo", data).then((res) => {
        if (res.result === 200) {
          this.routeExceptionInfoList.splice(0);
          res.data.exceptionRouteInfoList.forEach((item) => {
            this.routeExceptionInfoList.push(...item.exceptionInfo.split(";"));
          });
          this.routeExceptionInfoShow = true;
        }
      });
    },
    // 点击查询详情
    viewConnected(val, sendSuccess) {
      if (sendSuccess) {
        this.sendSuccess = sendSuccess;
        this.statusSend = sendSuccess.split(",");
        if (sendSuccess === "SUCCESS") {
          this.sendTitle = "【" + val.activityCode + "】发送成功详情";
        } else if (sendSuccess === "FAILURE,SUBMIT_FAILURE") {
          this.sendTitle = "【" + val.activityCode + "】发送失败详情";
        } else {
          this.sendTitle = "【" + val.activityCode + "】等待回执详情";
        }
      } else {
        this.sendSuccess = "";
        this.sendTitle = "【" + val.activityCode + "】发送详情";
      }
      this.planId = val.id;
      this.channelSmsAccountId = undefined;
      this.sendShow = true;
      let data = {
        pageNo: 1,
        pageSize: 10,
        planId: val.id,
        sendStatus: this.sendSuccess,
      };
      this.getDetailsList(data);
    },
    // 关闭发送详情
    sendClose() {
      this.detailsTable = [];
      this.statusSend = [];
      this.sendStartTime = null;
      this.sendEndTime = null;
      this.sendMobile = "";
      this.receiveRealStatus = "";
      this.contentSearch = "";
      this.phoneOperator = undefined;
    },
    // 群发短信页面
    // 不可选的发送日期
    disabledGmtCreatedStartDateSend(startValue) {
      const endValue = this.sendEndTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledGmtCreatedEndDateSend(endValue) {
      const startValue = this.sendStartTime;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    // 选择发送日期
    handleGmtCreatedStartOpenChangeSend(open) {
      if (!open) {
        this.gmtEndOpenSend = true;
      }
    },
    handleGmtCreatedEndOpenChangeSend(open) {
      this.gmtEndOpenSend = open;
    },
    // 接口短信页面
    // 不可选的发送日期
    disabledGmtCreatedStartDateReSend(startValue) {
      const endValue = this.reSendEndTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledGmtCreatedEndDateReSend(endValue) {
      const startValue = this.reSendStartTime;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    disabledGmtCreatedStartDateInterface(startValue) {
      const endValue = this.interfaceEndTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledGmtCreatedEndDateInterface(endValue) {
      const startValue = this.interfaceStartTime;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    // 选择发送日期
    handleGmtCreatedStartOpenChangeInterface(open) {
      if (!open) {
        this.gmtEndOpenInterface = true;
      }
    },
    handleGmtCreatedEndOpenChangeInterface(open) {
      this.gmtEndOpenInterface = open;
    },
    // 表格混淆点击
    mixClick(val) {
      this.modalPlanId = val.id;
      this.mixShow = true;
      if (val.mocked) {
        this.btnShow = false;
        let data = {
          planId: val.id,
        };
        this.$http.get("/sms/getConfusionData", data).then((res) => {
          if (res.result === 200) {
            let rate = res.data.rate;
            this.mixData[0].rate = rate;
            this.mixData[0].num =
              val.successSendCount - res.data.successIncrement;
            this.mixData[1].num =
              val.failureSendCount - res.data.failureIncrement;
            // this.mixData[2].num = val.waitCallbackCount;
            this.mixData[2].num = 0;
            this.mixData[3].num =
              this.mixData[0].num + this.mixData[1].num + this.mixData[2].num;
            for (var i = 0; i < 3; i++) {
              // this.mixData[i].mixNum =
              //   (this.mixData[i].num * this.mixData[0].rate) / 100;
              if (i < 2) {
                this.mixData[i].mixNum = Math.floor(
                  (this.mixData[i].num * this.mixData[0].rate) / 100
                );
              } else {
                this.mixData[i].mixNum = 0;
              }
              this.mixData[i].mixAfterNum =
                this.mixData[i].num + this.mixData[i].mixNum;
              this.mixData[i].rateShow = this.mixData[0].rate + "%";
            }
            this.mixData[3].mixNum =
              this.mixData[0].mixNum +
              this.mixData[1].mixNum +
              this.mixData[2].mixNum;
            this.mixData[3].mixAfterNum =
              this.mixData[0].mixAfterNum +
              this.mixData[1].mixAfterNum +
              this.mixData[2].mixAfterNum;
          }
        });
      } else {
        this.btnShow = true;
        // successSendCount  failureSendCount  waitCallbackCount
        this.mixData[0].num = val.successSendCount;
        this.mixData[1].num = val.failureSendCount;
        this.mixData[2].num = val.waitCallbackCount;
        this.mixData[3].num =
          val.successSendCount + val.waitCallbackCount + val.failureSendCount;
        for (var i = 0; i < 3; i++) {
          // this.mixData[i].mixNum =
          //   (this.mixData[i].num * this.mixData[0].rate) / 100;
          if (i < 2) {
            this.mixData[i].mixNum = Math.floor(
              (this.mixData[i].num * this.mixData[0].rate) / 100
            );
          } else {
            this.mixData[i].mixNum = 0;
          }
          this.mixData[i].mixAfterNum =
            this.mixData[i].num + this.mixData[i].mixNum;
          this.mixData[i].rateShow = this.mixData[0].rate + "%";
        }
        // this.mixData[3].rate = this.mixData[0].rate;
        this.mixData[3].mixNum =
          this.mixData[0].mixNum +
          this.mixData[1].mixNum +
          this.mixData[2].mixNum;
        this.mixData[3].mixAfterNum =
          this.mixData[0].mixAfterNum +
          this.mixData[1].mixAfterNum +
          this.mixData[2].mixAfterNum;
      }
    },
    mixClose() {
      this.modalRate = "";
      this.mixData[0].rate = 0;
      this.mixData[0].rateShow = this.mixData[0].rate + "%";
    },
    rateModalClose() {},
    // 修改比例
    rateClick() {
      this.rateModalShow = true;
    },
    // 比例确定
    rate_submit() {
      this.mixData[0].rate = this.modalRate;
      this.mixData[0].rateShow = this.mixData[0].rate + "%";
      this.rateModalShow = false;
      for (var i = 0; i < 3; i++) {
        if (i < 2) {
          this.mixData[i].mixNum = Math.floor(
            (this.mixData[i].num * this.mixData[0].rate) / 100
          );
        } else {
          this.mixData[i].mixNum = 0;
        }
        this.mixData[i].mixAfterNum =
          this.mixData[i].num + this.mixData[i].mixNum;
        this.mixData[i].rateShow = this.mixData[0].rate + "%";
      }
      this.mixData[3].mixNum =
        this.mixData[0].mixNum +
        this.mixData[1].mixNum +
        this.mixData[2].mixNum;
      this.mixData[3].mixAfterNum =
        this.mixData[0].mixAfterNum +
        this.mixData[1].mixAfterNum +
        this.mixData[2].mixAfterNum;
    },
    // 混淆确定
    mix_submit() {
      let data = {
        planId: this.modalPlanId,
        rate: this.mixData[0].rate,
      };
      this.$http.get("/sms/confusionData", data).then((res) => {
        if (res.result === 200) {
          this.mixShow = false;
          this.$message.success("混淆成功");
        }
      });
    },
    // 立即更新
    updateNow() {
      this.loadingTable = true;
      let data = {
        msgPlanId: this.aisleId,
      };
      this.$http.get("/sms/doAnalysis", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("更新成功");
          this.getAnalysis();
          this.getAnalysis1();
          this.loadingTable = false;
        }
      });
    },
    // 点击通道分析
    aisleClick(val) {
      this.aisleId = val.id;
      this.aisleShow = true;
      // /sms/analysis?msgPlanId=&type=userAccount    operator
      this.getAnalysis();
      this.getAnalysis1();
    },
    getAnalysis() {
      let data = {
        msgPlanId: this.aisleId,
        type: this.aisleType,
      };
      this.$http.get("/sms/analysis", data).then((res) => {
        if (res.result === 200) {
          this.tableUserAccount = res.data;
        }
      });
    },
    getAnalysis1() {
      let data = {
        msgPlanId: this.aisleId,
        type: "operator",
      };
      this.$http.get("/sms/analysis", data).then((res) => {
        if (res.result === 200) {
          this.tableOperator = res.data;
        }
      });
    },
    aisleCallback(val) {
      if (val === "1") {
      } else if (val === "2") {
      }
    },
    aisleClose() {},
  },
  beforeDestroy() {
    if (this.asynTime) {
      clearInterval(this.asynTime);
      this.asynTime = null;
    }
  },
};
</script>

<style scoped lang="scss">
.icon-loading {
  margin: 0px 5px 0px 10px;
}
.copy-class {
  color: #52c41a;
}
.smmSmarketingRecords-button {
  margin-bottom: 10px;
}
.table-tr-btn {
  margin: 0 4px;
  cursor: pointer;
}
.table-tr-btn:hover {
  color: #409eff;
}

.status {
  &::v-deep .ant-switch {
    background-color: #ffa600;
  }
  &::v-deep .ant-switch-checked {
    background-color: #1890ff !important;
  }
}
.contentStatus {
  &::v-deep .ant-switch {
    background-color: #1890ff;
  }
  &::v-deep .ant-switch-checked {
    background-color: #52c41a !important;
  }
}
.sms-info-style {
  margin-top: 10px;
}
.info-required::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
::v-deep .ant-table {
  border: none !important;
  table {
    border: 1px solid #e8e8e8 !important;
    .ant-table-tbody {
      td {
        border: none !important;
      }
    }
  }
}
::v-deep .resize-table-th {
  position: relative;
  .table-draggable-handle {
    transform: none !important;
    position: absolute;
    height: 100% !important;
    bottom: 0;
    left: auto !important;
    right: -5px;
    cursor: col-resize;
    touch-action: none;
  }
}
.planTable {
  &::v-deep .ant-table-scroll {
    .ant-table-header {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      overflow: auto !important;
    }
    .ant-table-body {
      .ant-table-row:last-child {
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: #f9f9f9;
      }
    }
  }
}
.icon-yu {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #939393;
  border: 1px solid #939393;
  border-radius: 50%;
}
</style>
