<template>
  <div class="channelRetrySendLog">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>客户编号：</span>
        <a-input
          placeholder="请输入客户编号"
          v-model.trim="searchCondition.customerCode"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchConditionItem">
        创建时间：
        <a-date-picker
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          v-model="searchCondition.gmtCreatedStart"
          placeholder="开始日期"
          @openChange="handleGmtCreatedStartOpenChange"
        />
        <i style="display: inline-block; margin-left: 20px"></i>
        <a-date-picker
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="结束日期"
          v-model="searchCondition.gmtCreatedEnd"
          :open="gmtEndOpen"
          @openChange="handleGmtCreatedEndOpenChange"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
    </a-table>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "channelRetrySendLog",
  props: ["retryGroupItemId"],
  data() {
    return {
      searchCondition: {
        customerCode: "",
        gmtCreatedStart: moment().format("YYYY-MM-DD"),
        gmtCreatedEnd: moment().format("YYYY-MM-DD"),
      },
      gmtEndOpen: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "任务id",
          dataIndex: "planId",
          ellipsis: true,
          width: 100,
        },
        {
          title: "客户编号",
          dataIndex: "customerCode",
          ellipsis: true,
          width: 120,
        },
        {
          title: "内容",
          dataIndex: "msgContent",
          ellipsis: true,
        },
        {
          title: "重提通道名称",
          dataIndex: "channelSmsAccountName",
          ellipsis: true,
          width: 200,
        },
        {
          title: "原始通道名称",
          dataIndex: "originalChannelSmsAccountName",
          ellipsis: true,
          width: 200,
        },
        {
          title: "原始失败原因",
          dataIndex: "failureStatus",
          ellipsis: true,
          width: 150,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 170,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  watch: {
    retryGroupItemId: {
      handler() {
        this.query(1);
      },
    },
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 选择发送日期
    handleGmtCreatedStartOpenChange(open) {
      if (!open) {
        this.gmtEndOpen = true;
      }
    },
    handleGmtCreatedEndOpenChange(open) {
      this.gmtEndOpen = open;
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        ...this.searchCondition,
        retryGroupItemId: this.retryGroupItemId,
      };
      if (data.gmtCreatedStart) {
        data.gmtCreatedStart = data.gmtCreatedStart + " 00:00:00";
      }
      if (data.gmtCreatedEnd) {
        data.gmtCreatedEnd = data.gmtCreatedEnd + " 23:59:59";
      }
      this.loadingTable = true;
      this.$http
        .get("/channelRetryGroup/channelRetrySendLogPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.query(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
  },
};
</script>
