<template>
  <div class="shortLinks">
    <div class="searchCondition">
      <div class="searchConditionItem">
        短链名称：
        <a-input
          placeholder="请输入短链名称"
          v-model="activityCodeTitle"
          @keyup.enter="inquire(1)"
        />
      </div>
      <div class="searchConditionItem">
        添加时间：
        <a-range-picker
          :disabledDate="disabledDate"
          :defaultValue="[moment().startOf('month'), moment().endOf('day')]"
          :ranges="{
            今天: [moment(), moment()],
            本月: [moment().startOf('month'), moment().endOf('day')],
          }"
          format="YYYY-MM-DD"
          @change="onChangeIntelligenceTime"
        />
      </div>
      <div class="searchConditionItem">
        长链网址：
        <a-input
          placeholder="请输入长链"
          v-model="activitySubCodeTitle"
          @keyup.enter="inquire(1)"
        />
      </div>
      <div class="searchConditionItem">
        长链分组：
        <a-select
          v-model="longUrlGroupId"
          placeholder="请选择长链分组"
          allowClear
          showSearch
          :filterOption="false"
          @search="getLongUrlGroupList"
          @change="inquire(1)"
        >
          <a-select-option
            v-for="i in longUrlGroupList"
            :key="i.id"
            :value="i.id"
            >{{ i.name }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        业务类型：
        <a-select
          v-model="category"
          placeholder="请选择类型"
          @change="categoryChange"
          allowClear
        >
          <a-select-option
            v-for="i in categoryList"
            :key="i.value"
            :value="i.value"
          >
            {{ i.key }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查询</a-button>
        <a-button type="primary" @click="shortLinkClick()">添加短链</a-button>
        <a-dropdown :disabled="selectedRows.length == 0">
          <a-button type="primary"> 批量操作 <a-icon type="down" /> </a-button>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="batchClickSend">批量发送</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="batchDistribute">批量分配</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="batchModifyExpireRule"
                >批量修改过期策略</a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <!-- 表格 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(columnsIntelligence)"
      :columns="columnsIntelligence"
      :dataSource="dataIntelligence"
      :pagination="paginationIntelligence"
      :loading="loadingTable"
      :rowKey="(record) => record.id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      size="small"
    >
      <span slot="longUrl" slot-scope="text, record" class="slotCell">
        <template v-if="record.longUrlGroupId">
          <a
            href="javascript:;"
            class="content"
            @click="getLongUrlGroupDetail(record.longUrlGroupId)"
            >{{ record.longUrlGroupName }}</a
          >
        </template>
        <template v-else>
          <a-tooltip placement="topLeft">
            <template slot="title">{{ record.longUrl }}</template>
            <a :href="record.longUrl" target="_blank" class="content">{{
              text
            }}</a>
          </a-tooltip>
          <a
            href="javascript:;"
            class="copy-class"
            v-clipboard:copy="record.longUrl"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            v-if="record.isCopyShow"
            >复制</a
          >
        </template>
      </span>
      <span slot="shortUrlCount" slot-scope="text, record">
        <a href="javascript:;" @click="analysisClick(record)">{{ text }}</a>
      </span>
      <span slot="status" slot-scope="text">
        <a-tag v-if="text === 'CREATING'" color="orange"
          >创建中
          <a-icon type="loading" />
        </a-tag>
        <a-tag v-if="text === 'NORMAL'" color="green">正常</a-tag>
        <a-tag v-if="text === 'ERROR'" color="red">错误</a-tag>
        <a-tag v-if="text === 'CLOSED'" color="orange">已关闭</a-tag>
        <a-tag v-if="text === 'TERMINATED'" color="orange">已终止</a-tag>
        <a-tag v-if="text === 'UPDATING'" color="orange">更新中</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a
          href="javascript:;"
          :disabled="record.status === 'CREATING' || record.status === 'CLOSED'"
          @click="clickSend(record)"
          >发送短信</a
        >
        <a-divider type="vertical" />
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a
                href="javascript:;"
                :disabled="record.status !== 'NORMAL'"
                @click="modifyLongUrlClick(record)"
                class="modify-long-click"
                >修改长链</a
              >
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                @click="openShort(record)"
                :disabled="
                  record.status === 'CREATING' ||
                  record.status === 'NORMAL' ||
                  record.status === 'TERMINATED'
                "
                >开启</a
              >
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                @click="closeShort(record)"
                :disabled="
                  record.status === 'CREATING' ||
                  record.status === 'CLOSED' ||
                  record.status === 'TERMINATED'
                "
                >关闭</a
              >
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                @click="endShort(record)"
                :disabled="record.status !== 'CREATING'"
                >终止</a
              >
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                style="color: red"
                @click="deleteShort(record)"
                >删除</a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <!-- 批次详情 -->
    <a-modal
      title="批次详情"
      v-model="batchDetailListShow"
      :maskClosable="false"
      width="900px"
      :afterClose="batchDetailListClose"
      class="actionSub-class"
      :footer="null"
    >
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(batchDetailListColumns)"
        :columns="batchDetailListColumns"
        :dataSource="dataAnalysis"
        :pagination="paginationAnalysis"
        size="small"
      ></a-table>
    </a-modal>
    <!-- 发送短信 -->
    <a-modal
      :title="sendShortName + '发送短信'"
      v-model="sendShortShow"
      :maskClosable="false"
      :afterClose="sendShortClose"
      width="1100px"
      class="actionSub-class"
    >
      <div class="number-pool" v-if="!batchClick">
        <div class="number-pool-item">
          <div>有效号码</div>
          <a href="javascript:;" class="number" @click="detailsClick()">{{
            pooiForm.validNumber
          }}</a>
        </div>
        <div class="number-pool-item">
          <div>移动</div>
          <a
            href="javascript:;"
            class="number"
            @click="detailsClick('中国移动')"
            >{{ pooiForm.moveNumber }}</a
          >
        </div>
        <div class="number-pool-item">
          <div>联通</div>
          <a
            href="javascript:;"
            class="number"
            @click="detailsClick('中国联通')"
            >{{ pooiForm.unicomNumber }}</a
          >
        </div>
        <div class="number-pool-item">
          <div>电信</div>
          <a
            href="javascript:;"
            class="number"
            @click="detailsClick('中国电信')"
            >{{ pooiForm.telecomNumber }}</a
          >
        </div>
      </div>
      <div class="contentLR">
        <div class="left">
          <a-form>
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 21 }"
              label="精准发送:"
            >
              <a-switch
                v-model="isPrecise"
                @change="preciseChange"
                size="small"
              />
              <a-checkbox-group
                v-model="operatorList"
                class="operatorList-checkbox"
                v-if="isPreciseCheck"
              >
                <a-checkbox value="中国移动">移动</a-checkbox>
                <a-checkbox value="中国联通">联通</a-checkbox>
                <a-checkbox value="中国电信">电信</a-checkbox>
              </a-checkbox-group>
            </a-form-item>
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 21 }"
              label="账号"
            >
              <a-select
                v-model="sendForm.userSmsAccountId"
                placeholder="请选择账号"
                class="select-tag"
                showSearch
                :filterOption="false"
                @change="userSmsChange"
                @search="userSearch"
                style="width: 70%"
              >
                <a-select-option
                  v-for="i in userLoginNameList"
                  :key="i.id"
                  :value="i.id"
                  >{{ i.accessKey }} | {{ i.alias }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 21 }"
              label="签名:"
            >
              <a-select
                v-model="sendForm.signId"
                placeholder="请选择签名"
                class="select-tag"
                showSearch
                :filterOption="filterOption"
                style="width: 70%"
                @change="sighChange"
              >
                <a-select-option
                  v-for="i in signList"
                  :key="i.signId"
                  :value="i.signId"
                  >{{ i.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 21 }"
              label="短信内容:"
            >
              <a-tabs v-model="contentActive" @change="callback">
                <a-tab-pane key="1" tab="短信内容">
                  <div class="smsContentTemplate-c">
                    <a
                      href="javascript:void(0)"
                      @click="smsContentTemplateClick"
                      >使用模版</a
                    >
                  </div>
                  <sms-input
                    :maxUrlLength="maxUrlLength"
                    :textareaSMS="textarea"
                    :prefixValue="prefix"
                    :sign="sendForm.sign"
                    @change="textarea_sms"
                    @prefixChange="prefixChange"
                  ></sms-input>
                </a-tab-pane>
                <a-tab-pane key="2" tab="选择模板">
                  <a-select
                    v-model="strategyId"
                    placeholder="请选择模板"
                    class="select-tag"
                    showSearch
                    @search="getStrategyList"
                    @change="strategyChange"
                  >
                    <a-select-option
                      v-for="i in strategyList"
                      :key="i.id"
                      :value="i.id"
                      >{{ i.name }}</a-select-option
                    >
                  </a-select>
                </a-tab-pane>
              </a-tabs>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 24 }">
              <i style="display: inline-block; margin-left: 116px"></i>
              <a-checkbox v-model="checkedTiming">定时发送</a-checkbox>
              <a-date-picker
                v-if="checkedTiming"
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                v-model="sendForm.time"
                placeholder="选择日期时间"
              />
              <div v-if="isRetransmission">
                <i style="display: inline-block; margin-left: 116px"></i>
                <a-checkbox v-model="retransmission">
                  <span style="color: #f56c6c"
                    >该分组已发送过短信, 确认还需要再发送一次</span
                  >
                </a-checkbox>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="right">
          <div
            class="bubble"
            v-if="this.sendForm.sign !== undefined || this.textarea !== ''"
          >
            {{ sendForm.sign }}{{ textarea }}
          </div>
        </div>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="sendShortShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            @click="sendShort_submit"
            :loading="sendLoading"
            :disabled="
              !this.sendForm.signId ||
              (this.contentActive == '1' && !this.textarea) ||
              (!this.retransmission && this.isRetransmission) ||
              (this.checkedTiming && !this.sendForm.time) ||
              (this.contentActive == '2' &&
                !this.isCheckStrategyContentShortUrlTag) ||
              (this.contentActive == '2' && !this.strategyId)
            "
            >确定发送</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 添加短链接 -->
    <a-modal
      :title="shortModalTitle"
      v-model="shortLinkShow"
      :maskClosable="false"
      :afterClose="shortLinkClose"
      class="actionSub-class"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <template v-if="!batchModifyExpireRuleShow">
          <a-form-item
            v-if="shortModalTitle == '添加短链接'"
            label="号码文件:"
            required
          >
            <a-upload-dragger
              :fileList="fileList"
              :remove="handleRemoveMale"
              :beforeUpload="beforeUploadMale"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">单击或拖动文件到此区域以上传</p>
            </a-upload-dragger>
            <span class="dropbox-ts">支持文件格式：.txt。 </span>
          </a-form-item>
          <a-form-item label="短链名称:" required>
            <div class="s-url-input">
              <template v-if="shortModalTitle == '添加短链接'">
                <a-input
                  v-model="name"
                  placeholder="请输入短链名称"
                  @blur="groupExist()"
                />
                <span
                  class="dropbox-ts"
                  :style="{ color: groupExistYesOrNo ? 'red' : '#52c41a' }"
                  v-show="groupExistShow"
                >
                  {{ groupExistYesOrNo ? "名称已存在，请更换" : "名称可用" }}
                </span>
              </template>
              <template v-else>
                <a-input v-model="name" placeholder="请输入短链名称" />
              </template>
            </div>
          </a-form-item>
          <a-form-item label="长链" required>
            <div>
              <div
                :class="{ card: true, checked: active == 0 }"
                @click="active = 0"
              >
                单链
              </div>
              <div
                :class="{ card: true, checked: active == 1 }"
                @click="active = 1"
              >
                长链分组
              </div>
            </div>
            <template v-if="active == 0">
              <a-input
                v-model="longUrl"
                placeholder="请输入长网址链接"
                @blur="compareToLongUrl"
              />
              <a-tooltip placement="topLeft">
                <template slot="title"> 长链校验 </template>
                <a-checkbox
                  v-model="needLongUrlCheck"
                  style="position: absolute; left: 325px; top: 40px"
                />
              </a-tooltip>
              <span
                class="dropbox-ts"
                :style="{ color: longUrlExistYesOrNo ? '#52c41a' : 'red' }"
                v-if="urlFormat"
              >
                {{
                  longUrlExistYesOrNo
                    ? "校验通过：" + templateName
                    : urlFormatText
                }}
              </span>
            </template>
            <template v-else>
              <a-select
                v-model="longUrlGroupIdAdd"
                placeholder="请选择长链分组"
                showSearch
                :filterOption="false"
                @search="getLongUrlGroupList"
              >
                <a-select-option
                  v-for="i in longUrlGroupList"
                  :key="i.id"
                  :value="i.id"
                  >{{ i.name }}</a-select-option
                >
              </a-select>
            </template>
          </a-form-item>
          <a-form-item label="选择域名:" required>
            <a-select v-model="selectDomain" placeholder="请选择域名">
              <a-select-option
                v-for="i in selectDomainList"
                :key="i.value"
                :value="i.value"
                >{{ i.label }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="业务类型:" required>
            <a-select
              v-model="selectGory"
              placeholder="请选择类型"
              @change="selectGoryCheckChange()"
            >
              <a-select-option
                v-for="i in categoryList"
                :key="i.value"
                :value="i.value"
              >
                {{ i.key }}
              </a-select-option>
            </a-select>
            <a-tooltip placement="topLeft">
              <template slot="title"> 记住业务类型 </template>
              <a-checkbox
                v-model="selectGoryCheck"
                @change="selectGoryCheckChange()"
                style="position: absolute; left: 325px; top: -10px"
              />
            </a-tooltip>
          </a-form-item>
          <a-form-item
            label="数据来源:"
            v-if="shortModalTitle == '添加短链接'"
            required
          >
            <a-select v-model="source" placeholder="请选择数据来源">
              <a-select-option
                v-for="i in sourceList"
                :key="i.value"
                :value="i.value"
              >
                {{ i.key }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </template>
        <a-form-item label="过期策略:" required>
          <a-select v-model="expireRule" placeholder="请选择过期策略">
            <a-select-option
              v-for="(item, index) in expireRuleTypeList"
              :key="index"
              :value="item.code"
            >
              {{ item.desc }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="过期时间:"
          v-if="expireRule !== 'UN_EXPIRE'"
          required
        >
          <a-input
            v-model="expireTime"
            placeholder="请输入过期时间"
            style="width: 200px"
          >
            <a-select
              slot="addonAfter"
              v-model="expireUnit"
              style="width: 65px"
            >
              <a-select-option value="月"> 月 </a-select-option>
              <a-select-option value="天"> 天 </a-select-option>
              <a-select-option value="小时"> 小时 </a-select-option>
              <a-select-option value="分钟"> 分钟 </a-select-option>
              <a-select-option value="秒"> 秒 </a-select-option>
            </a-select>
          </a-input>
        </a-form-item>
        <template v-if="!batchModifyExpireRuleShow">
          <a-form-item label="点击回调:">
            <a-select
              v-model="clickCallbackUrlIdList"
              placeholder="请选择"
              class="select-tag"
              mode="multiple"
              showSearch
              :filterOption="false"
              @search="getClickCallbackUrlList"
            >
              <a-select-option
                v-for="item in clickCallbackUrlList"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <span slot="label">
              空号检测
              <a-tooltip placement="topLeft" style="margin-left: 5px">
                <template slot="title">
                  随机选择若干空号插入到批次，进行空号检测<br />
                  开启空号检测后,发送条数会增多(最多100条)
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </span>
            <a-checkbox v-model="blankNumberCheck" />
          </a-form-item>
        </template>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="shortLinkShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            v-if="batchModifyExpireRuleShow"
            @click="batchModifyExpireRuleSubmit"
            >确定</a-button
          >
          <a-button
            key="submit"
            type="primary"
            v-else
            @click="shortLink_submit"
            :disabled="
              shortLinkDisabled ||
              groupExistYesOrNo ||
              (!longUrlExistYesOrNo && urlFormat)
            "
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 分析 -->
    <a-modal
      :title="sendShortName + '分析'"
      v-model="analysisShow"
      :maskClosable="false"
      :footer="null"
      :afterClose="analysisClose"
      width="1000px"
      class="actionSub-class"
    >
      <div class="shortLinks-button">
        <a-button type="primary" @click="exportClick">导出</a-button>
        <a-button @click="inquireAnalysis">刷新</a-button>
      </div>
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(columnsAnalysis)"
        :columns="columnsAnalysis"
        :dataSource="dataAnalysis"
        :pagination="paginationAnalysis"
        size="small"
      >
        <span slot="shortUrl" slot-scope="text, record" class="slotCell">
          <a :href="record.shortUrl" target="_blank" class="content">{{
            text
          }}</a>
        </span>
        <span slot="longUrl" slot-scope="text, record" class="slotCell">
          <a
            :href="record.longUrl"
            target="_blank"
            :title="text"
            class="content"
            >{{ text }}</a
          >
        </span>
        <span slot="pv" slot-scope="text, record">
          <a href="javascript:;" @click="clickPv(record)">{{ text }}</a>
        </span>
      </a-table>
    </a-modal>

    <a-modal
      title="长链分组详情"
      v-model="longUrlGroupDetailVisible"
      :maskClosable="false"
      class="action-class"
      width="1000px"
    >
      <h3>路由类型:{{ routingType == "WITH_ORDER" ? "轮循" : "随机" }}</h3>
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(longUrlGroupDetailColumns)"
        :columns="longUrlGroupDetailColumns"
        :dataSource="longUrlGroupDetailList"
        :pagination="false"
        size="small"
        style="margin: 10px 0"
      >
        <span slot="enabled" slot-scope="text, record">
          <a-tag v-if="text" color="green"> 已启用 </a-tag>
          <a-tag v-if="!text" color="red"> 已禁用 </a-tag>
        </span>
      </a-table>
      <h3>默认长链:{{ defaultLongUrl }}</h3>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="longUrlGroupDetailVisible = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="批量分配"
      v-model="batchDistributeShow"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="产品名称:">
          <a-select
            v-model="productName"
            placeholder="请选择产品"
            @change="getLongUrlDistributeCount"
          >
            <a-select-option
              v-for="(item, index) in productList"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="时间间隔:">
          <a-select
            v-model="stepDate"
            placeholder="请选择时间间隔"
            @change="getLongUrlDistributeCount"
          >
            <a-select-option
              v-for="(item, index) in stepDateList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="可用连接条数:"
          v-if="longUrlDistributeCountShow == true"
        >
          {{ longUrlDistributeCount }}
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="batchDistributeShow = false"
            >取消</a-button
          >
          <a-button type="primary" @click="batchDistributeSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="批量分配详情"
      v-model="batchDistributeDetailShow"
      :maskClosable="false"
      class="action-class"
      width="1000px"
      :afterClose="batchDistributeDetailClose"
    >
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(batchDistributeDetailColumns)"
        :columns="batchDistributeDetailColumns"
        :dataSource="batchDistributeDetailList"
        :pagination="false"
        size="small"
      >
        <span slot="status" slot-scope="text">
          <a-tag v-if="text === '成功'" color="green">成功</a-tag>
          <a-tag v-if="text === '失败'" color="red">失败</a-tag>
          <a-tag v-if="text === '匹配中'" color="orange">
            匹配中
            <a-icon type="loading" />
          </a-tag>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button
            type="danger"
            v-if="allowLongUrlDistribute"
            @click="cancelLongUrlDistribute"
            >取消</a-button
          >
          <template v-else>
            <a-button key="back" @click="batchDistributeDetailShow = false"
              >关闭</a-button
            >
            <a-button type="primary" v-copy="copyBatchDistributeDetailListText"
              >复制</a-button
            >
          </template>
        </div>
      </template>
    </a-modal>

    <!-- 点击显示 -->
    <a-drawer
      title="PV详情"
      placement="right"
      :width="590"
      :closable="false"
      @close="onClosePv"
      :visible="visiblePv"
    >
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(columnsPv)"
        :columns="columnsPv"
        :dataSource="dataPv"
        :pagination="paginationPv"
        size="small"
      ></a-table>
    </a-drawer>
    <!-- 短信模版 -->
    <smsContentTemplate
      :show="smsContentTemplateShow"
      :width="900"
      @close="smsContentTemplateClose"
    ></smsContentTemplate>
  </div>
</template>

<script>
import moment from "moment";
import reqwest from "reqwest";
import smsInput from "../tool/smsInput.vue";
import smsContentTemplate from "../tool/smsContentTemplate.vue";
import axios from "axios";

export default {
  name: "intelligence",
  data() {
    return {
      sendLoading: false,
      randomNum: "",
      smsContentTemplateShow: false,
      intelligenceTime: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("day").format("YYYY-MM-DD"),
      ],
      shortLinkDisabled: false,
      selectDomain: undefined,
      selectDomainList: [],
      selectGory: "游戏",
      categoryList: [],
      source: "未知",
      sourceList: [],
      clickCallbackUrlIdList: [],
      shortModalTitle: "添加短链接",
      syncLoading: null,
      analysisPageNo: 1,
      urlFormat: false,
      urlFormatText: "",
      longUrlExistYesOrNo: false,
      needLongUrlCheck: true,
      templateName: "",
      prefix: undefined,
      pooiForm: {
        validNumber: 0,
        moveNumber: 0,
        unicomNumber: 0,
        telecomNumber: 0,
      },
      isPrecise: false,
      operatorList: [],
      isPreciseCheck: false,
      shortCode: "",
      columnsPv: [
        {
          title: "点击时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "号码",
          ellipsis: true,
          dataIndex: "userCode",
          width: 150,
        },
        {
          title: "ip",
          ellipsis: true,
          dataIndex: "ip",
          width: 120,
        },
        {
          title: "设备",
          ellipsis: true,
          dataIndex: "deviceInfo",
          width: 100,
        },
      ],
      dataPv: [],
      paginationPv: {},
      visiblePv: false,
      groupId: "",
      analysisShow: false,
      columnsAnalysis: [
        {
          title: "号码",
          ellipsis: true,
          dataIndex: "userCode",
          width: 120,
        },
        {
          title: "短链",
          ellipsis: true,
          dataIndex: "shortUrl",
          scopedSlots: { customRender: "shortUrl" },
          width: 170,
        },
        {
          title: "长链",
          ellipsis: true,
          dataIndex: "longUrl",
          width: 300,
          scopedSlots: { customRender: "longUrl" },
        },
        {
          title: "省份",
          ellipsis: true,
          dataIndex: "province",
          width: 60,
        },
        {
          title: "城市",
          ellipsis: true,
          dataIndex: "city",
          width: 60,
        },
        {
          title: "运营商",
          ellipsis: true,
          dataIndex: "operator",
          width: 80,
        },
        {
          title: "PV",
          ellipsis: true,
          dataIndex: "clickCount",
          scopedSlots: { customRender: "pv" },
          width: 80,
        },
      ],
      dataAnalysis: [],
      paginationAnalysis: {},
      isTime: false,
      sendShortShow: false,
      sendShortName: "",
      checkedTiming: false,
      sendForm: {
        userSmsAccountId: undefined,
        sendCount: 0,
        activityCode: "",
        sign: "",
        signId: undefined,
        time: null,
      },
      userLoginNameList: [],
      textarea: "",
      signList: [],
      shortLinkShow: false,
      paginationIntelligence: {},
      columnsIntelligence: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 80,
        },
        {
          title: "短链名称",
          ellipsis: true,
          dataIndex: "activityCodeLabel",
          width: 300,
        },
        {
          title: "长链",
          ellipsis: true,
          dataIndex: "longUrlLabel",
          scopedSlots: { customRender: "longUrl" },
          customCell: this.mouseoverLongUrl,
          width: 450,
        },
        {
          title: "短链域名",
          ellipsis: true,
          dataIndex: "domain",
          width: 100,
        },
        {
          title: "业务类型",
          ellipsis: true,
          dataIndex: "category",
          width: 80,
        },
        {
          title: "短链数量",
          ellipsis: true,
          dataIndex: "shortUrlCount",
          scopedSlots: { customRender: "shortUrlCount" },
          width: 90,
        },
        {
          title: "总访问PV",
          ellipsis: true,
          dataIndex: "clickCount",
          width: 90,
        },
        {
          title: "独立UV",
          ellipsis: true,
          dataIndex: "uniqueClickCount",
          width: 80,
        },
        {
          title: "IP",
          ellipsis: true,
          dataIndex: "ipCount",
          width: 50,
        },
        {
          title: "添加时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          fixed: "right",
          width: 180,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataIntelligence: [],
      loadingTable: false,
      activityCodeTitle: "",
      activitySubCodeTitle: "",
      selectedRowKeys: [],
      fileList: [],
      name: "",
      longUrl: "https://www.baidu.com/",
      currentPage: 1,
      pageSize: 10,
      shortUrlGroupId: "",
      maxUrlLength: 0,
      operator: "",
      asynTime: null,
      batchDetailListShow: false,
      batchDetailListColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "key",
          width: 60,
        },
        {
          title: "电话",
          ellipsis: true,
          dataIndex: "userCode",
          width: 200,
        },
        {
          title: "省份",
          ellipsis: true,
          dataIndex: "province",
          width: 200,
        },
        {
          title: "城市",
          ellipsis: true,
          dataIndex: "city",
          width: 190,
        },
        {
          title: "运营商",
          ellipsis: true,
          dataIndex: "operator",
          width: 190,
        },
      ],
      shortUrlName: "",
      isRetransmission: false,
      retransmission: false,
      selectedRows: [],
      batchClick: false,
      groupExistShow: false,
      groupExistYesOrNo: false,
      blankNumberCheck: false,
      longUrlGroupList: [],
      longUrlGroupId: undefined,
      category: undefined,
      longUrlGroupIdAdd: undefined,
      active: 0,
      longUrlGroupDetailVisible: false,
      routingType: "",
      defaultLongUrl: "",
      longUrlGroupDetailList: [],
      longUrlGroupDetailColumns: [
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "longUrlName",
          width: 120,
        },
        {
          title: "长链",
          ellipsis: true,
          dataIndex: "longUrl",
          width: 500,
        },
        {
          title: "pv上限",
          ellipsis: true,
          dataIndex: "pvLimit",
          width: 100,
        },
        {
          title: "权重",
          ellipsis: true,
          dataIndex: "weight",
          width: 60,
        },
        {
          title: "启用",
          ellipsis: true,
          dataIndex: "enabled",
          width: 100,
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "优先级",
          ellipsis: true,
          dataIndex: "priority",
          width: 70,
        },
      ],
      expireRuleTypeList: [],
      expireRule: "UN_EXPIRE",
      expireTime: "",
      expireUnit: "秒",
      selectGoryCheck: false,
      contentActive: "1",
      strategyList: [],
      strategyId: undefined,
      isCheckStrategyContentShortUrlTag: true,
      clickCallbackUrlList: [],
      batchDistributeShow: false,
      productName: undefined,
      productList: [],
      stepDate: "",
      stepDateList: [
        { label: "不限制", value: "" },
        {
          label: "当天未使用",
          value: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          label: "近2天未使用",
          value: moment()
            .subtract(1, "days")
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          label: "近3天未使用",
          value: moment()
            .subtract(2, "days")
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
        },
      ],
      longUrlDistributeCount: "",
      longUrlDistributeCountShow: false,
      allowLongUrlDistribute: false,
      batchDistributeDetailShow: false,
      batchDistributeDetailColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "短链名称",
          ellipsis: true,
          dataIndex: "activityCode",
        },
        {
          title: "产品名称",
          ellipsis: true,
          dataIndex: "productName",
        },
        {
          title: "长链",
          ellipsis: true,
          dataIndex: "longUrl",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "上次使用时间",
          ellipsis: true,
          dataIndex: "lastUsedDate",
        },
      ],
      batchDistributeDetailList: [],
      cancelToken: axios.CancelToken.source(),
      copyBatchDistributeDetailListText: "",
      batchModifyExpireRuleShow: false,
      unitsInSeconds: {
        月: 30 * 24 * 60 * 60, // 假设每月按30天算
        天: 24 * 60 * 60,
        小时: 60 * 60,
        分钟: 60,
        秒: 1,
      },
    };
  },
  components: {
    smsInput,
    smsContentTemplate,
  },
  watch: {
    needLongUrlCheck: {
      handler(newVal) {
        this.urlFormat = false;
      },
    },
  },
  computed: {
    expireSeconds() {
      let seconds = "";
      if (this.expireTime) {
        seconds = this.expireTime * 1 * this.unitsInSeconds[this.expireUnit];
      }
      return seconds;
    },
  },
  created() {
    let category = localStorage.getItem("category");
    if (category) {
      this.category = category;
    }
    this.getClickCallbackUrlList();
    this.getLongUrlGroupList();
    this.inquire(1);
    this.getCategoryList();
    this.getStrategyList();
    this.getShortUrlGroupExpireRuleTypeList();
    this.getSourceList();
  },
  methods: {
    moment,
    categoryChange() {
      let category = "";
      if (this.category) {
        category = this.category;
      }
      localStorage.setItem("category", category);
      this.inquire(1);
    },
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        gmtCreatedStart: "",
        gmtCreatedEnd: "",
        activityCode: this.activityCodeTitle,
        longUrl: this.activitySubCodeTitle,
        longUrlGroupId: this.longUrlGroupId,
        category: this.category,
      };
      if (this.intelligenceTime[0]) {
        data.gmtCreatedStart = this.intelligenceTime[0] + " 00:00:00";
        data.gmtCreatedEnd = this.intelligenceTime[1] + " 23:59:59";
      }
      this.loadingTable = true;
      this.$http
        .get("/shortUrl/groupList", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            let ids = "";
            list.forEach((item, index) => {
              item.key = index + 1;
              item.longUrlLabel = item.longUrl;
              item.isCopyShow = false;
              if (item.gmtCreated) {
                item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              } else {
                item.gmtCreated = "";
              }
              item.activityCodeLabel = item.activityCode;
              if (item.status === "CREATING" || item.status === "UPDATING") {
                ids += item.id + ",";
              }
            });
            this.dataIntelligence = list;
            if (this.asynTime) {
              clearInterval(this.asynTime);
              this.asynTime = null;
            }
            if (ids) {
              this.AsyncIntelligence(ids); // 刷新智能短链列表
            }
            this.paginationIntelligence = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: this.pageSize,
              current: this.currentPage,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.currentPage_Intelligence(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    currentPage_Intelligence(index) {
      this.inquire(index);
    },
    callback() {
      this.strategyId = undefined;
      this.isCheckStrategyContentShortUrlTag = true;
    },
    strategyChange(strategyId) {
      this.$http
        .get("/contentStrategy/checkStrategyContentShortUrlTag", { strategyId })
        .then((res) => {
          if (res.result == 200) {
            this.isCheckStrategyContentShortUrlTag = res.data;
            if (!this.isCheckStrategyContentShortUrlTag) {
              this.$message.warning("模板存在不包含短链标签的文本内容");
            }
          }
        });
    },
    getStrategyList(name) {
      this.$http.get("/contentStrategy/strategyList", { name }).then((res) => {
        if (res.result == 200) {
          this.strategyList = res.data;
        }
      });
    },
    smsContentTemplateClick() {
      this.smsContentTemplateShow = true;
    },
    // 关闭使用模版
    smsContentTemplateClose() {
      this.smsContentTemplateShow = false;
    },
    // 获取短信内容
    textarea_sms(val) {
      this.textarea = val;
    },
    // 获取前缀
    prefixChange(val) {
      this.prefix = val;
    },
    getClickCallbackUrlList(name) {
      this.$http
        .get("/shortUrl/getClickCallbackUrlList", { name })
        .then((res) => {
          if (res.result === 200) {
            this.clickCallbackUrlList = res.data;
          }
        });
    },
    // 获取业务类型
    getCategoryList() {
      this.$http.get("/shortUrl/getCategoryList").then((res) => {
        if (res.result === 200) {
          this.categoryList = res.data;
        }
      });
    },
    getShortUrlGroupExpireRuleTypeList() {
      this.$http
        .get("/shortUrl/shortUrlGroupExpireRuleTypeList")
        .then((res) => {
          if (res.result === 200) {
            this.expireRuleTypeList = res.data;
          }
        });
    },
    // 获取数据来源
    getSourceList() {
      this.$http.get("/shortUrl/getSourceList").then((res) => {
        if (res.result === 200) {
          this.sourceList = res.data;
        }
      });
    },
    preciseChange(val) {
      this.isPreciseCheck = val;
      if (!val) {
        this.operatorList = [];
      }
    },
    // 选择表格数据
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 刷新智能短链列表
    AsyncIntelligence(ids) {
      if (this.asynTime) {
        clearInterval(this.asynTime);
        this.asynTime = null;
      }
      this.asynTime = setInterval(() => {
        let data = {
          ids: ids,
        };
        this.groupDetail(data);
      }, 1000);
    },
    // 请求刷新智能短链列表
    groupDetail(data) {
      this.$http.get("/shortUrl/groupDetail", data).then((res) => {
        if (res.result === 200) {
          let param = res.data;
          for (let i in param) {
            this.dataIntelligence.forEach((item) => {
              if (item.id == i) {
                if (param[i].status === "NORMAL") {
                  clearInterval(this.asynTime);
                  this.asynTime = null;
                  this.inquire(1);
                }
              }
            });
          }
        }
      });
    },
    // 不可选日期
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    // 选择时间
    onChangeIntelligenceTime(dates, dateStrings) {
      this.intelligenceTime = [dateStrings[0], dateStrings[1]];
    },
    shortLinkClick() {
      let selectGory = localStorage.getItem("selectGory");
      if (selectGory) {
        this.selectGoryCheck = true;
        this.selectGory = selectGory;
      } else {
        this.selectGoryCheck = false;
        this.selectGory = "游戏";
      }
      this.clickCallbackUrlIdList.splice(0);
      this.getLongUrlGroupList();
      this.shortModalTitle = "添加短链接";
      this.getDomainList();
      this.shortLinkShow = true;
    },
    selectGoryCheckChange() {
      if (this.selectGoryCheck) {
        localStorage.setItem("selectGory", this.selectGory);
      } else {
        localStorage.removeItem("selectGory");
      }
    },
    getDomainList() {
      this.selectDomainList = [];
      this.$http.get("/shortUrl/domainList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.selectDomainList.push({
              value: item,
              label: item,
            });
          });
        }
      });
    },
    batchClickSend() {
      this.batchClick = true;
      this.isRetransmission = false;
      this.retransmission = false;
      this.selectedRows.forEach((item) => {
        this.getMsgPlanList(item.id);
        if (item.maxUrlLength > this.maxUrlLength) {
          this.maxUrlLength = item.maxUrlLength;
        }
      });
      this.getUserSmsAccount(); //获取账号
      this.getSignList(); //获取签名
      this.sendShortName = "批量";
      setTimeout(() => {
        this.sendShortShow = true;
        this.$nextTick(() => {
          this.prefix = "none";
          let textarea = this.textarea;
          this.textarea = textarea + "#短链#";
        });
      }, 500);
    },
    getUserSmsAccount(accessKeyOrName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        querySingleUser: true,
        accessKeyOrName,
      };
      this.$http
        .get("/userPlatformRelation/list", data)
        .then((res) => {
          if (res.result === 200) {
            let list = res.data.records;
            this.userLoginNameList = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    getSignList(val) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        userSmsAccountId: val,
      };
      this.$http.get("/sms/userSignInfo/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          let signList = [];
          list.forEach((item) => {
            if (item.sign) {
              signList.push({
                sign: "【" + item.sign.trim() + "】",
                label: item.sign.trim(),
                signId: item.id,
              });
            } else {
              signList.push({
                sign: "【" + item.sign + "】",
                label: item.sign,
                signId: item.id,
              });
            }
          });
          this.signList = signList;
        }
      });
    },
    batchDistribute() {
      this.getProductList();
      this.productName = undefined;
      this.stepDate = "";
      this.longUrlDistributeCountShow = false;
      this.batchDistributeShow = true;
    },
    getLongUrlDistributeCount() {
      if (!this.productName) {
        return;
      }
      let data = {
        productName: this.productName,
        stepDate: this.stepDate,
      };
      this.$http
        .json_post("/shortUrl/longUrlDistributeCount", data)
        .then((res) => {
          if (res.result == 200) {
            this.longUrlDistributeCount = res.data;
            this.longUrlDistributeCountShow = true;
          }
        });
    },
    batchDistributeSubmit() {
      if (!this.productName) {
        this.$message.warning("请选择产品名称");
        return;
      }
      this.selectedRows = this.selectedRows.sort((a, b) => {
        return this.wordSort(a.activityCode, b.activityCode);
      });
      this.batchDistributeShow = false;
      this.allowLongUrlDistribute = true;
      this.batchDistributeDetailList.splice(0);
      this.batchDistributeDetailShow = true;

      let groupIdList = this.selectedRows.map((item) => {
        return item.id;
      });
      let data = {
        groupIds: groupIdList.join(","),
      };
      this.$http
        .json_post("/shortUrl/longUrlDistributeLock", data)
        .then((res) => {
          if (res.result == 200) {
            const { token } = this.cancelToken;
            this.longUrlDistribute(this.selectedRows[0], 0, token);
          }
        });
    },
    wordSort(a, b) {
      let reg = /[a-zA-Z0-9]/;
      if (reg.test(a) || reg.test(b)) {
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        } else {
          return 0;
        }
      } else {
        return b.localeCompare(a);
      }
    },
    longUrlDistribute(item, index, token) {
      if (!item.longUrl) {
        this.$message.warning(item.id + "长链分组类型不可匹配");
        this.next(index, token);
        return;
      }
      if (item.status !== "NORMAL") {
        this.$message.warning(item.id + "状态不是正常状态，不可分配");
        this.next(index, token);
        return;
      }
      let obj = {
        id: item.id,
        activityCode: item.activityCode,
        productName: this.productName,
        longUrl: "",
        status: "匹配中",
        lastUsedDate: "",
      };
      this.batchDistributeDetailList.push(obj);

      let clickCallbackUrl = [];
      if (item.clickCallbackUrlIdList) {
        item.clickCallbackUrlIdList.forEach((item) => {
          this.clickCallbackUrlList.forEach((urlItem) => {
            if (urlItem.id == item) {
              clickCallbackUrl.push({ id: urlItem.id, url: urlItem.url });
            }
          });
        });
      }
      let data = {
        groupId: item.id,
        activityCode: item.activityCode,
        longUrl: item.longUrl,
        productName: this.productName,
        stepDate: this.stepDate,
        category: item.category,
        domain: item.domain,
        clickCallbackUrl,
      };
      this.$http
        .json_post("/shortUrl/longUrlDistribute", data, token)
        .then((res) => {
          if (res.result == 200) {
            this.$set(
              this.batchDistributeDetailList[
                this.batchDistributeDetailList.length - 1
              ],
              "longUrl",
              res.data ? res.data.longUrl : ""
            );

            this.$set(
              this.batchDistributeDetailList[
                this.batchDistributeDetailList.length - 1
              ],
              "status",
              res.data ? "成功" : "失败"
            );

            this.$set(
              this.batchDistributeDetailList[
                this.batchDistributeDetailList.length - 1
              ],
              "lastUsedDate",
              res.data ? res.data.lastUsedDate : ""
            );
            this.next(index, token);
          }
        })
        .catch((err) => {
          this.cancelLongUrlDistribute();
        });
    },
    next(index, token) {
      if (index < this.selectedRows.length - 1) {
        this.longUrlDistribute(this.selectedRows[index + 1], index + 1, token);
      } else {
        this.cancelLongUrlDistribute();
      }
    },
    cancelLongUrlDistribute() {
      this.cancelToken.cancel();
      this.allowLongUrlDistribute = false;
      this.copyBatchDistributeDetailListText = this.batchDistributeDetailList
        .map((item) => item.activityCode + "\t" + item.longUrl)
        .join("\n");
    },
    batchDistributeDetailClose() {
      this.selectedRowKeys.splice(0);
      this.selectedRows.splice(0);
      this.cancelToken = axios.CancelToken.source();
    },
    getProductList() {
      this.$http.get("/shortUrl/productList").then((res) => {
        if (res.result === 200) {
          this.productList = res.data;
        }
      });
    },
    analysisClick(val) {
      if (val.longUrlGroupId) {
        this.columnsAnalysis.splice(2, 1);
      }
      this.analysisShow = true;
      this.groupId = val.id;
      this.sendShortName = "【" + val.activityCode + "】";
      this.shortUrlName = val.activityCodeLabel;
      this.analysisPageNo = 1;
      let data = {
        groupId: val.id,
        pageNo: 1,
        pageSize: 10,
      };
      this.getGroupDetailList(data);
    },
    getGroupDetailList(data) {
      this.$http.get("/shortUrl/groupDetailList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.key = index + 1;
          });
          this.paginationAnalysis = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_Analysis(current),
          };
          this.dataAnalysis = list;
        }
      });
    },
    currentPage_Analysis(index) {
      this.analysisPageNo = index;
      let data = {
        groupId: this.groupId || this.shortUrlGroupId,
        pageNo: index,
        pageSize: 10,
        operator: this.operator,
      };
      this.getGroupDetailList(data);
    },
    // 刷新分析数据
    inquireAnalysis() {
      let data = {
        groupId: this.groupId || this.shortUrlGroupId,
        pageNo: this.analysisPageNo,
        pageSize: 10,
        operator: this.operator,
      };
      this.getGroupDetailList(data);
    },
    // 关闭分析
    analysisClose() {
      this.dataAnalysis = [];
      this.columnsAnalysis = [
        {
          title: "号码",
          ellipsis: true,
          dataIndex: "userCode",
        },
        {
          title: "短链",
          ellipsis: true,
          dataIndex: "shortUrl",
          scopedSlots: { customRender: "shortUrl" },
        },
        {
          title: "长链",
          ellipsis: true,
          dataIndex: "longUrl",
          width: 200,
          scopedSlots: { customRender: "longUrl" },
        },
        {
          title: "省份",
          ellipsis: true,
          dataIndex: "province",
        },
        {
          title: "城市",
          ellipsis: true,
          dataIndex: "city",
        },
        {
          title: "运营商",
          ellipsis: true,
          dataIndex: "operator",
        },
        {
          title: "PV",
          ellipsis: true,
          dataIndex: "clickCount",
          scopedSlots: { customRender: "pv" },
        },
      ];
    },
    clickSend(val) {
      if (val.longUrl) {
        if (val.longUrl.indexOf("baidu.com") !== -1) {
          this.$confirm({
            title: "当前长链点击将跳转到百度,是否继续发送?",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
              this.clickSendConfirm(val);
            },
            onCancel() {},
          });
        } else {
          this.clickSendConfirm(val);
        }
      } else {
        this.clickSendConfirm(val);
      }
    },
    clickSendConfirm(val) {
      this.randomNum = this.$common.randomStr(false, 12);
      this.pooiForm = {
        validNumber: val.shortUrlCount,
        moveNumber: val.cmccCount,
        unicomNumber: val.cmcuCount,
        telecomNumber: val.cmctCount,
      };
      this.isRetransmission = false;
      this.retransmission = false;
      this.longUrl = val.longUrl;
      this.maxUrlLength = val.maxUrlLength;
      this.sendForm.activityCode = val.activityCode;
      this.shortUrlGroupId = val.id;
      this.sendShortShow = true;
      this.sendShortName = "【" + val.activityCode + "】";
      this.sendForm.sendCount = val.shortUrlCount;
      this.getUserSmsAccount(); //获取账号
      this.getSignList(); //获取签名
      this.getMsgPlanList(val.id);
      this.$nextTick(() => {
        this.prefix = "none";
        let textarea = this.textarea;
        this.textarea = textarea + "#短链#";
      });
    },
    batchModifyExpireRule() {
      this.batchModifyExpireRuleShow = true;
      this.modifyLongUrlClick();
    },
    convertFromSeconds(expireSeconds) {
      if (expireSeconds < 0) {
        return { expireTime: expireSeconds, expireUnit: "秒" };
      }
      for (const expireUnit in this.unitsInSeconds) {
        if (expireSeconds % this.unitsInSeconds[expireUnit] === 0) {
          const expireTime = expireSeconds / this.unitsInSeconds[expireUnit];
          return { expireTime, expireUnit };
        }
      }
    },
    modifyLongUrlClick(val) {
      this.getDomainList();
      if (val) {
        this.groupId = val.id;
        this.shortModalTitle = "修改长链";
        this.name = val.activityCode;
        if (val.expireRule) {
          this.expireRule = val.expireRule;
          this.expireTime = this.convertFromSeconds(
            val.expireSeconds
          ).expireTime;
          this.expireUnit = this.convertFromSeconds(
            val.expireSeconds
          ).expireUnit;
        } else {
          this.expireRule = "UN_EXPIRE";
          this.expireTime = "";
          this.expireUnit = "秒";
        }
        if (val.longUrlGroupId) {
          this.active = 1;
          this.longUrlGroupIdAdd = val.longUrlGroupId;
        } else {
          this.active = 0;
          this.longUrl = val.longUrl;
        }
        this.selectGory = val.category;
        this.selectDomain = val.domain;
        this.clickCallbackUrlIdList.splice(0);
        if (val.clickCallbackUrlIdList) {
          this.clickCallbackUrlIdList.push(...val.clickCallbackUrlIdList);
        }
      } else {
        this.shortModalTitle = "批量修改过期策略";
      }
      this.blankNumberCheck = false;
      this.shortLinkShow = true;
    },
    // 点击开启
    openShort(val) {
      this.$confirm({
        title: "确定开启",
        content: (h) => (
          <div style="color:red;">
            确定开启【{val.activityCodeLabel}】短链？
          </div>
        ),
        onOk: () => {
          this.$http
            .get("/shortUrl/openShortUrlGroup", { groupId: val.id })
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("开启成功");
                this.inquire();
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
    // 点击关闭
    closeShort(val) {
      this.$confirm({
        title: "确定关闭",
        content: (h) => (
          <div style="color:red;">
            确定关闭【{val.activityCodeLabel}】短链？
          </div>
        ),
        onOk: () => {
          this.$http
            .get("/shortUrl/closeShortUrlGroup", { groupId: val.id })
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("关闭成功");
                this.inquire();
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
    // 点击终止
    endShort(val) {
      this.$confirm({
        title: "确定终止",
        content: (h) => (
          <div style="color:red;">
            确定终止【{val.activityCodeLabel}】短链？
          </div>
        ),
        onOk: () => {
          this.$http
            .get("/shortUrl/terminatedShortUrlGroup", { groupId: val.id })
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("终止成功");
                this.inquire();
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
    // 点击删除
    deleteShort(val) {
      this.$confirm({
        title: "确定删除",
        content: (h) => (
          <div style="color:red;">
            确定删除【{val.activityCodeLabel}】短链？
          </div>
        ),
        onOk: () => {
          this.$http
            .get("/shortUrl/deleteShortUrlGroup", { groupId: val.id })
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.inquire();
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
    groupExist() {
      this.$http
        .get("/shortUrl/groupExist", { activityCode: this.name })
        .then((res) => {
          if (res.result == 200) {
            this.groupExistYesOrNo = res.data;
            this.groupExistShow = true;
          }
        });
    },
    compareToLongUrl() {
      if (this.longUrl) {
        let httpIndex = this.longUrl.indexOf("http://");
        let httpsIndex = this.longUrl.indexOf("https://");
        if (httpIndex === 0 || httpsIndex === 0) {
          this.urlFormat = false;
          if (this.needLongUrlCheck) {
            this.$http
              .get("/shortUrl/checkLongUrl", { longUrl: this.longUrl })
              .then((res) => {
                if (res.result == 200) {
                  this.urlFormat = true;
                  this.urlFormatText = "长链未匹配到任何模板";
                  this.longUrlExistYesOrNo = res.data.pass;
                  this.templateName = res.data.templateName;
                }
              });
          }
        } else {
          this.urlFormatText = "长网址链接格式不正确";
          this.urlFormat = true;
          this.longUrlExistYesOrNo = false;
        }
      }
    },
    batchModifyExpireRuleSubmit() {
      if (!this.expireSeconds && this.expireRule !== "UN_EXPIRE") {
        this.$message.warning("请输入过期时间");
        return;
      }

      this.syncLoading = this.$syncLoading({
        message: "长链修改中...",
      });

      this.$axios
        .all(
          this.selectedRows.map((item) => {
            let data = {
              groupId: item.id,
              activityCode: item.activityCode,
              domain: item.domain,
              clickCallbackUrl: [],
              blankNumberCheck: this.blankNumberCheck,
              expireRule: this.expireRule,
              category: item.category,
              newUrl: item.longUrl,
              longUrlGroupId: item.longUrlGroupId,
            };
            if (item.clickCallbackUrlIdList) {
              item.clickCallbackUrlIdList.forEach((item) => {
                this.clickCallbackUrlList.forEach((urlItem) => {
                  if (urlItem.id == item) {
                    data.clickCallbackUrl.push({
                      id: urlItem.id,
                      url: urlItem.url,
                    });
                  }
                });
              });
            }

            if (this.expireRule !== "UN_EXPIRE") {
              data.expireSeconds = this.expireSeconds;
            }
            return this.$http.json_post("/shortUrl/modifyLongUrl", data);
          })
        )
        .then((resArr) => {
          this.syncLoading.close();
          this.shortLinkShow = false;
          this.batchModifyExpireRuleShow = false;
          this.$message.success("修改成功");
          this.selectedRowKeys.splice(0);
          this.selectedRows.splice(0);
          this.inquire(1);
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    shortLink_submit() {
      if (!this.name) {
        this.$message.warning("请输入短链名称");
        return;
      }
      if (this.name.length > 128) {
        this.$message.warning("短链名称不能大于128个字符");
        return;
      }
      if (!this.longUrl && this.active == 0) {
        this.$message.warning("请输入长网址链接");
        return;
      }
      if (!this.longUrlGroupIdAdd && this.active == 1) {
        this.$message.warning("请选择长链分组");
        return;
      }
      if (!this.selectDomain) {
        this.$message.warning("请选择域名");
        return;
      }
      if (!this.expireSeconds && this.expireRule !== "UN_EXPIRE") {
        this.$message.warning("请输入过期时间");
        return;
      }
      if (!this.selectGory) {
        this.$message.warning("请选择业务类型");
        return;
      }

      let clickCallbackUrl = [];
      this.clickCallbackUrlIdList.forEach((item) => {
        this.clickCallbackUrlList.forEach((urlItem) => {
          if (urlItem.id == item) {
            clickCallbackUrl.push({ id: urlItem.id, url: urlItem.url });
          }
        });
      });
      if (this.shortModalTitle == "添加短链接") {
        if (!this.groupExistShow) {
          this.groupExist();
          return;
        }
        if (!this.urlFormat) {
          if (this.needLongUrlCheck) {
            this.compareToLongUrl();
            return;
          }
        } else if (!this.longUrlExistYesOrNo) {
          this.$message.warning("请修改长链格式");
          return;
        }
        if (this.fileList.length < 1) {
          this.$message.warning("请上传标识文件");
          return;
        }

        this.shortLinkDisabled = true;
        // 新增智能短链
        const { fileList } = this;
        const formData = new FormData();
        fileList.forEach((file) => {
          formData.append("file", file);
        });
        formData.append("activityCode", this.name);
        if (this.active == 0) {
          formData.append("longUrl", this.longUrl);
        } else {
          formData.append("longUrlGroupId", this.longUrlGroupIdAdd);
        }
        formData.append("domain", this.selectDomain);
        formData.append("category", this.selectGory);
        formData.append("source", this.source);
        formData.append("expireRule", this.expireRule);
        if (this.expireRule !== "UN_EXPIRE") {
          formData.append("expireSeconds", this.expireSeconds);
        }
        formData.append("clickCallbackUrl", JSON.stringify(clickCallbackUrl));
        formData.append("blankNumberCheck", this.blankNumberCheck);
        reqwest({
          url: this.$path + "/shortUrl/addGroup",
          method: "post",
          processData: false,
          crossOrigin: true,
          withCredentials: true,
          data: formData,
          success: () => {
            this.fileList = [];
            this.shortLinkShow = false;
            this.$message.success("新增成功！");
            this.inquire(1);
            this.shortLinkDisabled = false;
          },
          error: (res) => {
            this.shortLinkShow = false;
            this.$message.error(JSON.parse(res.response).message);
            this.shortLinkDisabled = false;
          },
        });
      } else {
        let data = {
          groupId: this.groupId,
          activityCode: this.name,
          domain: this.selectDomain,
          clickCallbackUrl: clickCallbackUrl,
          blankNumberCheck: this.blankNumberCheck,
          expireRule: this.expireRule,
          category: this.selectGory,
        };
        if (this.expireRule !== "UN_EXPIRE") {
          data.expireSeconds = this.expireSeconds;
        }
        if (this.active == 0) {
          data.newUrl = this.longUrl;
        } else {
          data.longUrlGroupId = this.longUrlGroupIdAdd;
        }
        this.syncLoading = this.$syncLoading({
          message: "长链修改中...",
        });
        this.$http
          .json_post("/shortUrl/modifyLongUrl", data)
          .then((res) => {
            this.syncLoading.close();
            if (res.result === 200) {
              this.shortLinkShow = false;
              this.$message.success("修改成功！");
              this.inquire(1);
            }
          })
          .catch((err) => {
            this.syncLoading.close();
          });
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 点击导出
    exportClick() {
      let href =
        this.$path +
        "/shortUrl/export/excel?groupId=" +
        this.groupId +
        "&shortUrlName=" +
        this.shortUrlName;
      window.location.href = href;
    },
    // 鼠标移入长链列--智能短链
    mouseoverLongUrl(record, rowIndex) {
      return {
        on: {
          // 事件
          click: (event) => {}, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {
            // 鼠标移入行
            this.dataIntelligence.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.dataIntelligence[rowIndex], "isCopyShow", true);
              }
            });
          },
          mouseleave: (event) => {
            // 鼠标移出行
            this.dataIntelligence.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.dataIntelligence[rowIndex], "isCopyShow", false);
              }
            });
          },
        },
      };
    },
    // 点击批次详情
    detailsClick(operator) {
      this.batchDetailListShow = true;
      if (!operator) {
        this.operator = "";
      } else {
        this.operator = operator;
      }
      this.analysisPageNo = 1;
      let data = {
        groupId: this.shortUrlGroupId,
        operator: this.operator,
        pageNo: 1,
        pageSize: 10,
      };
      this.getGroupDetailList(data);
    },
    // 关闭批次详情
    batchDetailListClose() {
      this.dataAnalysis = [];
      this.operator = "";
    },
    // 复制成功
    onCopy(e) {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError(e) {
      this.$message.error("复制失败");
    },
    clickPv(val) {
      this.visiblePv = true;
      this.shortCode = val.shortCode;
      let data = {
        shortCode: this.shortCode,
        pageNo: 1,
        pageSize: 10,
      };
      this.getShortUrlClickDetailList(data);
    },
    // 关闭pv
    onClosePv() {
      this.visiblePv = false;
      this.dataPv = [];
    },
    // 查询访问详情
    getShortUrlClickDetailList(data) {
      this.$http.get("/shortUrl/shortUrlClickDetailList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.data;
          list.forEach((item, index) => {
            item.key = index + 1;
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
          });
          this.paginationPv = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_Pv(current),
          };
          this.dataPv = list;
        }
      });
    },
    currentPage_Pv(index) {
      let data = {
        shortCode: this.shortCode,
        pageNo: index,
        pageSize: 10,
      };
      this.getShortUrlClickDetailList(data);
    },
    // 查询短信发送记录
    getMsgPlanList(id) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        shortUrlGroupId: id,
        smsPlanType: "BATCH_SEND",
      };
      this.$http.get("/sms/msgPlanList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          if (list && list.length > 0) {
            this.isRetransmission = true;
          }
        }
      });
    },
    async batchClickSend_submit(data) {
      for (let index = 0; index < this.selectedRows.length; index++) {
        let item = this.selectedRows[index];
        data.activityCode = item.activityCode;
        data.longUrl = item.longUrl;
        data.shortUrlGroupId = item.id;
        data.token = this.$common.randomStr(false, 12);
        await this.$http.json_post("/sms/addMsgPlanForShortUrlGroup", data);
      }
    },
    // 确定发送短信
    sendShort_submit() {
      let index = this.textarea.indexOf("#短链#");
      let lastIndex = this.textarea.lastIndexOf("#短链#");
      let indexNum = this.textarea.indexOf("#尾号#");
      let lastIndexNum = this.textarea.lastIndexOf("#尾号#");
      if (this.contentActive == "1") {
        if (index !== lastIndex) {
          this.$message.warning("内容不能有多个短链！");
          return;
        }
        if (indexNum !== lastIndexNum) {
          this.$message.warning("内容不能有多个尾号！");
          return;
        }
      } else {
        if (!this.strategyId) {
          this.$message.warning("模板不能为空");
          return;
        }
      }
      let content = this.sendForm.sign + this.textarea;
      let data = {
        signId: this.sendForm.signId,
        content: content,
        userSmsAccountId: this.sendForm.userSmsAccountId,
        operatorList: this.operatorList,
      };
      if (this.contentActive == "2") {
        data.strategyId = this.strategyId;
      }
      if (index !== -1) {
        data.prefix = this.prefix;
      }
      if (this.checkedTiming) {
        data.sendTime = this.$common.transformTime(this.sendForm.time);
      }
      this.sendLoading = true;
      if (this.batchClick) {
        this.batchClickSend_submit(data).then(() => {
          this.$message.success("提交审核成功！");
          this.sendShortShow = false;
          this.sendLoading = false;
        });
      } else {
        data.activityCode = this.sendForm.activityCode;
        data.longUrl = this.longUrl;
        data.shortUrlGroupId = this.shortUrlGroupId;
        data.token = this.randomNum;
        this.$http
          .json_post("/sms/addMsgPlanForShortUrlGroup", data)
          .then((res) => {
            if (res.result === 200) {
              this.$message.success("提交审核成功！");
              this.sendShortShow = false;
              this.sendLoading = false;
            }
          });
      }
    },
    // 关闭发送短信
    sendShortClose() {
      this.batchClick = false;
      this.sendLoading = false;
      this.isPrecise = false;
      this.isPreciseCheck = false;
      this.operatorList = [];
      this.maxUrlLength = 0;
      this.textarea = "";
      this.strategyId = undefined;
      this.contentActive = "1";
      this.checkedTiming = false;
      this.prefix = undefined;
      this.sendForm = {
        sendCount: 0,
        activityCode: "",
        sign: "",
        platform: "",
        signId: undefined,
        time: null,
      };
      this.pooiForm = {
        validNumber: 0,
        moveNumber: 0,
        unicomNumber: 0,
        telecomNumber: 0,
      };
    },
    // 账号下拉框
    userSmsChange(val) {
      this.getSignList(val);
    },
    // 选择签名
    sighChange(signId) {
      this.sendForm.signId = signId;
      this.signList.forEach((item) => {
        if (item.signId === signId) {
          this.sendForm.sign = item.sign;
        }
      });
    },
    // 关闭新增短链接
    shortLinkClose() {
      this.name = "";
      this.selectDomain = undefined;
      this.longUrl = "https://www.baidu.com/";
      this.fileList = [];
      this.urlFormat = false;
      this.needLongUrlCheck = true;
      this.shortLinkDisabled = false;
      this.longUrlGroupIdAdd = undefined;
      this.active = 0;
      this.source = "未知";
      this.selectGory = "游戏";
      this.expireRule = "UN_EXPIRE";
      this.expireTime = "";
      this.expireUnit = "秒";
      this.groupExistShow = false;
      this.groupExistYesOrNo = false;
      this.batchModifyExpireRuleShow = false;
    },
    // 删除上传文件
    handleRemoveMale(file) {
      let index = this.fileList.indexOf(file);
      let newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    // 上传文件之前的钩子
    beforeUploadMale(file) {
      this.fileList = [...this.fileList, file];
      let name = this.fileList[0].name;
      let index = name.lastIndexOf(".");
      this.name = name.substring(0, index);
      return false;
    },
    userSearch(val) {
      this.getUserSmsAccount(val);
    },
    getLongUrlGroupList(name) {
      this.$http.get("/longUrlGroup/longUrlGroupList", { name }).then((res) => {
        if (res.result === 200) {
          this.longUrlGroupList = res.data;
        }
      });
    },
    getLongUrlGroupDetail(groupId) {
      this.$http
        .get("/longUrlGroup/longUrlGroupDetail", { groupId })
        .then((res) => {
          if (res.result === 200) {
            this.longUrlGroupDetailList = res.data.longUrlGroupItemList;
            this.routingType = res.data.routingType;
            this.defaultLongUrl = res.data.defaultLongUrl;
            this.longUrlGroupDetailVisible = true;
          }
        });
    },
  },
  beforeDestroy() {
    if (this.asynTime) {
      clearInterval(this.asynTime);
      this.asynTime = null;
    }
  },
};
</script>

<style>
.ant-tooltip {
  max-width: 1000px !important;
  font-size: 12px !important;
}
a[disabled="disabled"] {
  color: rgba(0, 0, 0, 0.25) !important;
}
</style>
<style scoped lang="scss">
::v-deep .ant-table {
  border: none !important;
  table {
    border: 1px solid #e8e8e8 !important;
    .ant-table-tbody {
      td {
        border: none !important;
      }
    }
  }
}
::v-deep .resize-table-th {
  position: relative;
  .table-draggable-handle {
    transform: none !important;
    position: absolute;
    height: 100% !important;
    bottom: 0;
    left: auto !important;
    right: -5px;
    cursor: col-resize;
    touch-action: none;
  }
}
.smsContentTemplate-c {
  width: 100%;
  text-align: right;
}
.modify-long-click {
  color: #f56c6c;
}
.textarea-content {
  .content-item {
    display: flex;
    justify-content: space-between;
  }
}
.shortLinks-button {
  margin-bottom: 10px;
  .pv-search {
    display: inline-block;
    input {
      width: 115px;
      margin-right: 10px;
    }
    span {
      width: 115px;
      margin-right: 15px;
    }
  }
  button {
    margin-right: 15px;
  }
}
.copy-class {
  color: #52c41a;
}
.number-pool {
  width: 100%;
  height: 100px;
  background: #f8f9f9;
  border: 1px solid #f2f2f2;
  padding: 16px;
  margin: 0 auto;
  display: flex;
  text-align: center;
  justify-content: space-around;
  position: relative;
  .number-pool-item {
    color: #848a9f;
    .number {
      font-size: 22px;
      line-height: 50px;
    }
  }
}
.contentLR {
  display: flex;
}
.left {
  width: 680px;
}
.right {
  margin-top: 115px;
  margin-left: 50px;
  width: 240px;
  height: 450px;
  background: url("../assets/phone.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 40px 20px;
  .bubble {
    padding: 10px;
    width: 200px;
    border-radius: 10px;
    background-color: #f0f0f0;
    position: relative;
    overflow-wrap: break-word;
  }
  .bubble::before {
    position: absolute;
    top: 40%;
    left: -20px;
    content: "";
    width: 0;
    height: 0;
  }
}
.operatorList-checkbox {
  margin-left: 15px;
}
.shortLinks {
  .btn-cao {
    margin-bottom: 20px;
    .cx-cao {
      margin-bottom: 20px;
      .cx-cao-item {
        display: inline-block;
        margin-right: 20px;
        span {
          width: 250px;
        }
        input {
          width: 250px;
        }
      }
    }
  }
}
.actionSub-class {
  .s-url-input {
    position: relative;
    .s-url-a {
      position: absolute;
      left: 325px;
      top: 0px;
      width: 60px;
    }
  }
  .select-tag {
    width: 250px;
  }
  .template-sign {
    position: absolute;
    bottom: -4px;
    left: 0px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
  .template {
    position: absolute;
    bottom: -4px;
    left: 70px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
    // color: #3a8ee6;
  }
  .template2 {
    position: absolute;
    bottom: -4px;
    left: 120px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
    // color: #3a8ee6;
  }
  .template3 {
    position: absolute;
    bottom: -4px;
    left: 170px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
    // color: #3a8ee6;
  }
  .word {
    position: relative;
    display: block;
    width: 100%;
    line-height: 40px;
    text-align: right;
    font-style: normal;
    font-size: 12px;
  }
  .word i {
    color: red;
    font-style: normal;
  }
  .dropbox-ts {
    font-size: 12px;
    color: #999;
    display: block;
    line-height: 16px;
  }
}
.card {
  color: black;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e4e7ed;
  display: inline-block;
  line-height: 14px;
  cursor: pointer;
}
.card:first-child {
  border-right: none;
}
.checked {
  color: #409eff;
}
</style>
