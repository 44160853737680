<template>
  <div class="smsStatusInfo">
    <div class="smsStatusInfo-search">
      <div class="smsStatusInfo-search-1">
        accessKey:
        <a-input
          v-model="accessKeySearch"
          placeholder="请输入accessKey"
          allowClear
        />
      </div>
      <div class="smsStatusInfo-search-1">
        账号名称:
        <a-input v-model="fuzzyName" placeholder="请输入账号名称" allowClear />
      </div>
      <div class="smsStatusInfo-search-1">
        用户名:
        <a-input
          v-model="userLoginName"
          placeholder="请输入用户名"
          allowClear
        />
      </div>
    </div>
    <div class="smsStatusInfo-search-button">
      <a-button type="primary" @click="inquire(1)">查 询</a-button>
      <a-button type="primary" @click="addUser">新 增</a-button>
    </div>
    <!-- 用户列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(userColumns)"
      :columns="userColumns"
      :dataSource="userTableData"
      :pagination="userPagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="msgPlatformName" slot-scope="text, record">
        {{
          record.routingType !== "COMBINATION_ROUTE"
            ? record.platformName + "(" + text + ")"
            : "-----------"
        }}
      </span>
      <span slot="enabled" slot-scope="text, record" style="width: 100%">
        <a-popconfirm
          title="客户端链接会被立即断开， 是否确定?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="switchOnChange(record)"
          v-if="text && record.platformType !== 'HTTP'"
        >
          <a-switch
            checked-children="已启用"
            un-checked-children="已禁用"
            v-model="text"
          />
        </a-popconfirm>
        <a-switch
          v-if="!text || record.platformType == 'HTTP'"
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="text"
          @change="switchOnChange(record)"
        />
      </span>
      <span slot="autoAudit" slot-scope="text, record" style="width: 100%">
        <a-popconfirm
          title="确定要进行免审操作吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="autoOnChange(record)"
        >
          <a-switch
            checked-children="已开启"
            un-checked-children="已关闭"
            v-model="text"
          />
        </a-popconfirm>
      </span>
      <span slot="platformType" slot-scope="text, record">
        <a-tag color="green"> {{ text }} </a-tag>
      </span>
      <span slot="routingLabel" slot-scope="text, record">
        <a-tag color="green"> {{ text }} </a-tag>
      </span>
      <span slot="connectionNum" slot-scope="text, record">
        {{ text }}/{{ record.cmppMaxChannelCount }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a
          href="javascript:;"
          @click="routerClick(record)"
          :disabled="record.routingLabel == '单条线路'"
          >路由</a
        >
        <a-divider type="vertical" />
        <a href="javascript:;" @click="copyAccount(record)" v-copy="copyInfo"
          >复制账号信息</a
        >
        <a-divider type="vertical" />
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="mockConfig">
              <a href="javascript:;" @click="simulateClick(record)">模拟策略</a>
            </a-menu-item>
            <a-menu-item v-if="record.platformType == 'HTTP'">
              <a href="javascript:;" @click="modifyHttpCallbackUrl(record)"
                >设置独立回调地址</a
              >
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="modifyReplyCallbackUrl(record)"
                >设置上行转发地址</a
              >
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="modifyDelayLevel(record)"
                >设置延迟回调</a
              >
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="modifyBlackListSource(record)"
                >设置黑名单来源</a
              >
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="checkConnected(record)"
                >连通性检查</a
              >
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                @click="deleteClick(record)"
                style="color: red"
                >删除</a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <!-- 路由 -->
    <a-modal
      title="路由"
      v-model="routerShow"
      :maskClosable="false"
      class="router-class"
      width="1400px"
    >
      <a-button type="primary" @click="routerAdd" style="margin-bottom: 20px"
        >新增路由</a-button
      >
      <a-table
        :columns="routerColumns"
        :dataSource="routerTableData"
        :pagination="false"
        size="small"
        :customRow="customRow"
        :rowKey="(record) => record.id"
        :scroll="{ x: true }"
      >
        <span slot="signSet" slot-scope="text, record">
          <a-select
            v-model="record.signSet"
            placeholder="签名"
            class="select-tag"
            mode="multiple"
            style="width: 160px"
          >
            <a-select-option
              v-for="i in signList"
              :key="i.id"
              :value="i.sign"
              >{{ i.sign }}</a-select-option
            >
          </a-select>
        </span>
        <span slot="operatorSet" slot-scope="text, record">
          <a-select
            v-model="record.operatorSet"
            placeholder="运营商"
            class="select-tag"
            mode="multiple"
            style="width: 160px"
          >
            <a-select-option
              v-for="i in operatorSetList"
              :key="i.key"
              :value="i.value"
              >{{ i.value }}</a-select-option
            >
          </a-select>
        </span>
        <span slot="forbiddenArea" slot-scope="text, record, index">
          <el-cascader
            v-model="record.forbiddenArea"
            :options="options"
            :props="props"
            filterable
            :show-all-levels="false"
            clearable
            collapse-tags
            size="small"
            style="width: 160px"
          ></el-cascader>
          <a-tooltip placement="topLeft">
            <div slot="title">快速输入</div>
            <a-button
              type="link"
              @click.stop="fastTyping(record, index)"
              style="margin-left: 5px; padding: 0"
            >
              <a-icon type="edit" />
            </a-button>
          </a-tooltip>
        </span>
        <span slot="groupAccount" slot-scope="text, record">
          <a-switch
            checked-children="多线路"
            un-checked-children="单线路"
            v-model="record.groupAccount"
            class="checkGreen"
            @change="record.channelSmsAccountId = undefined"
          />
        </span>
        <span slot="channelSmsAccountId" slot-scope="text, record">
          <a-select
            v-model="record.channelSmsAccountId"
            placeholder="通道"
            class="select-tag"
            showSearch
            :filterOption="false"
            @search="getGroupAccountList"
            style="width: 200px"
            v-show="record.groupAccount"
            :dropdownMatchSelectWidth="false"
          >
            <a-select-option
              :disabled="!i.enabled"
              v-for="i in groupAccountList"
              :key="i.id"
              :value="i.id"
              >{{ i.name }}</a-select-option
            >
          </a-select>
          <a-select
            v-model="record.channelSmsAccountId"
            placeholder="通道"
            class="select-tag"
            showSearch
            :filterOption="false"
            @search="getPlatformList"
            style="width: 200px"
            v-show="!record.groupAccount"
            :dropdownMatchSelectWidth="false"
          >
            <a-select-option
              :disabled="!i.enabled"
              v-for="i in platformList"
              :key="i.id"
              :value="i.id"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </span>
        <span slot="content" slot-scope="text, record">
          <a-input v-model="record.content" placeholder="内容" />
        </span>
        <span slot="enabled" slot-scope="text, record">
          <a-switch
            checked-children="已启用"
            un-checked-children="已禁用"
            v-model="record.enabled"
          />
        </span>
        <span slot="action" slot-scope="text, record, index">
          <a href="javascript:;" @click="deleteRouter(index)" style="color: red"
            >删除</a
          >
        </span>
      </a-table>
      <div style="margin-top: 5px">
        <span slot="label">
          默认线路
          <a-tooltip placement="topLeft">
            <template slot="title">
              不满足任何一条线路的任务将被分配到默认线路
            </template>
            <a-icon type="question-circle" style="color: #3d91f7" />
          </a-tooltip>
          :
        </span>
        <a-switch
          checked-children="分配线路"
          un-checked-children="直接驳回"
          v-model="countShow"
          style="margin-right: 10px"
        />
        <a-select
          v-if="countShow"
          v-model="aisle"
          placeholder="请选择通道"
          class="select-tag"
          showSearch
          :filterOption="false"
          style="width: 230px"
          @search="getPlatformList"
        >
          <a-select-option
            :disabled="!i.enabled"
            v-for="i in platformList"
            :key="i.id"
            :value="i.id"
            >{{ i.name }}</a-select-option
          >
        </a-select>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="routerShow = false">关闭</a-button>
          <a-button key="submit" type="primary" @click="router_submit"
            >确定</a-button
          >
          <a-button v-if="sortDisabled" @click="saveSort">保存排序</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 添加用户 -->
    <a-modal
      :title="addTitle"
      v-model="addUserShow"
      :maskClosable="false"
      :afterClose="addUserClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="用户名"
          required
        >
          <a-select
            v-model="userLoginName"
            placeholder="请选择用户"
            class="select-tag"
            showSearch
            :filterOption="filterOption"
          >
            <a-select-option
              v-for="i in userNameList"
              :key="i.userLoginName"
              :value="i.userLoginName"
              >{{ i.userName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="账号名称"
        >
          <a-input v-model="alias" placeholder="请输入账号名称" allowClear />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="accessKey"
          required
        >
          <a-input
            v-model="accessKey"
            placeholder="请输入accessKey"
            allowClear
            disabled
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="accessSecret"
          required
        >
          <a-input
            v-model="accessSecret"
            placeholder="请输入accessSecret"
            allowClear
          />
          <a href="javascript:;" @click="random" class="randomPswAdd">随机</a>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="路由类型"
        >
          <a-select
            v-model="routingType"
            showSearch
            placeholder="请选择路由类型"
            :filterOption="false"
          >
            <a-select-option
              v-for="item in routingTypeList"
              :key="item.value"
              >{{ item.key }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="延迟回调"
          required
        >
          <a-select
            v-model="delayLevel"
            placeholder="请选择用户"
            class="select-tag"
          >
            <a-select-option
              v-for="i in delayLevelList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
          <span slot="label">
            接入方式
            <a-tooltip>
              <template slot="title">
                HTTP: 对外提供HTTP接口<br />
                CMPP: 对外提供CMPP接口 <br />
                DIRECT:不对外提供接口，仅限本系统使用
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-select
            v-model="platformType"
            showSearch
            placeholder="请选择接入方式"
            :filterOption="false"
          >
            <a-select-option v-for="item in platformTypeList" :key="item.key">{{
              item.value
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="CMPP版本"
          v-if="platformType == 'CMPP'"
        >
          <a-select
            v-model="cmppVersion"
            showSearch
            placeholder="请选择CMPP版本"
            :filterOption="false"
          >
            <a-select-option v-for="item in versionList" :key="item.key">{{
              item.value
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="接入号"
          v-if="platformType == 'CMPP'"
        >
          <a-input v-model="cmppSpCode" placeholder="请输入接入号" allowClear />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="通道数"
          v-if="platformType == 'CMPP'"
        >
          <a-input
            v-model="cmppMaxChannelCount"
            placeholder="请输入通道数"
            allowClear
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="速度"
          v-if="platformType == 'CMPP'"
        >
          <a-input v-model="cmppSpeed" placeholder="请输入速度" allowClear />
        </a-form-item>
        <a-form-item
          v-show="combinationShow"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="代理平台"
          required
        >
          <a-select
            v-model="platformId"
            placeholder="请选择代理平台"
            class="select-tag"
            @change="platChange"
            showSearch
            :filterOption="filterOption1"
          >
            <a-select-option
              v-for="i in platList"
              :key="i.name"
              :value="i.id"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <!-- 账号 -->
        <a-form-item
          v-show="combinationShow"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="代理账号"
          required
        >
          <a-select
            v-model="msgPlatformId"
            placeholder="请选择代理账号"
            class="select-tag"
            showSearch
            :filterOption="filterOption2"
          >
            <a-select-option
              v-for="i in msgPlatformList"
              :key="i.id"
              :value="i.id"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="时间限制"
        >
          <a-input
            v-model="availableStartTime"
            placeholder="开始时间"
            allowClear
            style="width: 100px"
          ></a-input
          >-
          <a-input
            v-model="availableEndTime"
            placeholder="结束时间"
            style="width: 100px"
            allowClear
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addUserShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addUser_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 模拟策略 -->
    <a-modal
      title="模拟策略"
      v-model="shortLinkShow"
      :maskClosable="false"
      :afterClose="shortLinkClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="模拟配置:"
        >
          <a-select
            v-model="strategyIdAdd"
            placeholder="请选择模拟配置"
            allowClear
          >
            <a-select-option
              v-for="i in strategyNameList"
              :key="i.id"
              :value="i.id"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
          <span slot="label">
            启用
            <a-tooltip title="启用后，所以通过此账号进来的数据都会进行模拟提交">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="mockEnabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="shortLinkShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="shortLink_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 设置独立回调地址 -->
    <a-modal
      :title="httpCallbackUrlTitle"
      v-model="httpCallbackUrlShow"
      :maskClosable="false"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="http回调地址:"
        >
          <a-input
            v-model="httpCallbackUrl"
            placeholder="请输入http回调地址"
            allowClear
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="httpCallbackUrlShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="modifyHttpCallbackUrl_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 设置延迟回调 -->
    <a-modal
      title="设置延迟回调"
      v-model="modifyDelayLevelShow"
      :maskClosable="false"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="延迟回调"
          required
        >
          <a-select
            v-model="delayLevel"
            placeholder="请选择用户"
            class="select-tag"
          >
            <a-select-option
              v-for="i in delayLevelList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyDelayLevelShow = false"
            >取消</a-button
          >
          <a-button key="submit" type="primary" @click="modifyDelayLevel_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="设置黑名单来源"
      v-model="modifyBlackListSourceShow"
      :maskClosable="false"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="黑名单来源"
          required
        >
          <a-select
            v-model="blackListSource"
            placeholder="请选择类型"
            class="select-tag"
            mode="multiple"
          >
            <a-select-option
              v-for="(i, index) in typeList"
              :key="index"
              :value="i.code"
              >{{ i.desc }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyBlackListSourceShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="modifyBlackListSource_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="快速输入"
      v-model="fastTypingShow"
      width="600px"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="快速输入:">
          <a-textarea :rows="2" placeholder="请输入内容" v-model="textarea" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="fastTypingShow = false">取 消</a-button>
          <a-button type="primary" @click.stop="fastTyping_submit()"
            >确 定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "sendUser",
  props: ["mockConfig"],
  data() {
    return {
      routingTypeList: [],
      fuzzyName: "",
      userLoginName: "",
      accessKeySearch: "",
      copyInfo: "",
      routerShow: false,
      routerColumns: [],
      sourceRouterColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "签名",
          dataIndex: "signSet",
          scopedSlots: { customRender: "signSet" },
          width: 140,
        },
        {
          title: "运营商",
          dataIndex: "operatorSet",
          scopedSlots: { customRender: "operatorSet" },
          width: 140,
        },
        {
          title: "排除区域",
          dataIndex: "forbiddenArea",
          scopedSlots: { customRender: "forbiddenArea" },
          width: 180,
        },
        {
          title: "线路",
          dataIndex: "groupAccount",
          scopedSlots: { customRender: "groupAccount" },
          width: 90,
        },
        {
          title: "通道商账号",
          dataIndex: "channelSmsAccountId",
          scopedSlots: { customRender: "channelSmsAccountId" },
          width: 200,
        },
        {
          title: "内容",
          dataIndex: "content",
          scopedSlots: { customRender: "content" },
          width: 180,
        },
        {
          title: "状态",
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "操作",
          key: "action",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      routerTableData: [],
      userNameList: [],
      userColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "账号名称",
          ellipsis: true,
          dataIndex: "alias",
          width: 100,
        },
        {
          title: "用户名",
          ellipsis: true,
          dataIndex: "userLoginName",
          width: 150,
        },
        {
          title: "accessKey",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 100,
        },
        {
          title: "代理账号",
          ellipsis: true,
          dataIndex: "msgPlatformName",
          scopedSlots: { customRender: "msgPlatformName" },
          width: 300,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "免审",
          ellipsis: true,
          dataIndex: "autoAudit",
          scopedSlots: { customRender: "autoAudit" },
          width: 100,
        },
        {
          title: "接入类型",
          ellipsis: true,
          dataIndex: "platformType",
          scopedSlots: { customRender: "platformType" },
          width: 80,
        },
        {
          title: "路由类型",
          ellipsis: true,
          dataIndex: "routingLabel",
          scopedSlots: { customRender: "routingLabel" },
          width: 100,
        },
        {
          title: "当前连接数",
          ellipsis: true,
          dataIndex: "connectionNum",
          scopedSlots: { customRender: "connectionNum" },
          width: 90,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 300,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      userTableData: [],
      userPagination: {},
      loadingTable: false,
      currentPage: 1,
      pageSize: 10,
      gmtEndOpen: false,
      addUserShow: false,
      userLoginName: undefined,
      userName: "",
      platList: [],
      platformId: undefined,
      accessKey: "",
      accessSecret: "",
      alias: "",
      addTitle: "",
      userId: "",
      msgPlatformId: undefined,
      msgPlatformList: [],
      platformType: "CMPP",
      platformTypeList: [
        {
          key: "HTTP",
          value: "HTTP",
        },
        {
          key: "CMPP",
          value: "CMPP",
        },
        {
          key: "DIRECT",
          value: "DIRECT",
        },
      ],
      cmppVersion: 32,
      versionList: [
        {
          key: 32,
          value: "2.0",
        },
        {
          key: 48,
          value: "3.0",
        },
      ],
      cmppSpCode: "",
      cmppMaxChannelCount: "1",
      cmppSpeed: "500",
      availableStartTime: "8:00",
      availableEndTime: "22:00",
      platformList: [],
      signList: [],
      channelRetryGroupList: [],
      operatorSetList: [
        { key: "中国移动", value: "中国移动" },
        { key: "中国联通", value: "中国联通" },
        { key: "中国电信", value: "中国电信" },
        { key: "其它", value: "其它" },
      ],
      routingType: "SINGLE_ROUTE",
      strategyNameList: [],
      shortLinkShow: false,
      strategyIdAdd: undefined,
      mockEnabled: true,
      editId: "",
      combinationShow: true,
      countShow: false,
      aisle: undefined,
      props: { multiple: true, emitPath: false, checkStrictly: true },
      options: [],
      groupAccountList: [],
      sourceObj: null,
      targetObj: null,
      sourceIndex: null,
      targetIndex: null,
      sortDisabled: false,
      httpCallbackUrlShow: false,
      httpCallbackUrlTitle: "",
      httpCallbackUrl: "",
      delayLevel: 0,
      delayLevelList: [
        { label: "不延迟", value: 0 },
        { label: "1分钟", value: 5 },
        { label: "5分钟", value: 9 },
        { label: "10分钟", value: 14 },
        { label: "20分钟", value: 15 },
        { label: "30分钟", value: 16 },
        { label: "60分钟", value: 17 },
        { label: "120分钟", value: 18 },
      ],
      modifyDelayLevelShow: false,
      blackListSource: [],
      modifyBlackListSourceShow: false,
      typeList: [],
      textarea: "",
      forbiddenAreaIndex: "",
      fastTypingShow: false,
    };
  },
  watch: {
    routingType: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue == "COMBINATION_ROUTE") {
          this.combinationShow = false;
        } else {
          this.combinationShow = true;
        }
      },
    },
  },
  mounted() {
    this.getTypeList();
    this.getRoutingType();
    this.inquire(1);
    this.getPlatList();
    this.getStrategyNameList();
    this.getGroupAccountList();
  },
  methods: {
    fastTyping(record, index) {
      let arr = [];
      record.forbiddenArea.forEach((item) => {
        if (item.indexOf(":") !== -1) {
          arr.push(item.split(":")[1]);
        } else {
          arr.push(item);
        }
      });
      this.forbiddenAreaIndex = index;
      this.textarea = arr.join(",");
      this.fastTypingShow = true;
    },
    fastTyping_submit() {
      this.textarea = this.textarea.replace(/，/g, ",");
      this.textarea = this.textarea.replace(/ /g, ",");
      this.textarea = this.textarea.replace(/\|/g, ",");
      this.textarea = this.textarea.replace(/\｜/g, ",");
      let textareaArr = this.textarea.split(",");
      textareaArr = this.removeTrim(textareaArr);
      textareaArr.map((areaItem, index) => {
        this.options.map((item) => {
          item.children.map((childrenItem) => {
            if (childrenItem.value.split(":")[1].indexOf(areaItem) !== -1) {
              textareaArr[index] = childrenItem.value;
            }
          });
        });
      });

      this.$nextTick(() => {
        this.$set(
          this.routerTableData[this.forbiddenAreaIndex],
          "forbiddenArea",
          textareaArr
        );
      });
      this.fastTypingShow = false;
      this.$message.success("设置成功");
    },
    removeTrim(array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i] == "") {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      return array;
    },
    getTypeList() {
      this.$http.get("/blacklist/typeList").then((res) => {
        if (res.result === 200) {
          this.typeList = res.data;
        }
      });
    },
    saveSort() {
      let orderedIdList = [];
      this.routerTableData.forEach((item) => {
        orderedIdList.push(item.id);
      });
      let data = {
        userSmsAccountId: this.userAccountId,
        orderedIdList,
      };
      this.$http
        .json_post("/userPlatformRelation/updateRoutingOrder", data)
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("排序成功");
            this.sortDisabled = false;
          }
        });
    },
    customRow(record, index) {
      return {
        style: {
          cursor: "pointer",
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            // 兼容IE
            var ev = event || window.event;
            ev.target.draggable = true;
          },
          // 开始拖拽
          dragstart: (event) => {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到源目标数据
            this.sourceObj = record;
            this.sourceIndex = index;
          },
          // 拖动元素经过的元素
          dragover: (event) => {
            // 兼容 IE
            var ev = event || window.event;
            // 阻止默认行为
            ev.preventDefault();
          },
          // 鼠标松开
          drop: (event) => {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到目标数据
            this.targetObj = record;
            this.targetIndex = index;
            if (this.sourceObj.id !== this.targetObj.id) {
              this.routerTableData.splice(this.sourceIndex, 1);
              this.routerTableData.splice(this.targetIndex, 0, this.sourceObj);
              this.routerTableData.forEach((item, index) => {
                this.$set(item, "order", index + 1);
              });
              this.sortDisabled = true;
            }
            this.targetObj = null;
            this.sourceObj = null;
            this.sourceIndex = null;
            this.targetIndex = null;
          },
        },
      };
    },
    getGroupAccountList(name) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        name,
      };
      this.$http
        .get("/channelSmsGroupAccount/channelSmsGroupAccountPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.groupAccountList.splice(0);
            this.groupAccountList.push(...res.data.records);
          }
        });
    },
    // 模拟策略
    simulateClick(val) {
      this.shortLinkShow = true;
      this.strategyIdAdd = val.strategyId;
      this.mockEnabled = val.mockEnabled;
      this.editId = val.id;
    },
    modifyHttpCallbackUrl(val) {
      this.httpCallbackUrl = val.httpCallbackUrl;
      this.editId = val.id;
      this.httpCallbackUrlTitle = "设置独立回调地址";
      this.httpCallbackUrlShow = true;
    },
    modifyReplyCallbackUrl(val) {
      this.httpCallbackUrl = val.replyCallbackUrl;
      this.editId = val.id;
      this.httpCallbackUrlTitle = "设置上行转发地址";
      this.httpCallbackUrlShow = true;
    },
    modifyBlackListSource(val) {
      if (val.blackListSource) {
        this.blackListSource = val.blackListSource.split(",");
      } else {
        this.blackListSource = [];
      }
      this.editId = val.id;
      this.modifyBlackListSourceShow = true;
    },
    checkConnected(record) {
      let data = {
        accountAccessKey: record.accessKey,
      };
      this.$http
        .get("/userPlatformRelation/checkConnected", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("检查成功");
          }
        });
    },
    modifyDelayLevel(val) {
      this.delayLevel = val.delayLevel;
      this.editId = val.id;
      this.modifyDelayLevelShow = true;
    },
    modifyBlackListSource_submit() {
      let data = {
        id: this.editId,
        blackListSource: this.blackListSource.join(","),
      };
      this.$http
        .json_post("/userPlatformRelation/modifyBlackListSource", data)
        .then((res) => {
          if (res.result === 200) {
            this.modifyBlackListSourceShow = false;
            this.$message.success("设置成功");
            this.inquire(1);
          }
        });
    },
    modifyDelayLevel_submit() {
      let data = {
        id: this.editId,
        delayLevel: this.delayLevel,
      };
      this.$http
        .json_post("/userPlatformRelation/modifyDelayLevel", data)
        .then((res) => {
          if (res.result === 200) {
            this.modifyDelayLevelShow = false;
            this.$message.success("设置成功");
            this.inquire(1);
          }
        });
    },
    modifyHttpCallbackUrl_submit() {
      let data = {
        id: this.editId,
        httpCallbackUrl: this.httpCallbackUrl,
      };
      if (this.httpCallbackUrlTitle == "设置独立回调地址") {
        data.httpCallbackUrl = this.httpCallbackUrl;
        this.$http
          .json_post("/userPlatformRelation/modifyHttpCallbackUrl", data)
          .then((res) => {
            if (res.result === 200) {
              this.httpCallbackUrlShow = false;
              this.$message.success("修改成功！");
              this.inquire(1);
            }
          });
      } else {
        data.replyCallbackUrl = this.httpCallbackUrl;
        this.$http
          .json_post("/userPlatformRelation/modifyReplyCallbackUrl", data)
          .then((res) => {
            if (res.result === 200) {
              this.httpCallbackUrlShow = false;
              this.$message.success("修改成功！");
              this.inquire(1);
            }
          });
      }
    },
    shortLink_submit() {
      let data = {
        id: this.editId,
        strategyId: this.strategyIdAdd,
        enabled: this.mockEnabled,
      };
      this.$http
        .json_post("/userPlatformRelation/changeMockStatus", data)
        .then((res) => {
          if (res.result === 200) {
            this.shortLinkShow = false;
            this.$message.success("修改成功！");
            this.inquire(1);
          }
        });
    },
    shortLinkClose() {},
    // 查询模拟配置
    getStrategyNameList() {
      this.$http.get("/statusMock/strategyList").then((res) => {
        if (res.result === 200) {
          this.strategyNameList = res.data;
        }
      });
    },
    filterOption1(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterOption2(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 绑定测试账号
    bindTest(val) {
      this.$confirm({
        title:
          "确定要将此账号绑定为测试账号吗？更改为测试账号后，每次测试发送，底层通道都会被更改，请确认理解后再进行操作",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http
            .json_post("/userPlatformRelation/changeTestAccount", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("绑定成功");
                this.inquire();
              }
            });
        },
        onCancel() {},
      });
    },
    // 点击路由
    routerClick(val) {
      this.userAccountId = val.id;
      this.getProvinceAndCity();
      this.getPlatformList();
      this.getChannelRetryGroup();
      this.listUserSign();
      this.routerShow = true;
      this.routingRuleList();
      this.routerColumns.splice(0);
      if (val.routingType === "RANDOM_COMBINATION_ROUTE") {
        this.routerColumns.push(
          ...this.sourceRouterColumns.filter((item) => {
            return item.dataIndex !== "forbiddenArea";
          })
        );
      } else {
        this.routerColumns.push(...this.sourceRouterColumns);
      }
      this.routingRuleList();
    },
    routingRuleList() {
      let data = {
        userAccountId: this.userAccountId,
      };
      this.$http
        .get("/userPlatformRelation/routingRuleList", data)
        .then((res) => {
          if (res.result === 200) {
            this.routerTableData.splice(0);
            this.routerTableData.push(...res.data.ruleList);
            this.routerTableData.forEach((item) => {
              this.$set(item, "forbiddenArea", item.forbiddenArea.split(","));
            });
            if (res.data.defaultChannelSmsAccount) {
              this.countShow = true;
              this.aisle = res.data.defaultChannelSmsAccount.id;
            } else {
              this.countShow = false;
            }
            this.routerShow = true;
          }
        });
    },
    router_submit() {
      let data = {
        list: this.routerTableData,
        userSmsAccountId: this.userAccountId,
      };
      if (this.countShow) {
        data.defaultChannelSmsAccountId = this.aisle;
      } else {
        data.defaultChannelSmsAccountId = -1;
      }
      data.list.map((item) => {
        item.forbiddenArea = item.forbiddenArea.join(",");
      });
      this.$http
        .json_post("/userPlatformRelation/editRoutingRule", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("编辑成功");
            this.routerShow = false;
            this.inquire(1);
          }
        });
    },
    // 路由删除
    deleteRouter(val) {
      this.routerTableData.splice(val, 1);
    },
    // 路由新增
    routerAdd() {
      let obj = {
        enabled: true,
        userSmsAccountId: this.userAccountId,
        order: 1,
        channelSmsAccountId: 82,
        forbiddenArea: [],
        signSet: [],
        operatorSet: [],
        content: "",
      };
      this.routerTableData.push(obj);
    },
    // 查询路由类型
    getRoutingType() {
      this.$http.get("/userPlatformRelation/routingType").then((res) => {
        if (res.result === 200) {
          this.routingTypeList = res.data;
          this.inquire(1);
        }
      });
    },
    getProvinceAndCity() {
      this.$http.get("/userPlatformRelation/getProvinceAndCity").then((res) => {
        if (res.result === 200) {
          this.options.splice(0);
          let data = res.data;
          for (let i in data) {
            let a = {};
            a.value = i;
            a.label = i;
            a.children = [];
            if (data[i] && data[i].length > 0) {
              for (let j of data[i]) {
                let b = {};
                b.value = i + ":" + j;
                b.label = i + " " + j;
                a.children.push(b);
              }
            }
            this.options.push(a);
          }
        }
      });
    },
    getPlatformList(accessKeyOrName) {
      let data = {
        pageNo: 1,
        pageSize: 5000,
        accessKeyOrName,
      };
      this.$http.get("/sms/platform/list", data).then((res) => {
        if (res.result === 200) {
          this.platformList = res.data.records;
        }
      });
    },
    getChannelRetryGroup(name) {
      let data = {
        pageNo: 1,
        pageSize: 5000,
        name,
        enabled: true,
      };
      this.$http
        .get("/channelRetryGroup/channelRetryGroupPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.channelRetryGroupList = res.data.records;
            this.channelRetryGroupList.unshift({
              id: -1,
              name: "无",
            });
          }
        });
    },
    // 获取签名
    listUserSign() {
      let data = {
        userSmsAccountId: this.userAccountId,
      };
      this.$http.get("/sms/signRelation/listUserSign", data).then((res) => {
        if (res.result === 200) {
          this.signList = res.data;
        }
      });
    },
    switchOnChange(record) {
      let data = {
        id: record.id,
        enabled: !record.enabled,
      };
      this.$http
        .json_post(
          "/userPlatformRelation/updateUserPlatformRelationStatus",
          data
        )
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.inquire();
          }
        });
    },
    // 免审
    autoOnChange(record) {
      let data = {
        id: record.id,
        autoAudit: !record.autoAudit,
      };
      this.$http
        .json_post("/userPlatformRelation/changeAutoAuditStatus", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("操作成功");
            this.inquire();
          }
        });
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        fuzzyName: this.fuzzyName,
        userLoginName: this.userLoginName,
        accessKey: this.accessKeySearch,
      };
      this.getUserList(data);
    },
    // 查询用户列表
    getUserList(data) {
      this.loadingTable = true;
      this.$http
        .get("/userPlatformRelation/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
              item.routingLabel = "";
              this.routingTypeList.forEach((menu) => {
                if (menu.value === item.routingType) {
                  item.routingLabel = menu.key;
                }
              });
            });
            this.userTableData = list;
            this.userPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.currentPage_batch(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 查询平台集合
    getPlatList() {
      this.$http.get("/plat/getAllList").then((res) => {
        if (res.result === 200) {
          this.platList = res.data;
        }
      });
    },
    // 翻页
    currentPage_batch(index) {
      this.inquire(index);
    },
    addUser() {
      this.random();
      this.getUserListAdd();
      this.addUserShow = true;
      this.addTitle = "新增用户通道";
      for (var i = 0; i < 6; i++) {
        this.cmppSpCode += Math.floor(Math.random() * 10);
      }
      this.$http.get("/userPlatformRelation/nextAccessKey").then((res) => {
        if (res.result === 200) {
          this.accessKey = res.data;
        }
      });
    },
    addUserClose() {
      this.userLoginName = undefined;
      this.platformId = undefined;
      this.msgPlatformId = undefined;
      this.accessKey = "";
      this.accessSecret = "";
      this.alias = "";
      this.platformType = "CMPP";
      this.cmppVersion = 32;
      this.cmppSpCode = "";
      this.cmppMaxChannelCount = "1";
      this.routingType = "SINGLE_ROUTE";
      this.cmppSpeed = "500";
      this.availableStartTime = "8:00";
      this.availableEndTime = "22:00";
      this.combinationShow = true;
    },
    //随机密码
    random() {
      let psw = this.$common.randomStr(false, 6);
      this.accessSecret = psw;
    },
    addUser_submit() {
      let data = {
        userLoginName: this.userLoginName,
        platformId: this.platformId,
        msgPlatformId: this.msgPlatformId,
        accessKey: this.accessKey,
        accessSecret: this.accessSecret,
        alias: this.alias,
        platformType: this.platformType,
        cmppVersion: this.cmppVersion,
        cmppSpCode: this.cmppSpCode,
        cmppMaxChannelCount: this.cmppMaxChannelCount,
        routingType: this.routingType,
        cmppSpeed: this.cmppSpeed,
        availableStartTime:
          this.availableStartTime.split(":")[0] * 3600 +
          this.availableStartTime.split(":")[1] * 60,
        availableEndTime:
          this.availableEndTime.split(":")[0] * 3600 +
          this.availableEndTime.split(":")[1] * 60,
      };
      if (this.addTitle === "新增用户通道") {
        this.$http.json_post("/userPlatformRelation/add", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.userLoginName = undefined;
            this.inquire();
            this.addUserShow = false;
          }
        });
      } else if (this.addTitle === "编辑用户通道") {
        data.id = this.userId;
        this.$http.json_post("/userPlatformRelation/edit", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("编辑成功");
            this.userLoginName = undefined;
            this.inquire();
            this.addUserShow = false;
            this.combinationShow = true;
          }
        });
      }
    },
    // 点击修改
    modifyClick(val) {
      console.log(val);
      this.getUserListAdd();
      this.addUserShow = true;
      this.addTitle = "编辑用户通道";
      this.userLoginName = val.userLoginName;
      this.platformId = val.platformId;
      this.msgPlatformId = val.msgPlatformId;
      this.accessKey = val.accessKey;
      this.accessSecret = val.accessSecret;
      this.alias = val.alias;
      this.platformType = val.platformType;
      this.cmppVersion = val.cmppVersion;
      this.cmppSpCode = val.cmppSpCode;
      this.cmppMaxChannelCount = val.cmppMaxChannelCount;
      this.routingType = val.routingType;
      this.cmppSpeed = val.cmppSpeed;
      if (val.routingType == "COMBINATION_ROUTE") {
        this.combinationShow = false;
      }
      let sTime1 = parseInt(val.availableStartTime / 3600);
      let sTime2 = (val.availableStartTime % 3600) / 60;
      let eTime1 = parseInt(val.availableEndTime / 3600);
      let eTime2 = (val.availableEndTime % 3600) / 60;
      if (sTime2 < 10) {
        sTime2 = "0" + sTime2;
      }
      if (eTime2 < 10) {
        eTime2 = "0" + eTime2;
      }
      //
      this.availableStartTime = sTime1 + ":" + sTime2;

      this.availableEndTime = eTime1 + ":" + eTime2;
      this.userId = val.id;
      this.getMsgPlatformList(val.platformCode);
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除【" + val.userLoginName + "】吗？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http
            .json_post("/userPlatformRelation/delete", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.inquire();
              }
            });
        },
        onCancel() {},
      });
    },
    // 查询用户列表
    getUserListAdd() {
      let data = {
        pageNo: 1,
        pageSize: 200,
      };
      this.userNameList = [];
      this.$http.json_post("/user/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.userNameList = list;
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 平台下拉框发生变化
    platChange(val) {
      this.msgPlatformId = undefined;
      let arr = [];
      arr = this.platList.filter((el) => el.id === val);
      let code = arr[0].code;
      this.getMsgPlatformList(code);
    },
    getMsgPlatformList(val) {
      let data = {
        code: val,
      };
      this.$http.get("/msgPlatform/list", data).then((res) => {
        this.msgPlatformList = res.data;
      });
    },
    // 复制账号信息
    copyAccount(val) {
      if (val.platformType === "CMPP") {
        this.copyInfo =
          val.platformType +
          "-" +
          val.accessKey +
          "\n--------------------" +
          "\n网关: 47.97.127.55" +
          "\n端口: 7890" +
          "\n账号: " +
          val.accessKey +
          "\n密码: " +
          val.accessSecret +
          "\n接入号: " +
          val.cmppSpCode +
          "\n连接数: " +
          val.cmppMaxChannelCount +
          "\n速度: " +
          val.cmppSpeed +
          "\n版本: 2.0";
      } else if (val.platformType === "HTTP") {
        this.copyInfo =
          val.platformType +
          "-" +
          val.accessKey +
          "\n--------------------" +
          "\n地址: https://sms.backend.huiyu.org.cn/api/sms/send" +
          "\n账号: " +
          val.accessKey +
          "\n密码: " +
          val.accessSecret;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.checkGreen.ant-switch-checked {
  background-color: #52c41a;
}
.smsStatusInfo {
  .smsStatusInfo-search {
    position: relative;
    margin-bottom: 20px;
    .date-time {
      display: inline-block;
    }

    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .smsStatusInfo-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .smsStatusInfo-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
  }
}
.router-class {
  input {
    width: 240px;
  }
}
.randomPswAdd {
  position: absolute;
  right: -46px;
  top: -10px;
  color: #1890ff;
}
.el-cascader {
  margin-bottom: 0px !important;
  &::v-deep .el-cascader__search-input {
    min-width: 2px;
  }
}
</style>
