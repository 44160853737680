<template>
  <div class="urlReportList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        批次编号：
        <a-input
          v-model="searchBatchNumber"
          @keyup.enter="inquire(1)"
          placeholder="请输入批次编号"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        日期：
        <a-range-picker
          v-model="time"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 250px"
        />
      </div>
      <div class="searchConditionItem">
        类型：
        <a-select
          v-model="sendStatusSelect"
          placeholder="请选择类型"
          allowClear
        >
          <a-select-option
            v-for="i in sendStatusList"
            :key="i.value"
            :value="i.value"
            >{{ i.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        长链：
        <a-input
          v-model="searchLongUrl"
          @keyup.enter="inquire(1)"
          placeholder="请输入长链"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
        <a-button
          type="primary"
          @click="statisticsData()"
          :disabled="statisticsDataDisabled"
          >{{ statisticsTitle }}</a-button
        >
        <a-button type="primary" @click="exportClick()">导出表单</a-button>
      </div>
    </div>
    <!-- 长链发送列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(urlReportColumns)"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :columns="urlReportColumns"
      :dataSource="urlReportTableData"
      :pagination="false"
      :loading="loading"
      @change="handleTableChange"
      size="small"
    >
      <span slot="platformName" slot-scope="text, record">
        <a-tooltip placement="topLeft">
          <template slot="title">{{ record.platformName }}</template>
          <span>{{ record.platformName }}</span>
        </a-tooltip>
      </span>
      <span slot="totalCount" slot-scope="text, record">
        <a-tooltip placement="topLeft" v-if="record.id">
          <template slot="title">
            中国移动: {{ record.cmccCount }}<br />
            中国联通: {{ record.cmcuCount }}<br />
            中国电信: {{ record.cmctCount }}
          </template>
          <a href="javascript:;" class="send-color-total">
            {{ text }}
          </a>
        </a-tooltip>
        <a v-else href="javascript:;" class="send-color-total">
          {{ text }}
        </a>
      </span>
      <span slot="realSubmitCountTitle">
        实际发送条数
        <a-tooltip placement="left">
          <div slot="title">剔除系统内部过滤的条数(HY:000X)</div>
          <a-icon
            type="question-circle"
            style="color: #1879ff; font-size: 14px"
          />
        </a-tooltip>
      </span>
      <span slot="successCount" slot-scope="text, record">
        <a-tooltip placement="topLeft" v-if="record.id">
          <template slot="title"
            >中国移动: {{ record.cmccSuccessCount }} ({{
              percentage(record.cmccSuccessCount, record.cmccCount)
            }})<br />中国联通: {{ record.cmcuSuccessCount }} ({{
              percentage(record.cmcuSuccessCount, record.cmcuCount)
            }})<br />中国电信: {{ record.cmctSuccessCount }} ({{
              percentage(record.cmctSuccessCount, record.cmctCount)
            }})</template
          >
          <a href="javascript:;" class="send-color-success">
            {{ text }}
          </a>
        </a-tooltip>
        <span v-else>{{ text }}</span>
      </span>
      <span slot="cmccSuccessCount" slot-scope="text, record">
        <a-tooltip placement="topLeft">
          <template slot="title"> 移动 </template>
          <span>
            {{ record.cmccSuccessCount }}
          </span>
        </a-tooltip>
        <a-divider type="vertical" />
        <a-tooltip placement="topLeft">
          <template slot="title"> 联通 </template>
          <span>
            {{ record.cmcuSuccessCount }}
          </span>
        </a-tooltip>
        <a-divider type="vertical" />
        <a-tooltip placement="topLeft">
          <template slot="title"> 电信 </template>
          <span>
            {{ record.cmctSuccessCount }}
          </span>
        </a-tooltip>
      </span>
      <span slot="batchNumber" slot-scope="text, record">
        <a-tooltip placement="topLeft">
          <template slot="title">{{ record.batchNumber }}</template>
          <span>{{ record.batchNumber }}</span>
        </a-tooltip>
      </span>
      <span slot="longUrl" slot-scope="text, record" class="slotCell">
        <a-tooltip placement="topLeft">
          <template slot="title">{{ record.longUrl }}</template>
          <span class="content">{{ record.longUrlLabel }}</span>
        </a-tooltip>
        <a
          href="javascript:;"
          class="copy-class"
          v-clipboard:copy="record.longUrl"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-if="record.isCopyShow"
          >复制</a
        >
      </span>
      <span slot="clickRate" slot-scope="text, record">
        {{ record.clickRate / 100 }}%
      </span>
      <span slot="clickRateTitle">
        点击率
        <a-tooltip>
          <template slot="title"> 点击uv/发送成功数 </template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
      <span slot="registerRate" slot-scope="text, record">
        {{ record.registerRate / 100 }}%
      </span>
      <span slot="msgCostLabel" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            <span>总成本：{{ record.totalCost }}</span>
            <a-divider type="vertical" />
            <span>短信成本：{{ record.msgCost }}</span>
            <a-divider type="vertical" />
            <span>语音成本：{{ record.voiceCost }}</span>
            <a-divider type="vertical" />
            <span>资源成本：{{ record.resourceCost }}</span>
          </template>
          <span class="send-color-total">{{ record.totalCost }}</span>
          <a-divider type="vertical" />
          <span class="send-color-success">{{ record.msgCost }}</span>
          <a-divider type="vertical" />
          <span class="send-color-wait">{{ record.voiceCost }}</span>
          <a-divider type="vertical" />
          <span class="send-color-fail">{{ record.resourceCost }}</span>
        </a-tooltip>
      </span>
      <span slot="msgUnitPriceLabel" slot-scope="text, record, index">
        <a-tooltip v-show="index !== urlReportTableData.length - 1">
          <template slot="title">
            <span>短信单价：{{ record.msgUnitPrice }}</span>
            <a-divider type="vertical" />
            <span>语音单价：{{ record.voiceUnitPrice }}</span>
            <a-divider type="vertical" />
            <span>资源单价：{{ record.resourceUnitPrice }}</span>
          </template>
          <span class="send-color-total">{{ record.msgUnitPrice }}</span>
          <a-divider type="vertical" />
          <span class="send-color-success">{{ record.voiceUnitPrice }}</span>
          <a-divider type="vertical" />
          <span class="send-color-fail">{{ record.resourceUnitPrice }}</span>
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record, index">
        <a
          v-show="index !== urlReportTableData.length - 1"
          href="javascript:;"
          @click="modifyUnitPrice(record)"
          >修改单价</a
        >
      </span>
      <span slot="footer">
        <div class="pagination">
          <my-pagination
            :tableList="urlReportTableData"
            :total="total"
            :pageNo="urlReportTablePageNo"
            :pageSize="urlReportTablePageSize"
            summaryShow
            @changePage="urlReportChangePage"
          ></my-pagination>
        </div>
      </span>
    </a-table>
    <a-button @click="downloadClick()" :disabled="this.tableData == ''"
      >下载客户编号</a-button
    >
    <!-- 修改单价 -->
    <a-modal
      :title="'【' + batchNumber + '】修改单价'"
      v-model="modifyUnitPriceShow"
      :maskClosable="false"
      :afterClose="modifyUnitPriceClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="短信单价(元)"
        >
          <a-input v-model="msgUnitPrice" placeholder="请输入短信单价" />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="语音单价(元)"
        >
          <a-input v-model="voiceUnitPrice" placeholder="请输入语音单价" />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="资源单价(元)"
        >
          <a-input v-model="resourceUnitPrice" placeholder="请输入资源单价" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="modifyUnitPriceShow = false">取消</a-button>
          <a-button type="primary" @click="modifyUnitPrice_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import myPagination from "../tool/myPagination.vue";
export default {
  components: {
    myPagination,
  },
  name: "urlReportList",
  data() {
    return {
      time: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      gmtEndOpen: false,
      // yc多选下载
      selectedRowKeys: [],
      selectedRows: [], //多选选中的数据
      tableData: "",
      downloadId: "", //下载id
      downloadTimer: "", //刷新下载状态定时器
      // 排序
      clickRateOrder: "",
      registerRateOrder: "",
      desc: "",
      sort: "",
      //  ycEnd
      modifyUnitPriceShow: false,
      batchNumber: "",
      msgUnitPrice: "",
      voiceUnitPrice: "",
      resourceUnitPrice: "",
      urlReportId: "",
      statisticsDataDisabled: true,
      statisticsTitle: "统计当日数据",
      sendStatusSelect: undefined,
      sendStatusList: [
        {
          value: "BATCH_SEND",
          label: "群发",
        },
        {
          value: "API_MESSAGE",
          label: "挂短",
        },
      ],
      loading: false,
      urlReportColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>合计</span>,
                attrs: {
                  colSpan: 3,
                },
              };
            }
            return <span>{text}</span>;
          },
        },
        {
          title: "发送日期",
          ellipsis: true,
          dataIndex: "date",
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>{{ text }}</span>,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return <span>{text}</span>;
          },
          width: 170,
        },
        {
          title: "批次编号",
          ellipsis: true,
          dataIndex: "batchNumber",
          scopedSlots: { customRender: "batchNumber" },
          customRender: (text, row, index) => {
            if (index === this.urlReportTableData.length - 1) {
              return {
                children: <span>{{ text }}</span>,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return <span>{text}</span>;
          },
          width: 350,
        },
        {
          title: "发送条数",
          ellipsis: true,
          dataIndex: "totalCount",
          width: 100,
          scopedSlots: { customRender: "totalCount" },
        },
        {
          ellipsis: true,
          dataIndex: "realSubmitCount",
          width: 130,
          scopedSlots: { title: "realSubmitCountTitle" },
        },
        {
          title: "成功条数",
          ellipsis: true,
          dataIndex: "successCount",
          width: 100,
          scopedSlots: { customRender: "successCount" },
        },
        {
          title: "三网分布",
          ellipsis: true,
          dataIndex: "cmccSuccessCount",
          width: 200,
          scopedSlots: { customRender: "cmccSuccessCount" },
        },
        {
          title: "点击uv",
          ellipsis: true,
          dataIndex: "clickUv",
          width: 100,
        },
        {
          ellipsis: true,
          dataIndex: "clickRate",
          sorter: true,
          scopedSlots: { customRender: "clickRate", title: "clickRateTitle" },
          width: 100,
        },
        {
          title: "长链",
          ellipsis: true,
          dataIndex: "longUrl",
          customCell: this.mouseoverLongUrl,
          scopedSlots: { customRender: "longUrl" },
          width: 450,
        },
        {
          title: "平台名称",
          ellipsis: true,
          dataIndex: "platformName",
          scopedSlots: { customRender: "platformName" },
          width: 200,
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "typeLabel",
          width: 100,
        },
        {
          title: "失败条数",
          ellipsis: true,
          dataIndex: "failureCount",
          width: 100,
        },
        {
          title: "等待回执条数",
          ellipsis: true,
          dataIndex: "waitCallbackCount",
          width: 100,
        },
        {
          title: "点击pv",
          ellipsis: true,
          dataIndex: "clickPv",
          width: 100,
        },
        {
          title: "语音接通量",
          ellipsis: true,
          dataIndex: "voiceConnectedCount",
          width: 150,
        },
        {
          title: "单价(元)",
          ellipsis: true,
          dataIndex: "msgUnitPriceLabel",
          scopedSlots: { customRender: "msgUnitPriceLabel" },
          width: 200,
        },
        {
          title: "成本(元)",
          ellipsis: true,
          dataIndex: "msgCostLabel",
          scopedSlots: { customRender: "msgCostLabel" },
          width: 200,
        },
        {
          title: "注册率",
          ellipsis: true,
          dataIndex: "registerRate",
          sorter: true,
          scopedSlots: { customRender: "registerRate" },
          width: 100,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      urlReportTableData: [],
      total: 0,
      urlReportTablePageNo: 1,
      urlReportTablePageSize: 10,
      searchBatchNumber: "",
      searchLongUrl: "",
      syncLoading: null,
      timerStatistics: null,
    };
  },
  computed: {
    percentage() {
      return (num, count) => {
        let percentage = num / count;
        if (count > 0) {
          return (percentage * 100).toFixed(2) + "%";
        } else {
          return "0%";
        }
      };
    },
  },
  mounted() {
    this.inquire(1);
    this.getStatisticsDateUrlReportStatus();
    this.setIntervalStatistics();
  },
  methods: {
    // 多选框数据
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      var arr = [];
      for (var i = 0; i < this.selectedRows.length; i++) {
        var newArr = this.selectedRows[i].id;
        arr.push(newArr);
      }
      this.tableData = arr.join(",");
    },
    // 点击下载按钮
    downloadClick() {
      let data = {
        ids: this.tableData,
      };
      this.$http.get("/report/customerCode/export", data).then((res) => {
        if (res.result === 200) {
          this.downloadId = res.data.id;
          this.downloadStatus();
          this.syncLoading = this.$syncLoading({
            message: "文件正在下载...",
          });
        }
      });
    },
    downloadStatus() {
      this.downloadTimer = setInterval(() => {
        let data = {
          id: this.downloadId,
        };
        this.$http.get("/report/file/state", data).then((res) => {
          if (res.result === 200) {
            this.syncLoading.close();
            if (res.data.status === "DOWNLOADABLE") {
              let data = {
                id: this.downloadId,
              };
              this.$http.get("/report/file/download", data).then((res) => {
                clearInterval(this.downloadTimer);
                this.downloadTimer = null;
                let href =
                  this.$path +
                  "/report/file/download?" +
                  "id=" +
                  this.downloadId;
                console.log(href);
                window.location.href = href;
              });
            } else if (res.data.status === "EXCEPTION") {
              clearInterval(this.downloadTimer);
              this.downloadTimer = null;
              this.$message.error("下载失败");
            }
          } else {
            clearInterval(this.downloadTimer);
            this.downloadTimer = null;
          }
        });
      }, 2000);
    },
    // yc排序
    handleTableChange(pagination, filters, { field, order }) {
      if (field == "clickRate" && order == "ascend") {
        this.desc = "ASC";
        this.sort = "";
      } else if (field == "clickRate" && order == "descend") {
        this.desc = "DESC";
        this.sort = "";
      } else if (field == "registerRate" && order == "ascend") {
        this.sort = "ASC";
        this.desc = "";
      } else if (field == "registerRate" && order == "descend") {
        this.sort = "DESC";
        this.desc = "";
      }
      this.inquire();
    },
    // 点击修改单价
    modifyUnitPrice(val) {
      this.modifyUnitPriceShow = true;
      this.msgUnitPrice = val.msgUnitPrice;
      this.voiceUnitPrice = val.voiceUnitPrice;
      this.resourceUnitPrice = val.resourceUnitPrice;
      this.urlReportId = val.id;
      this.batchNumber = val.batchNumber;
    },
    // 提交修改单价
    modifyUnitPrice_submit() {
      let data = {
        urlReportId: this.urlReportId,
        msgUnitPrice: this.msgUnitPrice,
        voiceUnitPrice: this.voiceUnitPrice,
        resourceUnitPrice: this.resourceUnitPrice,
      };
      if (data.msgUnitPrice) {
        data.msgUnitPrice = Number(data.msgUnitPrice) * 1000;
      }
      if (data.voiceUnitPrice) {
        data.voiceUnitPrice = Number(data.voiceUnitPrice) * 1000;
      }
      if (data.resourceUnitPrice) {
        data.resourceUnitPrice = Number(data.resourceUnitPrice) * 1000;
      }
      this.$http.json_post("/report/modifyUnitPriceInfo", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.modifyUnitPriceShow = false;
          this.inquire();
        }
      });
    },
    // 关闭修改单价
    modifyUnitPriceClose() {
      this.urlReportId = "";
      this.msgUnitPrice = "";
      this.voiceUnitPrice = "";
      this.resourceUnitPrice = "";
    },
    // 刷新统计数据状态
    setIntervalStatistics() {
      if (this.timerStatistics) {
        clearInterval(this.timerStatistics);
        this.timerStatistics = null;
      }
      this.timerStatistics = setInterval(() => {
        this.getStatisticsDateUrlReportStatus();
      }, 5000);
    },
    // 查询统计数据状态
    getStatisticsDateUrlReportStatus() {
      this.$http.get("/report/statisticsDateUrlReportStatus").then((res) => {
        if (res.result === 200) {
          this.statisticsDataDisabled = res.data.statisticsIng;
          if (this.statisticsDataDisabled) {
            this.statisticsTitle = "数据统计中";
          } else {
            this.statisticsTitle = "统计当日数据";
          }
        }
      });
    },
    // 鼠标移入长链列--智能短链
    mouseoverLongUrl(record, rowIndex) {
      return {
        on: {
          // 事件
          click: (event) => {}, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {
            // 鼠标移入行
            this.urlReportTableData.forEach((item) => {
              if (record.id === item.id && record.id) {
                this.$set(
                  this.urlReportTableData[rowIndex],
                  "isCopyShow",
                  true
                );
              }
            });
          },
          mouseleave: (event) => {
            // 鼠标移出行
            this.urlReportTableData.forEach((item) => {
              if (record.id === item.id && record.id) {
                this.$set(
                  this.urlReportTableData[rowIndex],
                  "isCopyShow",
                  false
                );
              }
            });
          },
        },
      };
    },
    // 复制成功
    onCopy(e) {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError(e) {
      this.$message.error("复制失败");
    },
    // 点击导出
    exportClick() {
      let data = {
        batchNumber: this.searchBatchNumber,
        longUrl: this.searchLongUrl,
        startDate: this.time[0],
        endDate: this.time[1],
        type: this.sendStatusSelect,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href = this.$path + "/report/export?" + url;
      window.location.href = href;
    },
    // 点击统计当日数据
    statisticsData() {
      if (!this.time[0]) {
        this.$message.warning("请选择日期");
        return;
      }
      this.syncLoading = this.$syncLoading({
        message: "数据统计中...",
      });
      this.getStatisticsDateUrlReportStatus();
      this.setIntervalStatistics();
      this.$http
        .json_post("/report/statisticsDateUrlReport", { date: this.time[0] })
        .then((res) => {
          this.syncLoading.close();
          if (res.result === 200) {
            this.inquire(1);
            this.$message.success("统计成功");
            this.getStatisticsDateUrlReportStatus();
          }
        })
        .catch((err) => {
          this.syncLoading.close();
        });
    },
    // 点击查询
    inquire(pageNo, pageSize) {
      if (pageNo) {
        this.urlReportTablePageNo = pageNo;
      }
      if (pageSize) {
        this.urlReportTablePageSize = pageSize;
      }
      let data = {
        pageNo: this.urlReportTablePageNo,
        pageSize: this.urlReportTablePageSize,
        batchNumber: this.searchBatchNumber,
        longUrl: this.searchLongUrl,
        startDate: this.time[0],
        endDate: this.time[1],
        type: this.sendStatusSelect,
        clickRateOrder: this.desc !== "" ? this.desc : undefined,
        registerRateOrder: this.sort !== "" ? this.sort : undefined,
      };
      this.urlReportTableData = [];
      this.getUrlReportList(data);
    },
    getUrlReportList(data) {
      this.loading = true;
      this.$http
        .get("/report/urlReportList", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.selectedRowKeys = [];
            this.selectedRows = [];
            this.tableData = "";
            let list = res.data.data.records;
            let summary = res.data.summary;
            list.forEach((item, index) => {
              item.typeLabel = "";
              item.longUrlLabel = item.longUrl;
              if (item.longUrl.length > 50) {
                item.longUrlLabel = item.longUrl.substring(0, 50) + "...";
              }
              if (item.msgUnitPrice) {
                item.msgUnitPrice = item.msgUnitPrice / 1000;
              } else {
                item.msgUnitPrice = 0;
              }
              if (item.voiceUnitPrice) {
                item.voiceUnitPrice = item.voiceUnitPrice / 1000;
              } else {
                item.voiceUnitPrice = 0;
              }
              if (item.resourceUnitPrice) {
                item.resourceUnitPrice = item.resourceUnitPrice / 1000;
              } else {
                item.resourceUnitPrice = 0;
              }
              item.isCopyShow = false;
              switch (item.type) {
                case "BATCH_SEND":
                  item.typeLabel = "群发";
                  break;
                case "API_MESSAGE":
                  item.typeLabel = "挂短";
                  break;
              }
            });
            if (summary) {
              list.push(summary);
            }
            this.urlReportTableData = list;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 翻页
    urlReportChangePage(pageNo, pageSize) {
      this.inquire(pageNo, pageSize);
    },
  },
  beforeDestroy() {
    if (this.timerStatistics) {
      clearInterval(this.timerStatistics);
      this.timerStatistics = null;
    }
  },
};
</script>
<style lang="scss" scoped>
.copy-class {
  color: #52c41a;
}
</style>
